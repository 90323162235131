@charset "UTF-8";
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0
}

body {
    line-height: 1
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

nav ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: none
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: 700
}

del {
    text-decoration: line-through
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

input,
select {
    vertical-align: middle
}

html {
    -webkit-text-size-adjust: none
}

body {
    font: 80% 'Open Sans', Arial, sans-serif;
    color: #525252;
    //padding-top: 180px
}

body.toolbar {
    //padding-top: 185px!important
}

body.toolbar.network {
    //padding-top: 245px!important
}

body.network {
    //padding-top: 245px
}

@media only screen and (max-width:959px) {
    body {
        background-color: #444;
        padding: 0
    }
    body.toolbar,
    body.toolbar.network {
        //padding-top: 0!important
    }
    body.network {
        //padding-top: 0
    }
}

#wrapper {
    background: #fff;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 9px #1a1a1a
}

.box-content {
    width: 960px;
    margin: 0 auto;
    position: relative
}

.box-content .block-title, .box-content-new .block-title {
    font-weight: 700;
    font-size: 1.7em;
    line-height: 1
}

.box-content .light-title, .box-content-new .light-title {
    font-weight: 300;
    color: #005d25
}

@media only screen and (max-width:959px) {
    .box-content {
        width: 96%;
        padding: 0 2%
    }
}

.left {
    float: left
}

.right {
    float: right
}

.clear {
    clear: both
}

span.clear {
    display: block;
    height: 0
}

.green {
    color: #005d25
}

.yellow {
    color: #ffc200
}

a {
    text-decoration: none;
    color: #005d25
}

a.read-more-yellow-plus {
    position: relative;
    font-size: .8em;
    font-weight: 700;
    line-height: 1;
    padding: 10px 0 0 30px;
    display: inline-block
}

a.read-more-yellow-plus:before {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    background: #ffc200;
    text-align: center;
    line-height: 1.9;
    position: absolute;
    left: 0
}

.with-icon:before {
    font-size: 1.4em;
    vertical-align: middle;
    color: #999;
    margin-right: 15px
}

blockquote:after,
blockquote:before {
    font: .4em owsd;
    display: inline-block;
    color: #005d25
}

blockquote:before {
    content: 'h';
    vertical-align: top;
    height: 140px;
    float: left;
    margin-right: 6px
}

blockquote:after {
    content: 'g';
    vertical-align: text-bottom;
    margin-left: 12px
}

blockquote p {
    display: inline
}

.button {
    display: inline-block;
    min-width: 70px;
    text-align: center;
    padding: 9px 12px;
    background: #78c6e7;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 8px;
    font-size: 1.2em;
    margin: 0 auto
}

.button-green {
    background: #005d25
}

.centered-button {
    text-align: center;
    position: relative;
    z-index: 100
}

.righted-button {
    text-align: right
}

.controls a span {
    display: none
}

.controls a.disabled {
    visibility: hidden
}

.italic-title {
    font-weight: 300;
    font-style: italic
}

.icon-news-icon:before {
    color: #8c8d00
}

.icon-workshop-icon:before {
    color: #bbb
}

.icon-event-icon:before {
    color: #d96d00
}

.with-blue-sign:before,
.with-small-sign:before {
    display: block;
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
    content: ''
}

.with-blue-sign {
    position: relative
}

.with-blue-sign:before {
    height: 4px;
    background: #0085b2
}

.with-small-sign {
    position: relative
}

.with-small-sign:before {
    height: 1px;
    background: #ddd
}

#accordion,
.accordion {
    border-top: 1px solid #eee
}

#accordion dt,
.accordion dt {
    height: 60px;
    padding: 20px 3px 29px;
    cursor: pointer;
    font-size: 1.4em;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    border-bottom: 1px solid #eee
}

#accordion dt span,
.accordion dt span {
    display: inline-block
}

#accordion dt:after,
.accordion dt:after {
    content: 'i';
    font-family: owsd;
    font-size: 1.2em;
    text-transform: lowercase;
    position: absolute;
    right: 3px;
    top: 40px
}

#accordion dt.on,
.accordion dt.on {
    border: none
}

#accordion dt.on:after,
.accordion dt.on:after {
    content: 'q';
    color: #ddd
}

.stickit {
    position: fixed;
    top: 95px;
    z-index: 101
}

.view {
    clear: both
}

#cookieChoiceInfo {
    position: fixed;
    width: 100%;
    margin: 0;
    padding-top: 1em;
    left: 0;
    bottom: 0;
    z-index: 1000;
    text-align: center;
    background-color: rgba(238, 238, 238, .9)
}

#cookieChoiceInfo>span {
    padding: 1em
}

@media only screen and (max-width:767px) {
    #cookieChoiceInfo>a {
        display: inline-block;
        margin: .5em
    }
}

#cookieChoiceInfo #cookieChoiceDismiss {
    margin: 0 24px
}

img[style*=left] {
    margin: 0 20px 20px 0
}

img[style*=right] {
    margin: 0 0 20px 20px
}

#content #news .service-links .links {
    margin: 0
}

#content .service-links .links {
    border-top: 0;
    padding: 0
}

#content .service-links .links li {
    display: inline-block;
    padding: 10px 0;
    margin-right: 1em
}

#content .service-links .links li:before {
    content: ''
}

#content .service-links .links li a {
    display: block;
    width: 33px;
    height: 32px;
    padding-top: 0;
    background: url(../images/share-icon.png) no-repeat;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden
}

#content .service-links .links li a.service-links-facebook {
    background-position: center -97px
}

#content .service-links .links li a.service-links-twitter {
    background-position: center -212px
}

#content .service-links .links li a.service-links-linkedin {
    background-position: center -1373px
}

.highcharts-tooltip,
.highcharts-tooltip>span {
    width: 180px
}

.highcharts-tooltip .tooltip {
    white-space: normal!important
}

#content .page table {
    border: 1px solid #c2c2c2;
    width: auto !important;
    margin-bottom: 2em;
    border-collapse: collapse;
    border-spacing: 0;
    overflow-x: auto;
    display: table;

    &[align=center] {
      margin: 0 auto;
    }
}

#content .page table thead {
    display: table-header-group
}

#content .page table th {
    padding: 7px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #c2c2c2;
    border-right: 1px solid #c2c2c2;
    background-color: #e9e9e9
}

#content .page table tr:first-child th {
    font-size: 120%;
    line-height: 22px
}

#content .page table td {
    border-top: 1px solid #c2c2c2;
    line-height: 16px;
    vertical-align: middle;
    padding: 4px 8px
}

@media screen and (max-width:1200px) {
    #content .page table {
        text-align: center;
        //display: table
    }
    #content .page table p {
        padding-bottom: 5px
    }
    #content .page table caption {
        background-image: none
    }
    .page-node-13058 .content .field-item table.mobile-tb thead {
        display: none
    }
    #content .page table tbody td {
        //display: block;
        padding: .6rem
    }
    #content .page table tbody tr td:first-child {
        //background: #e9e9e9;
        //font-weight: 700;
        //font-size: 120%
    }
    #content .page table tbody td:before {
        content: attr(data-th);
        font-weight: 700;
        display: inline-block
    }
    #content .page table thead {
        //display: none
    }

    #content .page .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      //border: 1px solid #ddd;
    }
}

header.main {
    width: 100%;
    //height: 150px;
    background: url(../images/header_bg.jpg) left top repeat-x;
    border-bottom: 6px solid #005d25;
    padding-top: 30px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 202;
    transition: height .5s
}

header.main>.box-content {
    //height: 150px;
    transition: height .5s
}

header.main .logo {
    display: inline-block;
    position: relative
}

header.main .logo img {
    width: auto;
    height: 120px;
    transition: height .5s
}

header.main .first-level>li,
header.main .submenu {
    transition: 500ms
}

@media only screen and (max-width:959px) {
    header.main {
        position: static;
        //height: 140px;
        padding-top: 0
    }
    header.main .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%)
    }
    header.main>.box-content {
        text-align: center
    }
    .network header.main {
        //height: 198px
    }
}

@media only screen and (max-width:959px) {
    header.main {
        //height: 110px
    }
    header.main .social,
    header.main .utility {
        display: none
    }
    header.main .logo {
        top: 38%;
        width: 55%;
        height: auto
    }
    header.main .logo img {
        max-width: 100%;
        height: auto
    }
    header.main .burger-menu {
        top: 44px
    }
}

header.small {
    height: 80px;
    padding-top: 10px
}

header.small>.box-content {
    height: 80px
}

header.small .logo img {
    height: 70px
}

header.small .first-level>li {
    height: 45px
}

header.small .mega-menu,
header.small .submenu {
    top: 95px
}

nav {
    position: absolute;
    right: 0;
    bottom: 0
}

nav .first-level>li {
    display: block;
    position: relative;
    height: 70px;
    float: left;
    padding: 0 10px
}

nav .first-level>li.active:before,
nav .first-level>li:hover:before {
    content: 'n';
    font-family: owsd;
    line-height: .6;
    color: #005d25;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center
}

nav .first-level>li.active>a,
nav .first-level>li:hover>a {
    color: #03a242;
    border-bottom: 1px solid #005d25
}

nav .first-level>li>a {
    font: 1.1em Cabin, Arial, sans-serif;
    color: #fff;
    text-transform: uppercase
}

nav .first-level>li>a:focus,
nav .first-level>li>a:hover {
    color: #03a242;
    border-bottom: 1px solid #005d25
}

@media only screen and (max-width:959px) {
    nav {
        left: 0;
        top: 115px;
        right: auto;
        bottom: auto;
        width: 100%
    }
    nav .first-level>li {
        float: none;
        height: auto;
        padding: 0
    }
    nav .first-level>li.active:before,
    nav .first-level>li:hover:before {
        content: '';
        width: 0
    }
    nav .first-level>li>a {
        display: block;
        padding: 9px 0;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 1.4em;
        color: #469267;
        border-bottom: 1px solid #333
    }
}

.mega-menu {
    display: none;
    position: fixed;
    left: 0;
    top: 185px;
    width: 100%;
    height: auto;
    background: url(../images/megamenu_pattern.png);
    box-shadow: 0 5px 6px 0 #dedede;
    transition: 500ms;
    transition-property: top
}

.mega-menu .box-content, .mega-menu .box-content-new {
    background: url(../images/mega_menu_bg.gif) left top repeat-y #fff
}

.mega-menu .cta {
    width: 239px;
    height: 147px;
    padding: 33px 21px 0 130px;
    background-repeat: no-repeat
}

.mega-menu .cta h3 {
    font-size: 1.5em;
    color: #005d25
}

.mega-menu .cta h3 a {
    color: #005d25
}

.mega-menu .cta a {
    color: #525252
}

.mega-menu .cta a.more {
    font-weight: 700;
    color: #005d25;
    font-size: .9em
}

.mega-menu .about-cta {
    background-image: url(../images/megamenu_about_icon.png);
    background-position: 18px 34px
}

.mega-menu .why-cta {
    background-image: url(../images/megamenu_why_icon.png);
    background-position: 14px 34px
}

.mega-menu .network-cta {
    background-image: url(../images/megamenu_network_icon.png);
    background-position: 7px 37px
}

.mega-menu .career-cta {
    background-image: url(../images/megamenu_career_icon.png);
    background-position: 26px 34px
}

.mega-menu .resources-cta {
    background-image: url(../images/megamenu_resources_icon.png);
    background-position: 38px 34px
}

.mega-menu .block-menu {
    padding: 3% 0;
    position: relative
}

.mega-menu .block-menu li {
    list-style: none;
    list-style-image: none;
    line-height: 1;
    border-right: 1px solid #ababab
}

.mega-menu .block-menu li ul {
    display: none;
    position: absolute;
    left: 100%;
    top: 32px;
    height: 100%
}

.mega-menu .block-menu li li a {
    min-width: 200px;
    padding-left: 21px;
    font-weight: 400
}

.mega-menu .block-menu li li a.active,
.mega-menu .block-menu li li a:focus,
.mega-menu .block-menu li li a:hover {
    background: 0 0;
    color: #005d25
}

.mega-menu .block-menu a {
    display: block;
    padding: 6px 27px;
    color: #525252;
    font-size: 1.1em;
    font-weight: 700
}

.mega-menu .block-menu a:before {
    content: '•';
    margin-right: 9px
}

.mega-menu .block-menu a.active,
.mega-menu .block-menu a:focus,
.mega-menu .block-menu a:hover {
    background: #9abea9;
    color: #fff
}

@media only screen and (max-width:959px) {
    .mega-menu {
        position: static;
        box-shadow: none;
        background: #2a2a2a;
        padding-left: 30px;
        width: auto
    }
    .mega-menu .box-content {
        background: 0 0;
        padding: 0;
        width: 100%
    }
    .mega-menu .cta {
        display: none
    }
    .mega-menu .block-menu {
        width: 100%
    }
    .mega-menu .block-menu li {
        border-right: none
    }
    .mega-menu .block-menu li.last>a {
        border-bottom: none
    }
    .mega-menu .block-menu li li a {
        min-width: 50%;
        padding-left: 0
    }
    .mega-menu .block-menu li ul {
        position: static;
        display: block;
        margin-left: 5%
    }
    .mega-menu .block-menu li ul a,
    .mega-menu .block-menu li ul a:focus,
    .mega-menu .block-menu li ul a:hover {
        color: #999
    }
    .mega-menu .block-menu a {
        color: #ccc;
        font-weight: 400;
        padding: 9px 15px;
        margin: 0 15px;
        border-bottom: 1px solid #666
    }
    .mega-menu .block-menu a.slicknav_item {
        display: none
    }
    .mega-menu .block-menu a:before {
        content: '';
        margin: 0
    }
    .mega-menu .block-menu a.active,
    .mega-menu .block-menu a:focus,
    .mega-menu .block-menu a:hover {
        background: 0 0
    }
}

.submenu {
    /*position: absolute;*/
    left: 0;
    top: 185px;
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0 2px 6px 0 #dedede
}

.submenu ul.menu {
    display: table;
    width: 100%
}

.submenu ul.menu li {
    display: table-cell;
    margin: 0;
    padding: 0
}

.submenu a {
    font-size: 1.5em;
    font-weight: 300;
    color: #525252;
    text-transform: uppercase;
    display: block;
    height: 60px;
    text-align: center;
    line-height: 3
}

.submenu a.active,
.submenu a:focus,
.submenu a:hover {
    color: #fff;
    background: #ffbf00
}

@media only screen and (max-width:959px) {
    .submenu {
        top: 175px
    }
    .submenu a {
        font-size: 1.1em;
        line-height: 4;
        font-weight: 700
    }
}

.utility {
    position: absolute;
    top: 42px;
    right: 0
}

.utility li {
    display: inline-block;
    position: relative;
    padding: 0 10px
}

.utility a {
    font-size: .9em;
    color: #888;
    font-weight: 700;

    &:hover {
      color: #333 !important;
    }
}

.utility a:focus,
.utility a:hover {
    color: #fff
}

.small .utility {
    top: 0;
    right: 100px
}

@media only screen and (max-width:767px) {
    .utility {
        top: 5px;
        right: 100px
    }
}

.social {
    position: absolute;
    right: 0;
    top: 0
}

.social li {
    display: block;
    float: left;
    padding: 0 4px
}

.social li span {
    display: none
}

.social a {
    color: #fff;
    font-size: 1.4em
}

@media only screen and (max-width:767px) {
    .social {
        top: 5px
    }
}

.burger-menu {
    display: none;
    position: absolute;
    left: 9px;
    top: 60px;
    color: #fff;
    font-size: 2.2em
}

.burger-menu span,
.slideout-menu {
    display: none
}

@media only screen and (max-width:959px) {
    .burger-menu {
        display: inline-block
    }
}

@media only screen and (max-width:959px) {
    .slideout-open .slideout-panel,
    .slideout-open body {
        overflow: hidden
    }
    .slideout-open .slideout-menu {
        display: block
    }
    .slideout-menu {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        background: #1a1a1a;
        width: 280px;
        min-height: 100%;
        height: auto;
        overflow: auto;
        -webkit-overflow-scrolling: auto
    }
    .slideout-menu>.mobile_logo {
        background-color: #fff
    }
    .slideout-menu>.mobile_logo>img {
        display: block
    }
}

#slideshow {
    width: 100%;
    overflow: hidden;
    position: relative
}

#slideshow .slide {
    width: 100%;
    position: relative;
    float: left
}

#slideshow .slide img.image-slide {
    display: block;
    width: 100%;
    min-width: 100%;
    height: auto
}

#slideshow .slide .info {
    width: 448px;
    padding: 24px 21px 10px;
    margin-top: -150px;
    position: absolute;
    top: 50%;
    right: 20%;
    z-index: 101;
    background-color: rgba(231, 231, 233, .8);
    border-radius: 15px
}

#slideshow .slide .info.generic {
    padding: 24px 21px 10px
}

#slideshow .slide .info.generic .field-date {
    color: #005C2B;
    padding-left: 10px
}

#slideshow .slide .info.generic blockquote {
    margin: 0;
    font-size: 25px;
    line-height: 1.1
}

#slideshow .slide .info.generic blockquote a {
    color: #525252
}

#slideshow .slide .info.generic blockquote:after,
#slideshow .slide .info.generic blockquote:before {
    content: initial
}

#slideshow .slide .info.generic a.read-more-yellow-plus:before {
    line-height: 2
}

#slideshow .slide .info.succes-story a.read-more-yellow-plus {
    margin-left: 9px
}

#slideshow .slide blockquote {
    font-style: normal;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.2;
    display: block;
    padding-left: 10px
}

#slideshow .slide blockquote a {
    color: #525252
}

#slideshow .slide blockquote:before {
    position: absolute;
    left: 10px
}

#slideshow .slide blockquote:after {
    margin-left: 0
}

#slideshow .slide blockquote strong {
    font-weight: 300;
    color: #005d25
}

#slideshow .slide .member {
    margin: 5px 10px
}

#slideshow .slide .member img {
    height: auto;
    padding: 2px;
    background: #fff;
    box-shadow: 0 0 2px 2px #b5b4b3
}

#slideshow .slide .member a {
    color: #333
}

#slideshow .slide .member h5 {
    font-weight: 400;
    padding: 5px 0 18px 9px;
    font-size: 1.3em;
    margin-right: 12px
}

#slideshow .slide .member h5 strong {
    font-weight: 400;
    color: #005d25
}

#slideshow .slick-next,
#slideshow .slick-prev {
    width: 50px;
    height: 50px
}

#slideshow .slick-next:before,
#slideshow .slick-prev:before {
    color: #005d25;
    font-size: 26px;
    font-weight: 700;
    background-color: rgba(231, 231, 233, .8);
    border-radius: 50px;
    padding: 5px;
    line-height: 36px;
    width: 35px;
    height: 35px
}

#slideshow .slick-next:hover:before,
#slideshow .slick-prev:hover:before {
    background-color: #e7e7e9
}

#slideshow .slick-prev {
    left: 40px
}

#slideshow .slick-next {
    right: 40px
}

@media only screen and (max-width:959px) {
    #slideshow .slide .info {
        width: 276px;
        padding: 24px 12px
    }
    #slideshow .slide .info.generic blockquote {
        font-size: 21.5px
    }
    #slideshow .slide .info.succes-story blockquote {
        font-size: 30px
    }
    #slideshow .slide .info.succes-story a.read-more-yellow-plus {
        margin-left: 0
    }
    #slideshow .slide blockquote {
        font-size: 2.7em;
        height: auto;
        line-height: 1
    }
    #slideshow .slide .member {
        position: relative;
        left: 0;
        bottom: auto
    }
    #slideshow .slide .member a.read-more-yellow-plus {
        clear: both
    }
}

@media only screen and (max-width:767px) {
    #slideshow .slick-next,
    #slideshow .slick-prev {
        visibility: hidden
    }
    #slideshow .slide .info.succes-story blockquote {
        font-size: 12px
    }
    #slideshow .slide .info.generic {
        padding: 10px 5px
    }
    #slideshow .slide .info.generic blockquote {
        font-size: 12px
    }
    #slideshow .slide .info {
        width: 100%;
        margin: 0;
        top: initial;
        bottom: 0;
        right: 0;
        border-radius: 0;
        padding: 10px 5px
    }
    #slideshow .slide .info .field-date,
    #slideshow .slide .info .member {
        display: none
    }
    #slideshow .slide .info blockquote {
        font-size: 12px
    }
    #slideshow .slide .info blockquote:after,
    #slideshow .slide .info blockquote:before {
        content: ''
    }
}

#mission {
    position: relative;
    height: 380px;
    background: #dbe9e1;
    -webkit-box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .25);
    -moz-box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .25);
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .25)
}

#mission .text {
    width: 556px;
    cursor: pointer;
    padding-left: 12px
}

#mission .text p {
    font-size: 1.1em;
    line-height: 1.7
}

#mission .text strong {
    color: #005d25
}

#mission h2 {
    font-weight: 300;
    font-size: 3.7em;
    line-height: 1;
    padding: 27px 0 21px
}

#mission h2 strong {
    font-weight: 800;
    color: #005d25
}

#mission h3 {
    text-transform: uppercase;
    font-size: 1.8em;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 18px
}

#mission .me-ows-cta {
    width: 303px;
    height: 380px;
    background: #E0EEEB;
    position: relative
}

#mission .me-ows-cta img {
    position: absolute;
    top: -25px;
    z-index: 1
}

#mission .me-ows-cta p {
    font-size: .9em;
    margin-bottom: 15px
}

#mission .me-ows-cta h4 {
    color: #78c6e7
}

@media only screen and (max-width:959px) {
    #mission {
        height: auto
    }
    #mission .text {
        width: 100%;
        float: none;
        padding: 0;
        margin-bottom: 45px
    }
    #mission .me-ows-cta {
        display: none;
        width: 100%;
        height: auto;
        float: none;
        padding: 30px 2%;
        margin-left: -2%
    }
}

#career {
    position: relative;
    height: 398px;
    background: url(../images/pattern.gif);
    -webkit-box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .25);
    -moz-box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .25);
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .25);
    padding-top: 2em
}

#career .block-title {
    padding: 9px 0 15px
}

@media only screen and (max-width:959px) {
    #career {
        height: auto
    }
}

#career-slider {
    height: 320px
}

#career-slider .caroufredsel_wrapper {
    border: 2px solid #ebebe9;
    border-radius: 12px
}

@media only screen and (max-width:959px) {
    #career-slider {
        height: 295px;
        background-color: #fff
    }
    #career-slider .caroufredsel_wrapper {
        width: 100%!important;
        border: 0
    }
}

#career-slider-content article {
    width: 931px;
    height: 146px;
    background: #fff;
    padding: 21px 12px 18px
}

#career-slider-content figure {
    width: 265px;
    height: 136px;
    text-align: center;
    padding-top: 9px
}

#career-slider-content .text {
    width: 605px
}

#career-slider-content .text h2 {
    color: #005d25;
    font-size: 1.7em
}

#career-slider-content .text h2 a {
    color: #005d25
}

#career-slider-content .text p {
    line-height: 1.8
}

#career-slider-content .text a.more {
    color: #005d25;
    font-size: .9em
}

@media only screen and (max-width:959px) {
    #career-slider-content {
        width: 100%!important
    }
    #career-slider-content article {
        width: 96%;
        padding: 2%;
        height: auto
    }
    #career-slider-content figure {
        display: none!important
    }
    #career-slider-content .text {
        width: 85%;
        padding-left: 7%
    }
}

#career-pager {
    height: 100px;
    width: 800px;
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-right: -415px
}

#career-pager a {
    display: block;
    width: 192px;
    height: 100px;
    float: left;
    position: relative
}

#career-pager a.first:before {
    content: '';
    display: block;
    position: absolute;
    left: -5px;
    background: #005d25!important
}

#career-pager a.last {
    width: auto
}

#career-pager a.last a.first:before,
#career-pager a.last span.bar,
#career-pager a.last span.bar-bg,
#career-pager a.last span.dot {
    display: none
}

#career-pager span {
    display: block;
    position: absolute
}

#career-pager span.bar,
#career-pager span.bar-bg {
    width: 0;
    height: 4px;
    left: 0;
    top: 0;
    background: #03a242;
    z-index: 101
}

#career-pager span.bar-bg {
    width: 100%;
    background: #eae6d5;
    z-index: 100
}

#career-pager span.label {
    width: 96px;
    text-align: center;
    font-weight: 700;
    color: #005d25;
    font-size: .9em;
    left: -45px;
    top: 20px
}

#career-pager span.label img {
    width: 45px;
    height: auto
}

#career-pager span.label span {
    width: 100%
}

#career-pager a.first:before,
#career-pager span.dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #eae6d5;
    top: -5px;
    right: -5px;
    z-index: 102;
    transition: 1s
}

#career-pager span.active {
    background: #005d25
}

@media only screen and (max-width:959px) {
    #career-pager {
        display: none!important
    }
}

#career-full-slider {
    position: relative;
    padding-top: 39px;
    border-top: 1px solid #eee
}

#career-full-slider .caroufredsel_wrapper {
    margin-top: 85px!important
}

#career-full-slider #career-pager {
    top: 20px;
    width: 80%;
    margin: 0 10%;
    right: 0;
    left: 0
}

#career-full-slider #career-pager a {
    width: 25%
}

#career-full-slider #career-pager a .label {
    padding: 15px 0;
    left: -50px;
    top: 30px
}

#career-full-slider #career-pager a .label span {
    background-color: #005d25;
    border-radius: 5px;
    color: #fff;
    padding: 5px 8px
}

#career-full-slider #career-pager a .label span:hover {
    background-color: #03a242
}

#career-full-slider #career-pager a.last {
    width: auto
}

@media only screen and (max-width:959px) {
    #career-full-slider #career-full-slider-content,
    #career-full-slider .caroufredsel_wrapper {
        width: 100%!important;
        height: 565px!important
    }
    #career-full-slider #career-full-slider-content article,
    #career-full-slider .caroufredsel_wrapper article {
        width: 100%
    }
    #career-full-slider #career-full-slider-content article figure,
    #career-full-slider .caroufredsel_wrapper article figure {
        width: 50%;
        height: 100%;
        margin-right: 10%
    }
    #career-full-slider #career-full-slider-content article figure img,
    #career-full-slider .caroufredsel_wrapper article figure img {
        width: 100%;
        max-width: 300px;
        height: auto
    }
    #career-full-slider #career-full-slider-content article .text,
    #career-full-slider .caroufredsel_wrapper article .text {
        width: 40%;
        float: left
    }
}

@media only screen and (max-width:610px) {
    #career-full-slider #career-full-slider-content,
    #career-full-slider .caroufredsel_wrapper {
        height: 370px!important
    }
}

#career-full-slider-content article {
    width: 700px
}

#career-full-slider-content figure {
    width: 300px;
    height: 565px
}

#career-full-slider-content .text {
    width: 375px
}

#career-full-slider-content h2 {
    color: #005d25;
    font-weight: 400;
    margin-bottom: 15px
}

#career-full-slider-content a.more {
    font-size: .9em
}

#career-full-slider-content a.more:hover {
    background-color: #03a242
}

#career-full-pager {
    height: 100px;
    width: 960px
}

#career-full-pager a {
    display: block;
    position: relative
}

#career-full-pager a.page-0 {
    width: 0
}

#career-full-pager a.page-1 {
    width: 218px
}

#career-full-pager a.page-2 {
    width: 98px
}

#career-full-pager a.page-3 {
    width: 85px
}

#career-full-pager a.page-4 {
    width: 74px
}

#career-full-pager a.first #career-pager a.first:before,
#career-full-pager a.first span.dot,
#career-pager #career-full-pager a.first a.first:before {
    background: #005d25!important
}

#career-full-pager a:hover span.label {
    display: block
}

#career-full-pager a.selected #career-pager a.first:before,
#career-full-pager a.selected span.dot,
#career-pager #career-full-pager a.selected a.first:before {
    background: #005d25
}

#career-full-pager span {
    display: block;
    position: absolute
}

#career-full-pager span.bar,
#career-full-pager span.bar-bg {
    width: 0;
    left: 0;
    top: 0;
    background: #03a242;
    z-index: 101
}

#career-full-pager span.bar-bg {
    width: 100%;
    background: #eae6d5;
    z-index: 100
}

#career-full-pager span.label {
    min-width: 140px;
    height: 28px;
    font-size: 1.5em;
    left: 36px;
    bottom: -18px;
    background: #03a242;
    padding: 6px 24px;
    color: #fff;
    border-radius: 0 7px 7px 0;
    box-shadow: 1px 1px 3px #ccc;
    display: none
}

#career-full-pager span.label:before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #03a242;
    position: absolute;
    top: 0;
    left: -20px
}

#career-full-pager #career-pager a.first:before,
#career-full-pager span.dot,
#career-pager #career-full-pager a.first:before {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #eae6d5;
    bottom: -5px;
    right: -5px;
    z-index: 102
}

#career-full-pager span.active {
    background: #005d25
}

.career-controls a {
    display: block;
    height: 30px;
    position: absolute;
    top: 50%;
    margin-top: -60px;
    font-size: 2.1em;
    line-height: 1;
    color: #03a242
}

.career-controls a#prev-career {
    left: -30px
}

.career-controls a#next-career {
    right: -30px
}

@media only screen and (max-width:959px) {
    .career-controls a#prev-career {
        left: 0
    }
    .career-controls a#next-career {
        right: 0
    }
}

#focus {
    background: #efeeeb;
    height: 400px;
    padding-top: 18px;
    overflow: hidden
}

#focus .slick-list {
    margin: 0 auto
}

#focus .slick-next:before {
    content: 'l'
}

#focus .slick-prev:before {
    content: 'm'
}

#focus .block-title {
    margin-bottom: 26px
}

#focus .news {
    width: 209px;
    height: 304px;
    padding: 6px 24px 6px 6px;
    position: relative;
    margin: 0!important;
    border-top: 8px solid #efeeeb
}

#focus .news:hover {
    background: #d2ecdc;
    border-top-color: #005d25
}

#focus .news span {
    display: block;
    font-size: .9em
}

#focus .news span.label {
    font-style: italic;
    color: #bbb
}

#focus .news span.label:before {
    font-size: 2em;
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px
}

#focus .news span.date {
    color: #888;
    margin-top: 9px
}

#focus .hidden-element {
    display: none
}

#focus header {
    margin: 6px 0 30px
}

#focus header h3 {
    text-transform: uppercase;
    color: #005d25;
    font-size: 1.1em
}

#focus header h2 {
    font-size: 1.3em;
    line-height: 1.2
}

#focus header a {
    color: #333
}

#focus p {
    line-height: 1.5
}

#focus a.more {
    position: absolute;
    color: #005d25;
    left: 6px;
    bottom: 12px;
    font-weight: 700;
    font-size: .9em
}

#focus a.all {
    left: auto;
    bottom: auto;
    top: 7px;
    right: 0;
    text-transform: uppercase;
    font-size: .9em;
    font-weight: 400
}

@media only screen and (max-width:959px) {
    #focus a.all {
        display: none
    }
}

@media only screen and (min-width:768px) and (max-width:959px) {
    #focus .slick-list {
        width: 478px
    }
    #focus .slick-prev {
        left: 0
    }
    #focus .slick-next {
        right: 0
    }
}

@media only screen and (max-width:767px) {
    #focus #news-filters {
        display: none
    }
    #focus .block-title {
        text-align: center
    }
    #focus .slick-list {
        width: 239px
    }
    #focus .slick-prev {
        left: 0
    }
    #focus .slick-next {
        right: 0
    }
}

#news-filters {
    position: absolute;
    top: 2px;
    left: 20%;
    font-size: 1.2em
}

#news-filters label {
    font-weight: 400;
    display: block;
    margin-left: 12px
}

@media only screen and (max-width:959px) {
    #news-filters {
        left: 30%
    }
}

@media only screen and (max-width:959px) {
    #focus .news {
        background: #d2ecdc;
        border-top-color: #005d25
    }
}

#network-rest-of-the-world {
    display: none
}

#networks {
    height: 850px;
    border-bottom: 6px solid #005d25;
    padding: 36px 0 90px;
    overflow: hidden
}

#networks .block-title {
    margin-bottom: 12px
}

#networks #networks-list {
    margin-left: -1px
}

#networks .network {
    width: 239px;
    height: 815px;
    position: relative
}

#networks .network:first-child .network-info {
    border-left: 1px solid #eee
}

#networks .network h2 {
    cursor: pointer;
    width: 239px;
    height: 75px;
    display: table-cell;
    padding-bottom: 25px;
    color: #005d25;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 2.2em;
    line-height: 1;
    text-align: center;
    vertical-align: bottom
}

#networks .network-info {
    border-right: 1px solid #eee;
    padding: 0 9px
}

#networks .map {
    height: 233px
}

#networks .map a {
    display: block;
    width: 100%;
    height: 233px;
    text-indent: -9999px;
    background-position: 0 0
}

#networks .map a:focus,
#networks .map a:hover {
    background-position: -220px 0
}

#networks .chart {
    position: absolute;
    top: 220px;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    height: 200px
}

#networks .info {
    height: 70px;
    background: #eee;
    padding-top: 90px;
    font-weight: 600
}

#networks .info p {
    font-size: 1.1em;
    line-height: 1.3
}

#networks .info p strong {
    display: inline-block;
    width: 55px;
    text-align: right;
    color: #3d8e00;
    font-size: 1.5em;
    font-weight: 400
}

#networks .members {
    margin-top: 15px
}

#networks .members h4 {
    color: #333;
    font-weight: 400;
    font-size: 1.3em;
    margin-bottom: 12px
}

#networks .members figure {
    width: 80px
}

#networks .members figure img {
    width: 80px;
    height: auto
}

#networks .members .more {
    text-align: center
}

#networks .members .more a {
    color: #005d25;
    font-size: .9em;
    text-transform: uppercase
}

@media only screen and (min-width:768px) and (max-width:959px) {
    #networks .slick-list {
        width: 478px;
        margin: 0 auto
    }
    #networks .slick-prev {
        left: 0
    }
    #networks .slick-next {
        right: 0
    }
}

@media only screen and (max-width:767px) {
    #networks .slick-list {
        width: 239px;
        margin: 0 auto
    }
    #networks .slick-prev {
        left: 0
    }
    #networks .slick-next {
        right: 0
    }
}

.member-short {
    height: 80px;
    margin-bottom: 10px
}

.member-short figure {
    margin-right: 9px
}

.member-short figure img {
    width: 80px;
    height: 80px
}

.member-short .member-info {
    width: 150px
}

.view--front-members .member-short .member-info {
    width: 124px
}

.member-short .member-info span.type {
    color: #005d25;
    font-weight: 700;
    font-size: .8em;
    text-transform: uppercase
}

.member-short .member-info a {
    color: #525252
}

.member-short .member-info h5 {
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.1
}

.member-short .member-info p {
    font-size: .9em;
    line-height: 1.3
}

.member-short:hover {
    cursor: pointer;
    border-right: 4px solid #03a242
}

.member-short-success {
    border: 3px solid #FFC100;
    height: 74px;
    background: url(../images/top_member_badge_small.gif) 180px 45px no-repeat
}

.member-short-success figure {
    width: 77px;
    height: 74px;
    overflow: hidden
}

.map-image {
    height: 200px;
    text-align: center;
    background: #eee
}

.map-image img {
    max-width: 100%;
    height: auto
}

@media only screen and (max-width:959px) {
    .map-image {
        height: auto
    }
    .map-image img {
        display: block
    }
}

.network-full-info {
    width: 240px;
    height: 385px;
    padding: 20px 25px 0;
    position: absolute;
    right: 0;
    top: -170px;
    background-repeat: no-repeat;
    color: #999;
    line-height: 1.5em
}

.network-full-info strong {
    font-weight: 400;
    color: #fff
}

.network-full-info .member {
    margin: 15px 0 24px;
    padding: 15px 0;
    border-top: 1px dashed #AAA;
    border-bottom: 1px dashed #AAA
}

.network-full-info .member figure {
    margin-right: 10px;
    width: 40px;
    height: 40px
}

.network-full-info .member figure img {
    width: 100%;
    height: auto
}

.network-full-info .member p {
    font-size: 1.1em;
    line-height: 1.3
}

.network-full-info .member a {
    font-size: .8em;
    color: #999
}

.network-full-info .member-info {
    width: 190px
}

.network-full-info blockquote {
    color: #fff;
    font-weight: 300;
    font-size: 1.1em
}

.network-full-info blockquote:after,
.network-full-info blockquote:before {
    color: #fff;
    font-size: 1em
}

.network-full-info blockquote:before {
    margin-top: -10px
}

.network-full-info blockquote:after {
    float: right;
    margin-top: 12px
}

.network-full-info blockquote strong {
    display: block;
    font-size: .7em
}

.network-full-info .stats {
    position: absolute;
    right: 25px;
    top: 300px
}

.network-full-info .stats p {
    font-size: 1.1em;
    text-align: right;
    color: #fff;
    line-height: 1.7;
    border-top: 1px solid #AAA;
    border-bottom: 1px solid #AAA
}

.network-full-info .stats p:first-child {
    color: #438d00;
    border-bottom: 0
}

.network-full-info .stats p:first-child strong {
    color: inherit
}

.network-full-info .stats p strong {
    font-size: 1.5em
}

.network-full-info .stats.country p {
    border: 0;
    margin-top: 12px
}

.network-full-info .network-chart {
    z-index: 1;
    width: 238px;
    height: 238px;
    position: absolute;
    bottom: -90px;
    left: -90px
}

@media only screen and (max-width:959px) {
    .network-full-info {
        top: 0;
        margin: 0 auto
    }
}

@media only screen and (max-width:767px) {
    .network-full-info {
        position: relative;
        width: 100%;
        height: auto;
        padding: 36px 2%;
        margin-left: -2%;
        background-color: #3c3c3c;
        background-image: none!important
    }
    .network-full-info .member {
        margin: 0;
        padding: 36px 0;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff
    }
    .network-full-info .member figure {
        width: 130px;
        height: 130px
    }
    .network-full-info .member-info {
        width: 50%
    }
    .network-full-info blockquote {
        margin: 21px 0
    }
    .network-full-info .network-chart {
        display: none
    }
    .network-full-info .stats {
        position: static
    }
    .network-full-info .stats p {
        text-align: center
    }
}

#network-mission .text {
    width: 530px;
    padding: 30px 0
}

#network-mission h1 {
    color: #fff;
    font-weight: 300;
    font-size: 2em;
    text-transform: uppercase;
    line-height: 1.1em
}

#network-mission h2 {
    font-weight: 300;
    font-size: 1.7em
}

#network-mission ul {
    padding: 15px 0 0
}

#network-mission li {
    padding: 0 0 5px 25px;
    list-style: none;
    list-style-image: none;
    position: relative;
    line-height: 1.7
}

#network-mission li:before {
    content: 'i';
    font-family: owsd;
    font-size: .8em;
    color: #005d25;
    position: absolute;
    left: 0;
    top: 6px
}

@media only screen and (max-width:959px) {
    #network-mission {
        height: auto;
        padding-bottom: 130px
    }
    #network-mission .text {
        width: 60%
    }
}

@media only screen and (max-width:767px) {
    #network-mission .text {
        width: 100%
    }
}

#network-conference {
    background: #dbe9e1
}

#network-conference .box-content {
    padding-top: 33px;
    padding-bottom: 33px
}

#network-conference h3.block-title {
    margin-bottom: 15px
}

#network-conference .info {
    width: 164px;
    height: 320px;
    overflow: hidden;
    position: relative;
    padding: 40px 23px 9px;
    background: #fff;
    float: right;
    margin-top: -70px
}

#network-conference .info:before {
    color: #4a9169;
    font-size: 8em;
    display: inline-block;
    margin-bottom: 25px
}

#network-conference .info:after {
    content: '...';
    position: absolute;
    bottom: 4px;
    right: 35px
}

#network-conference .info p {
    padding-bottom: 18px
}

#network-conference .info a {
    color: #005d25
}

#network-conference figure {
    margin-right: 65px
}

#network-conference .text {
    width: 375px
}

#network-conference header {
    margin-bottom: 21px
}

#network-conference span.date {
    color: #005d25;
    font-weight: 300;
    font-size: 1.5em
}

#network-conference h2 {
    font-size: 1.5em
}

#network-conference h2 a {
    color: #525252
}

#network-conference p {
    padding-bottom: 9px
}

#network-conference p a {
    color: #005d25
}

@media only screen and (max-width:959px) {
    #network-conference .info {
        display: none
    }
}

#national-chapters {
    padding: 45px 0
}

#national-chapters h3.block-title {
    margin-bottom: 18px
}

#national-chapters .region {
    width: 940px;
    height: 300px;
    background: #fff;
    border: 10px solid #efeeeb;
    margin-bottom: 18px
}

#national-chapters .region:hover {
    background-color: #d5e3da
}

#national-chapters div.text {
    width: 272px;
    height: 282px;
    cursor: pointer;
    border-right: 1px solid #ccc;
    padding: 18px 21px 0
}

#national-chapters div.text .info {
    margin-top: 33px
}

#national-chapters div.text .info h4 {
    font-weight: 400
}

#national-chapters div.text .info strong {
    font-size: 1.6em;
    font-weight: 400
}

#national-chapters div.text .info a {
    color: #005d25
}

#national-chapters .no-coordinator {
    background: #efeeeb url(../images/icon-network.png) no-repeat 20px center
}

#national-chapters .no-coordinator .text>div,
#national-chapters .no-coordinator .text>p {
    //width: 60%;
    //margin-left: 35%
}

@media only screen and (max-width:959px) {
    #national-chapters .no-coordinator .text>div,
    #national-chapters .no-coordinator .text>p {
        width: 70%;
        margin-left: 25%
    }
}

#national-chapters .chart {
    width: 262px;
    height: 279px;
    background-color: #fff;
    border-right: 1px solid #ccc;
    padding: 20px 25px 0;
    position: relative
}

#national-chapters .chart .chart-content {
    width: 238px;
    height: 238px;
    margin: -12px auto 0 auto
}

#national-chapters .chart p {
    font-size: 1.2em
}

#national-chapters .chart strong {
    font-weight: 400;
    font-size: 2em
}

#national-chapters .members {
    width: 302px;
    height: 290px;
    padding: 10px 0 0 10px;
    position: relative;
    background-color: #fff
}

#national-chapters .members .member-short {
    /*width: 290px*/
}

#national-chapters .members .member-short-success {
    width: 284px;
    background: 0 0;
    position: relative
}

#national-chapters .members .member-short-success:before {
    display: block;
    width: 52px;
    height: 66px;
    content: '';
    background: url(../images/network_member_success_badge.png) no-repeat;
    position: absolute;
    right: -9px;
    top: 10px
}

#national-chapters header {
    margin-bottom: 9px
}

#national-chapters header h2 {
    font-weight: 300;
    font-size: 2.5em;
    text-transform: uppercase
}

#national-chapters header h2 a {
    color: #005d25
}

#national-chapters p {
    font-size: .9em
}

#national-chapters p a {
    color: #525252
}

#national-chapters .more {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 6px;
    left: 0
}

#national-chapters .more a {
    font-size: .9em;
    text-transform: uppercase;
    color: #005d25
}

@media only screen and (max-width:959px) {
    #national-chapters .region {
        width: 100%;
        height: auto;
        box-sizing: border-box
    }
    #national-chapters .members,
    #national-chapters .text {
        width: 46%;
        padding: 2%;
        height: auto;
        border: none
    }
}

@media only screen and (max-width:767px) {
    #national-chapters .text {
        width: 96%
    }
    #national-chapters .text .info {
        padding-top: 33px;
        border-top: 1px solid #525252;
        font-size: 1.5em
    }
    #national-chapters .no-coordinator .info {
        border-top: 0;
        padding-top: 0;
        margin-top: 0
    }
    #national-chapters .members {
        display: none
    }
}

#national-chapters-filters {
    font-size: 1.2em;
    margin-bottom: 60px;
    background: #4d906a;
    padding: 15px;
    color: #fff
}

#national-chapters-filters p {
    font-size: 1em
}

#national-chapters-filters a {
    color: #fff;
    display: inline-block;
    margin: 0 24px 0 9px
}

#national-chapters-filters a:after {
    font-family: owsd;
    color: #fff;
    background: #000;
    content: 'K';
    margin-left: 9px
}

#national-chapters-filters a.active:after {
    content: 'J'
}

#national-chapters-filters label {
    font-weight: 400;
    display: block;
    margin-left: 12px
}

#country {
    position: relative
}

#country header {
    width: 370px;
    min-height: 300px
}

#country header h1 {
    font-size: 2.2em
}

#country header h2 {
    font-weight: 300;
    font-size: 1.5em
}

#country .chosen-container-single .chosen-single {
    height: 30px;
    background: 0 0;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    line-height: 2
}

#country .chosen-container-single .chosen-drop li {
    padding: 7px
}

#country .chosen-container-single .chosen-drop li:before {
    content: ''
}

#country .chosen-container-single .chosen-drop li.highlighted {
    background: 0 0;
    background-color: #f9f9f9;
    color: #ffc200
}

#country .chosen-container-active .chosen-single {
    border-color: #ffc200
}

#country .form-submit {
    line-height: 29px;
    background-color: #ffc200;
    border: #ffc200;
    cursor: pointer
}

#country .network-full-info {
    height: 204px;
    top: -45px;
    background: url(../images/network_info_bg.png)
}

#country .network-full-info .member {
    margin-top: 0;
    margin-bottom: 0
}

#country .network-full-info .stats {
    position: static
}

#country .network-full-info .stats p {
    text-align: left;
    line-height: 1
}

#country .network-full-info.country {
    height: 250px
}

#country .network-chart {
    left: 50%;
    bottom: -140px;
    margin-left: -119px
}

#country .accordion {
    border: none
}

#country .accordion h3.block-title {
    color: #005d25;
    font-size: 1.5em;
    margin-bottom: 15px
}

#country .accordion dt {
    height: 80px;
    padding: 4px 4px 7px;
    border-width: 4px 4px 1px;
    border-color: #fff #fff #eee;
    border-style: solid;
    text-transform: none;
    font-weight: 400;
    font-size: 1em
}

#country .accordion dt:after {
    color: #ccc;
    right: 18px;
    display: none
}

#country .accordion dt .member-info {
    padding-top: 9px
}

#country .accordion dt span.clear {
    display: block
}

#country .accordion dt figure {
    margin-right: 27px
}

#country .accordion dt figure img {
    width: 80px;
    height: auto
}

#country .accordion dt span.year {
    font-weight: 700
}

#country .accordion dt h5 {
    font-weight: 400;
    font-size: 1.5em
}

#country .accordion dt p {
    font-size: .9em;
    padding: 0
}

#country .accordion dt span.type {
    position: absolute;
    right: 55px;
    top: 30px;
    color: #636372;
    font-weight: 700;
    font-size: .8em;
    text-transform: uppercase
}

#country .accordion dt span.type:after {
    font-size: 4em;
    display: inline-block;
    vertical-align: top;
    margin-left: 9px
}

#country .accordion dt.yellow-button {
    background-image: url(../images/top_member_badge_small.gif);
    background-position: 480px 0;
    background-repeat: no-repeat;
    border-color: #ffc200;
    border-width: 4px;
    padding-bottom: 4px
}

#country .accordion dt.on {
    border-width: 4px 4px 0;
    border-color: #eee;
    border-style: solid;
    padding: 4px
}

#country .accordion dt.on.yellow-button {
    border-color: #ffc200
}

#country .accordion dt.on span.type {
    display: block;
    width: 80px;
    padding-top: 45px;
    right: auto;
    left: 9px;
    top: 100px;
    text-align: center
}

#country .accordion dt.on span.type:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0
}

#country .accordion dt:hover {
    background-color: #d2ecdc
}

#country .accordion dd {
    padding: 4px;
    border-width: 0 4px 4px;
    border-color: #eee;
    border-style: solid;
    display: none
}

#country .accordion dd.yellow-content {
    border-color: #ffc200
}

#country .accordion .member-info-accordion {
    padding-left: 107px
}

#country .accordion .member-info-accordion .profession {
    padding: 9px 0
}

#country .accordion .member-info-accordion .profession h4 {
    font-size: 1.1em
}

#country .accordion .member-info-accordion .profession p {
    font-size: 1.1em;
    padding: 0;
    line-height: 1.5
}

#country .accordion .member-info-accordion .text p {
    font-size: .9em
}

#country .accordion .member-info-accordion .thesis {
    padding-bottom: 18px
}

#country .accordion .member-info-accordion .thesis p {
    padding: 0
}

#country .accordion .member-info-accordion .thesis strong {
    font-size: 1.2em
}

@media only screen and (max-width:767px) {
    #country header {
        width: 100%
    }
    #country .network-full-info {
        background: #3c3c3c;
        height: auto
    }
    #country .network-full-info .stats {
        margin: 50px 0 36px
    }
    #country .network-full-info .stats p {
        text-align: center
    }
    #country .network-chart {
        position: static;
        display: block;
        margin: 0 auto;
        z-index: 1
    }
    #country .accordion dt {
        height: auto
    }
    #country .accordion dt figure {
        width: 30%;
        margin-right: 5%
    }
    #country .accordion dt .member-info {
        width: 65%
    }
}

@media only screen and (max-width:959px) {
    body.page-network {
        padding: 0
    }
    .network header.main {
        //height: 110px
    }
    .submenu {
        display: none
    }
    .network-full-info .stats {
        width: 50%;
        margin: 0 auto
    }
    .network-full-info .stats p:first-child {
        border-top: 0;
        border-bottom: 1px solid #fff
    }
    .network-full-info .stats p,
    .network-full-info .stats p:last-child {
        border: 0
    }
    #national-chapters div.text {
        width: 100%;
        height: auto;
        border: 0;
        padding: 0 0 0 15px
    }
    #national-chapters .region {
        border: 0;
        margin: 0;
        padding: 18px 0;
        border-top: 1px solid #eee
    }
    #national-chapters .region:hover {
        background: 0 0
    }
    #national-chapters .region:after {
        content: 's';
        font-family: owsd;
        color: #ddd;
        position: absolute;
        right: 18px;
        top: 50px;
        font-size: 1.5em
    }
    #national-chapters div.chart {
        height: auto;
        border: 0;
        padding: 0 0 0 15px
    }
    #national-chapters .chart p,
    #national-chapters .chart strong {
        font-size: 17px;
        color: #666
    }
    #national-chapters .no-coordinator {
        background: 0 0
    }
    #country .network-full-info,
    #national-chapters .chart .chart-content,
    #national-chapters .chart .more,
    #national-chapters .no-coordinator .text>div,
    #national-chapters .no-coordinator .text>p,
    #national-chapters .region .text .info,
    #national-chapters .region .text p {
        display: none
    }
    #country header {
        min-height: inherit;
        margin-bottom: 2em
    }
    #national-chapters header h2 {
        font-size: 2em
    }
    .menu-mlid-441 {
        display: none
    }
    #networks {
        height: auto;
        padding-bottom: 20px
    }
    #networks .network {
        width: 100%;
        height: auto;
        border: 0;
        margin: 0;
        padding: 25px 0 25px 5px;
        border-top: 1px solid #eee
    }
    #networks .network:after {
        content: 's';
        font-family: owsd;
        color: #ddd;
        position: absolute;
        right: 18px;
        top: 35px;
        font-size: 1.5em
    }
    #networks .network h2 {
        height: auto;
        text-align: left;
        font-size: 2em;
        padding: 0;
        margin: 0
    }
    #networks .network .network-info {
        border: 0;
        padding: 0
    }
    #networks .info {
        background: 0 0;
        height: auto;
        padding: 0;
        font-weight: 400;
        padding-top: 9px
    }
    #networks .info p>strong {
        width: auto;
        color: #525252;
        font-size: 1.1em
    }
    #networks .chart,
    #networks .map,
    #networks .members {
        display: none
    }
}

#breadcrumbs {
    text-align: right;
    padding-bottom: 24px;
    font-size: .8em
}

#breadcrumbs a {
    color: #525252
}

#breadcrumbs a:focus,
#breadcrumbs a:hover,
#breadcrumbs span.active {
    color: #005d25
}

#content-wrapper {
    padding: 45px 5px
}

@media only screen and (max-width:959px) {
    #content-wrapper {
        padding: 45px 2%
    }
}

.box-content aside .block-title {
    font-weight: 300
}

aside {
    width: 215px
}

aside .block {
    margin-bottom: 50px
}

aside .block-title {
    margin-bottom: 15px;
    color: #005d25;
    text-transform: uppercase
}

aside .block-menu ul ul {
    padding-top: 12px;
    padding-bottom: 12px
}

aside .block-menu ul ul:hover {
    background: #ffcf8f
}

aside .block-menu ul ul ul {
    padding-top: 0;
    padding-bottom: 0
}

aside .block-menu li {
    list-style: none;
    list-style-image: none;
    line-height: 1;
    border-top: 1px dashed #ddd
}

aside .block-menu li.last {
    border-bottom: 1px dashed #ddd
}

aside .block-menu li li,
aside .block-menu li li.last {
    border: none
}

aside .block-menu li li:before {
    content: '•';
    padding: 0 6px
}

aside .block-menu li li a {
    padding: 3px;
    font-weight: 400;
    display: inline-block
}

aside .block-menu a {
    display: block;
    color: #333;
    font-weight: 700;
    font-size: 1.1em;
    padding: 12px 6px
}

aside .block-menu a.active,
aside .block-menu a.active-trail,
aside .block-menu a:focus,
aside .block-menu a:hover {
    color: #005d25;
    background: #f5fcf8
}

aside .block-news article {
    width: 188px;
    background: #f7f6f5;
    border-right: 8px solid #f7f6f5;
    padding: 12px 15px 12px 9px
}

aside .block-news article:hover {
    background: #d2ecdc;
    border-right-color: #005d25
}

aside .block-news .block-title {
    color: #005d25;
    font-size: 1.4em
}

aside .block-news a {
    color: #333
}

aside .block-news span.date {
    color: #005d25;
    font-weight: 700;
    font-size: .9em
}

aside .block-news h2 {
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.3;
    padding: 9px 0
}

aside .block-news p {
    font-size: .9em
}

@media only screen and (max-width:959px) {
    aside {
        display: none
    }
}

#content {
    width: 695px
}

#content h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
    line-height: 1;
    letter-spacing: -.03em
}

#content h2 {
    letter-spacing: -.03em
}

#content .content-return {
    padding-left: 30px
}

#content h3.heading {
    font-weight: 300;
    font-size: 2em;
    padding-top: 18px
}

#content p {
    padding-bottom: 18px;
    line-height: 1.7
}

#content p img {
    //max-width: 100%;
    max-width: calc(100% - 20px);
    height: auto!important;
    padding-top: 4px;
    //margin: 0 20px 5px 0
}

#content ul {
    padding-bottom: 18px
}

#content li {
    padding: 0 0 10px 30px;
    list-style: none;
    list-style-image: none;
    position: relative;
    line-height: 1.7
}

#content li:before {
    content: 'i';
    font-family: owsd;
    font-size: .8em;
    color: #005d25;
    position: absolute;
    left: 0;
    top: 2px
}

#content .image-left {
    float: left;
    margin: 0 21px 21px 0
}

#content .image-right {
    float: right;
    margin: 0 0 21px 21px
}

#content .more-info {
    background: #efeeeb;
    padding: 15px 21px 0;
    margin: 36px 0 40px
}

#content .more-info p:first-child {
    padding-bottom: 0
}

#content .downloads,
#content .links,
#content .proceeding {
    padding: 18px 0;
    border-top: 1px solid #eee
}

#content .downloads li,
#content .links li,
#content .proceeding li {
    padding-bottom: 6px;
    font-weight: 700
}

#content .downloads li img,
#content .links li img,
#content .proceeding li img {
    display: none
}

#content .downloads li:before,
#content .links li:before,
#content .proceeding li:before {
    content: 'o';
    font-size: 1em;
    font-weight: 400;
    top: 0
}

#content .proceeding ul {
    padding-left: 55px;
    position: relative
}

#content .proceeding ul:before {
    content: 't';
    font-family: owsd;
    position: absolute;
    left: 0;
    top: 0;
    color: #efeeeb;
    font-size: 3em
}

#content .links li {
    color: #aaa
}

#content .links li:before {
    content: 'p';
    font-size: 1.7em;
    line-height: 1
}

#content .tags {
    padding: 18px 0;
    border-top: 1px solid #eee
}

#content .tags a {
    display: inline-block;
    background: #f7f6f5;
    font-style: italic;
    padding: 5px 18px;
    margin: 0 9px 9px 0
}

#content .pager {
    display: block;
    padding-top: 10px
}

#content .pager:after {
    display: block;
    content: '';
    clear: both
}

#content .pager li {
    display: block;
    float: left;
    padding: 0;
    margin: 0;
    background: #f7f6f5;
    margin-right: 10px
}

#content .pager li.pager-current {
    padding: 6px 12px;
    font-weight: 400
}

#content .pager li.pager-next {
    float: right;
    margin: 0
}

#content .pager li.pager-first,
#content .pager li.pager-last {
    display: none
}

#content .pager li a {
    display: inline-block;
    padding: 6px 12px;
    color: #333
}

#content .pager li:before {
    content: ''
}

@media only screen and (max-width:959px) {
    #content {
        width: 100%;
        float: none
    }
    #content .pager {
        padding: 10px 2%
    }
}

.page {
    position: relative
}

.page header {
    margin-bottom: 21px
}

.page header h2 {
    color: #005d25;
    font-size: 1.5em
}

.page h2 {
    font-size: 1.7em
}

.page h2.subtitle {
    color: #005d25;
    font-size: 1.5em;
    margin-bottom: 1em
}

.page h2.light-title {
    margin-bottom: 18px
}

.page h3 {
    font-size: 1.7em;
    padding-bottom: 18px
}

.page blockquote {
    color: #005d25;
    font-weight: 300;
    font-size: 1.7em;
    margin-bottom: 18px
}

.page blockquote p {
    line-height: 1.3!important
}

.page a {
    color: #005d25
}

.page a:hover {
    color: #333
}

.page a.button {
    color: #fff
}

.page a.download {
    font-weight: 700
}

.page a.download:before {
    content: 'o';
    font-family: owsd;
    font-size: .9em;
    font-weight: 400;
    margin-right: 18px
}

.page .views-exposed-widgets {
    margin: 0
}

.page .view-filters {
    width: 670px;
    padding: 18px 15px;
    background: #eee
}

.page .views-exposed-form {
    font-size: 1.2em;
    position: relative
}

.page .views-exposed-form .views-exposed-widget {
    float: none;
    padding: 0
}

.page .views-exposed-form label {
    font-weight: 400;
    color: #005d25;
    float: left
}

.page .views-exposed-form .views-widget,
.page .views-exposed-form .views-widget .form-item {
    float: left
}

.page .views-exposed-form .views-widget label {
    float: left;
    color: #525252;
    display: block;
    margin-left: 9px
}

.page .views-exposed-form .views-widget input.form-checkbox {
    float: right
}

.page .views-exposed-form .views-widget select.form-select {
    background: #ddd;
    border: none;
    padding: 3px
}

.page #edit-region-wrapper {
    position: absolute;
    right: 10%;
    top: 0
}

.page #edit-region-wrapper label {
    margin-right: 9px
}

@media only screen and (max-width:959px) {
    .page .view-filters {
        padding: 2%;
        width: 96%
    }
}

.partners-list .partner {
    padding: 24px 0 0;
    margin-bottom: 21px;
    border-width: 4px 1px 1px;
    border-style: solid;
    border-color: #005d25 #ddd #ddd
}

.partners-list .partner .content-return {
    padding-right: 30px
}

.partners-list .partner .content-return strong.green {
    display: block;
    text-transform: uppercase;
    margin: 15px 0
}

.partners-list h2 {
    font-weight: 400;
    font-size: 2em;
    color: #005d25;
    line-height: 1.2em
}

.partners-list h5 {
    margin-bottom: 15px;
    margin-top: 10px
}

.partners-list h5 strong {
    color: #005d25
}

.partners-list .links {
    border: none!important;
    background: #f7f6f5;
    padding: 15px 30px!important
}

.partners-list .links ul {
    padding-bottom: 0!important
}

.block-text {
    padding-top: 33px
}

.block-text h2 {
    color: #005d25;
    font-size: 1.4em;
    margin-bottom: 15px
}

.conference-upcoming {
    border-top: 4px solid #005d25;
    border-bottom: 4px solid #005d25;
    background: #fafaf9;
    min-height: 150px;
    padding: 36px 21px 24px 135px;
    margin-bottom: 2px;
    position: relative
}

.conference-upcoming a.button {
    font-size: .9em
}

.conference-upcoming:before {
    content: 'r';
    font-family: owsd;
    font-size: 12em;
    position: absolute;
    left: -6px;
    top: 33px;
    color: #005d25
}

.conference-upcoming header {
    margin-bottom: 0
}

.conference-upcoming header h3 {
    font-weight: 400;
    padding: 0
}

.conference-upcoming header h4 {
    color: #0085b2;
    font-size: 1.1em;
    line-height: 2
}

.conference-upcoming .green {
    text-transform: uppercase
}

.conference-past {
    padding: 15px 18px 15px 6px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    position: relative;
    margin-top: -1px
}

.conference-past:hover {
    background-color: #d2ecdc
}

.conference-past:after {
    content: 's';
    font-family: owsd;
    color: #ddd;
    position: absolute;
    right: 18px;
    top: 30px;
    font-size: 1.5em
}

.conference-past a {
    display: block
}

.conference-past header {
    margin: 0
}

.conference-past h3 {
    font-weight: 300;
    padding: 0
}

.conference-past h4 a {
    color: #525252
}

@media only screen and (max-width:767px) {
    .conference-past {
        padding: 15px 30px 15px 6px
    }
    .conference-past:after {
        right: 5px
    }
}

#conference header {
    padding-top: 12px
}

#conference header h1 {
    font-size: 1.5em
}

#conference header h2 {
    color: #0085b2
}

#conference .region {
    text-transform: uppercase
}

#conference .content-return {
    border-left: 1px solid #ddd;
    padding-left: 15px;
    margin-left: 15px
}

#conference .global-assembly {
    padding-bottom: 18px
}

#conference .global-assembly h2 {
    font-size: 1.3em;
    color: #0085b2;
    padding-top: 12px
}

#conference .global-assembly h3 {
    font-size: 1.4em;
    padding-bottom: 9px
}

#conference .accordion-member-list dt span {
    float: left
}

#conference .accordion-member-list dt .conference-region {
    padding: 30px 0 0 12px;
    color: #005d25
}

#conference .accordion-member-list dt:after {
    color: #005d25
}

#conference .accordion-member-list h3 {
    font-size: 1.3em;
    margin: 24px 0 40px
}

#conference .accordion-member-list ul {
    margin-left: -20px
}

#conference .accordion-member-list li {
    display: block;
    float: left;
    //width: 280px;
    height: 420px;
    padding: 0 21px;
    background: #f2f1ef;
    margin: 55px 0 24px 20px;
    position: relative
}

#conference .accordion-member-list li:hover {
    background: #d5e3da
}

#conference .accordion-member-list li:before {
    display: none
}

#conference .accordion-member-list li a {
    color: #525252
}

#conference .accordion-member-list li figure {
    width: 100%;
    text-align: center;
    position: absolute;
    top: -55px;
    left: 0
}

#conference .accordion-member-list li h4 {
    padding-top: 160px;
    font-size: 1.4em;
    font-weight: 400
}

#conference .accordion-member-list li h5 {
    color: #005d25;
    font-size: 1.1em;
    text-transform: uppercase;
    line-height: 1
}

#conference .accordion-member-list li h5 a {
    color: #005d25
}

#conference .accordion-member-list li h6 {
    font-weight: 400
}

#conference .accordion-member-list li blockquote {
    display: block;
    text-align: center;
    padding: 30px 0;
    margin-top: 9px;
    position: relative;
    font-size: 1em;
    font-style: italic
}

#conference .accordion-member-list li blockquote:after,
#conference .accordion-member-list li blockquote:before {
    position: absolute;
    font-size: 2.2em
}

#conference .accordion-member-list li blockquote:before {
    left: 0;
    top: 0
}

#conference .accordion-member-list li blockquote:after {
    right: 0;
    bottom: 0
}

@media only screen and (max-width:767px) {
    #conference .content-return {
        margin-left: 2%;
        padding-left: 2%
    }
    #conference .accordion-member-list li {
        width: 85%;
        padding: 0 5%;
        margin-left: 5%
    }
}

#eb-role {
    position: relative;
    padding: 90px 0 40px
}

#eb-role figure {
    width: 100%;
    position: absolute;
    top: -15px;
    left: 60px
}

#eb-role figure figcaption {
    position: absolute;
    top: 50px;
    left: 220px;
    font-size: 1.1em;
    line-height: 1.1
}

#eb-role figure figcaption strong {
    display: block;
    font-size: 1.4em
}

#eb-role header {
    width: 450px;
    height: 138px;
    background: rgba(239, 238, 235, .8);
    margin-left: 30px;
    padding: 27px 0 0 250px
}

#eb-role header h1 {
    font-weight: 400;
    font-size: 2em
}

#eb-role header p {
    line-height: 1.3;
    font-size: 1.1em
}

#eb-role .text {
    width: 580px;
    margin: 0 auto
}

#eb-role blockquote {
    font-style: italic;
    font-size: 1.2em;
    text-align: center;
    margin: 36px 0;
    padding: 0 27px;
    position: relative
}

#eb-role blockquote:after,
#eb-role blockquote:before {
    font-size: 2em;
    position: absolute
}

#eb-role blockquote:before {
    left: 0;
    top: -20px
}

#eb-role blockquote:after {
    right: 0;
    bottom: -20px
}

#eb-role .program {
    margin-top: 27px
}

#eb-role h5 {
    color: #005d25;
    font-weight: 400
}

@media only screen and (max-width:767px) {
    #eb-role {
        padding-top: 30px
    }
    #eb-role figure {
        width: 90%;
        position: relative;
        left: 5%;
        top: 0;
        margin-bottom: -25%
    }
    #eb-role figure img {
        width: 50%;
        height: auto
    }
    #eb-role figure figcaption {
        position: absolute;
        left: 55%;
        top: 0
    }
    #eb-role header {
        width: 92%;
        padding: 25% 2% 2%;
        margin: 0 2%
    }
    #eb-role .text {
        width: 100%
    }
}

.news-list {
    position: relative
}

.news-teaser {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 20px 10px;
    margin-top: -1px;
    position: relative
}

.news-teaser:hover {
    background-color: #d2ecdc
}

.news-teaser:before {
    display: inline-block;
    height: 24px;
    content: 's';
    font-family: owsd;
    font-size: 1.5em;
    color: #005d25;
    position: absolute;
    right: 18px;
    top: 50%;
    margin-top: -14px
}

.news-teaser img {
    width: 200px;
    margin-right: 21px;
    float: left
}

.news-teaser header {
    padding: 9px 0;
    margin: 0
}

.news-teaser header span {
    display: block;
    font-style: italic;
    color: #bbb;
    float: left
}

.news-teaser header span:before {
    font-size: 2em;
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px
}

.news-teaser a {
    color: #525252
}

.news-teaser h2 {
    font-weight: 400
}

.news-teaser h5.date {
    margin-left: 15px;
    margin-top: 3px;
    margin-bottom: 8px
}

.news-teaser h5.date a {
    color: #005d25
}

.news-teaser .text {
    width: 575px
}

.news-teaser .text-with-image {
    width: 390px
}

@media only screen and (max-width:959px) {
    .news-teaser img {
        display: none
    }
    .news-teaser .text {
        width: 90%
    }
}

#news h2.date {
    padding-top: 9px
}

#content #news .links,
#content #news .proceeding,
#news #content .links,
#news #content .proceeding,
#news .downloads {
    margin-top: 32px
}

#event h1,
#event header {
    margin-bottom: 0
}

#event h2.date {
    margin-bottom: 21px
}

#event span.category {
    display: inline-block;
    padding: 9px 0;
    font-size: 1.1em;
    color: #bbb
}

.page .view-front-news-events-focus .view-filters {
    width: 665px
}

.page .view-front-news-events-focus .views-exposed-form label {
    color: #333
}

.page .view-front-news-events-focus .views-exposed-form .views-widget label {
    margin-left: 24px
}

.page .view-front-news-events-focus .views-exposed-form .views-widget input[type=checkbox] {
    margin-top: 6px;
    margin-left: 6px
}

.page .view-front-news-events-focus .views-exposed-form #edit-region-wrapper {
    right: 1%
}

@media only screen and (max-width:959px) {
    .page .view-front-news-events-focus .view-filters {
        width: 96%;
        height: 57px
    }
    .page .view-front-news-events-focus .views-exposed-form #edit-region-wrapper {
        width: 90%;
        top: 30px;
        left: 1px
    }
}

.success-story {
    width: 630px;
    padding: 20px;
    background-color: #f7f6f5;
    background-image: url(../images/success_story_badge.gif);
    background-repeat: no-repeat;
    background-position: 610px 0;
    margin-bottom: 100px;
    position: relative
}

.success-story .member-info {
    width: 490px;
    position: relative;
    z-index: 101
}

.success-story figure {
    float: left;
    width: 132px;
    height: 132px;
    background: #fff;
    padding: 4px;
    margin-right: 21px;
    box-shadow: 0 0 4px #bcbbba
}

.success-story figure img {
    width: 100%;
    height: auto
}

.success-story header {
    float: left;
    width: 328px
}

.success-story header h2 {
    color: #525252;
    font-size: 2.2em;
    font-weight: 400;
    line-height: 1
}

.success-story header h2 a {
    color: #525252
}

.success-story .tagline {
    width: 448px;
    padding: 30px 47px 12px 65px;
    position: absolute;
    right: -21px;
    top: 125px;
    background: #efeeeb;
    border-radius: 24px;
    font-style: italic;
    z-index: 100
}

.success-story .tagline:before {
    display: block;
    width: 25px;
    height: 13px;
    content: '';
    background: url(../images/success_story_arrow.png) no-repeat #efeeeb;
    position: absolute;
    top: -12px;
    left: 58px
}

.success-story .tagline:after {
    display: block;
    width: 220px;
    height: 13px;
    content: '';
    background: url(../images/success_story_shadow.gif) no-repeat;
    position: absolute;
    bottom: -12px;
    right: 0
}

.success-story .read-more-yellow-plus {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 102;
    color: #525252
}

.success-story-even .read-more-yellow-plus {
    text-align: right;
    padding: 10px 30px 0 0
}

.success-story-even .read-more-yellow-plus:before {
    left: auto;
    right: 0
}

.success-story-odd {
    background-position: 21px 0;
    margin-left: 21px
}

.success-story-odd .member-info {
    margin-left: 140px
}

.success-story-odd figure {
    float: right;
    margin-right: 0;
    margin-left: 21px
}

.success-story-odd .tagline {
    right: auto;
    left: -21px;
    background: #eee;
    padding: 30px 65px 12px 47px
}

.success-story-odd .tagline:before {
    background-color: #eee;
    left: auto;
    right: 58px
}

.success-story-odd .read-more-yellow-plus {
    right: auto;
    left: 25px
}

@media only screen and (max-width:959px) {
    .success-story {
        width: 92%;
        padding: 2% 2% 0;
        margin-left: 2%;
        margin-right: 2%;
        background-position: 98% 0
    }
    .success-story .member-info {
        width: auto;
        margin-left: 0
    }
    .success-story figure {
        width: 25%;
        padding: 1%;
        height: auto;
        float: left;
        margin-left: 0;
        margin-right: 2%
    }
    .success-story figure img {
        display: block
    }
    .success-story header {
        padding-top: 30px;
        width: 70%;
        margin-bottom: 0
    }
    .success-story header h2 {
        margin-bottom: 5%
    }
    .success-story header strong {
        display: block
    }
    .success-story .read-more-yellow-plus {
        left: 30%;
        top: 5px;
        right: auto
    }
    .success-story .tagline {
        position: relative;
        width: 100%;
        padding: 2% 4%;
        top: auto;
        left: -4%;
        right: auto;
        transform: translateY(40%)
    }
}

#success-story {
    position: relative;
    padding: 40px 0
}

#success-story:before {
    display: block;
    width: 113px;
    height: 67px;
    content: '';
    background: url(../images/owsd_story_label_inverted.png) no-repeat;
    position: absolute;
    right: -11px;
    top: 58px;
    z-index: 102
}

#success-story figure.bigimage {
    position: relative;
    z-index: 1;
    width: 672px;
    margin-left: 27px;
    padding: 0
}

#success-story figure.bigimage img {
    width: 100%;
    height: auto
}

#success-story .image {
    position: absolute;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 4px solid #fff;
    z-index: 101;
    top: 10px;
    left: -40px;
    overflow: hidden
}

#success-story .image img {
    display: block;
    width: 100%;
    height: auto
}

#success-story .member-info {
    width: 602px;
    height: 144px;
    background: #efeeeb;
    background: rgba(239, 238, 235, .8);
    margin: -30px 0 0 27px;
    padding: 36px 0 0 70px;
    position: relative
}

#success-story .member-info h1 {
    font-weight: 400;
    font-size: 2em;
    color: #525252;
    margin: 7px 0 3px;
    padding-top: 8px
}

#success-story .member-info h1 a {
    color: #525252
}

#success-story .member-info h1 a:hover {
    color: #333
}

#success-story .member-info p {
    line-height: 1.3;
    font-size: 1.1em;
    padding: 0
}

#success-story .member-info p strong {
    font-size: 1.1em
}

#success-story .text {
    width: 580px;
    margin: 0 auto
}

#success-story blockquote {
    font-style: italic;
    font-size: 1.2em;
    text-align: center;
    margin: 36px 0;
    padding: 0 27px;
    position: relative;
    color: #525252
}

#success-story blockquote:after,
#success-story blockquote:before {
    font-size: 2em;
    position: absolute;
    color: #ffc200
}

#success-story blockquote:before {
    content: 'h';
    left: 0;
    top: -20px
}

#success-story blockquote:after {
    content: 'g';
    right: 0;
    bottom: -20px
}

#success-story .program {
    margin-top: 27px
}

#success-story .program h2 {
    margin-bottom: 10px
}

#success-story .links a,
#success-story .links li:before {
    color: #ffc200
}

@media only screen and (max-width:959px) {
    #success-story {
        margin-left: -2%;
        width: 104%
    }
    #success-story:before {
        width: 197px;
        height: 77px;
        background: url(../images/owsd_story_label_mobile_inverted.png) no-repeat;
        transform: scale(0.4)
    }
    #success-story .with-small-sign:before {
        display: none
    }
    #success-story:before {
        right: -49px
    }
    #success-story figure.bigimage {
        display: none
    }
    #success-story .member-info {
        margin: 0;
        width: 96%;
        padding: 3% 2%;
        height: auto
    }
    #success-story .member-info h1 {
        margin-bottom: 18px
    }
    #success-story .member-info strong {
        display: block
    }
    #success-story .image {
        width: 215px;
        height: auto;
        position: static;
        margin-right: 2%;
        float: left;
        border-width: 9px
    }
    #success-story header {
        width: auto;
        float: left;
        padding-top: 70px
    }
    #success-story .member-specialization {
        display: none
    }
    #success-story .text {
        width: 90%;
        padding: 0 5%
    }
}

.publications {
    margin-bottom: 80px
}

.publications .view-header {
    border-bottom: 4px solid #005d25;
    margin-bottom: 10px;
    position: relative
}

.publications .view-header h3 {
    font-size: 1.4em;
    color: #005d25
}

.publications .view-header a.more {
    position: absolute;
    right: 0;
    top: 0;
    text-transform: uppercase
}

.publication {
    padding: 21px 0;
    border-bottom: 1px solid #eee
}

.publication figure {
    width: 120px;
    margin-right: 15px
}

.publication .text {
    width: 545px
}

.publication header h1,
.publication header h2 {
    color: #525252;
    font-size: 1.8em
}

.publication p.author {
    color: #b9b9b9;
    font-style: italic;
    padding-bottom: 9px!important
}

.views-row-last .publication {
    border: 0
}

@media only screen and (max-width:959px) {
    .publication .text {
        width: 100%
    }
    .publication figure.left {
        display: none
    }
}

#member header h2 {
    color: #525252
}

#member .info {
    padding: 27px 36px;
    border-top: 4px solid #ffc200;
    border-bottom: 4px solid #ffc200;
    background: #fafaf9;
    position: relative
}

#member .info figure {
    box-shadow: 0 0 4px #bcbbba;
    width: 255px;
    height: 255px;
    margin-right: 30px;
    box-sizing: border-box;
    border: 8px solid #fff;
    overflow: hidden
}

#member .info .text {
    width: 255px
}

#member .info .text .nationality {
    margin-top: 15px
}

#member .info .text .country {
    font-weight: 700
}

#member .info .text .award_type {
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase
}

#member .info .text .award_type:before {
    content: url(../images/award_type-icon.png);
    width: 30px;
    height: 43px;
    position: relative;
    top: 15px;
    margin-right: 10px
}

#member .info .text .member_qualification_items .year {
    display: none
}

#member .info .text .member_qualification_items .degree {
    margin-top: 10px
}

#member .info span {
    display: block
}

#member .info h1 {
    font-size: 2.3em;
    font-weight: 400;
    margin-bottom: 3px
}

#member .info span.eb-role {
    font-weight: 700;
    text-transform: uppercase;
    font-size: .8em;
    margin: 9px 0 27px
}

#member .info span.eb-role:before {
    font-size: 4.2em;
    vertical-align: middle
}

#member .info p {
    padding-bottom: 21px;
    font-size: 1.1em;
    line-height: 1.4
}

#member .info .success-story-badge {
    width: 120px;
    height: 35px;
    padding-top: 60px;
    position: absolute;
    top: 18px;
    right: -4px;
    background: url(../images/owsd_story_label_inverted.png) top right no-repeat
}

#member .info .success-story-badge a {
    color: #525252
}

#member #accordion dt {
    padding: 18px 18px 18px 10px;
    height: auto;
    font-weight: 300
}

#member #accordion dt:before {
    color: #ffc200;
    margin-right: 9px;
    font-size: 1.9em;
    vertical-align: middle
}

#member #accordion dt:after {
    font-size: .8em;
    top: 24px;
    right: 18px;
    color: #ffc200
}

#member #accordion dt.on:after {
    color: #ddd
}

#member #accordion dd {
    padding: 0 10px
}

#member #accordion .member-section {
    border-top: 1px solid #eee;
    padding: 36px 12px;
    position: relative
}

#member #accordion .member-section .label {
    color: #bababa
}

#member #accordion .member-section .member_qualification_items span {
    padding: 10px;
    display: inline-block
}

#member #accordion .member-section h4.label {
    position: absolute;
    right: 12px;
    top: 12px;
    font-style: italic;
    font-weight: 400;
    font-size: .9em
}

#member #accordion .member-section p {
    font-size: 1.1em;
    line-height: 1.5
}

#member #accordion .member-section ul {
    padding: 0
}

#member #accordion .member-section li {
    font-size: 1.1em
}

#member #accordion .member-section li:before {
    color: #ffc200
}

#member #accordion .member-section table {
    font-size: 1.1em
}

#member #accordion .member-section table td {
    padding: 4px
}

#member #accordion .member-section table td strong {
    font-size: 1.1em
}

#member #accordion .member-section .graduation {
    background: #f7f6f5;
    padding: 21px 18px
}

#member #accordion .member-section .graduation figure {
    margin: 0 24px 21px 0
}

#member #accordion .member-section .graduation .years {
    padding-top: 6px;
    margin-bottom: 21px
}

#member #accordion .member-section .graduation .years p {
    color: #b1b1b1;
    line-height: 1.2;
    padding-bottom: 12px
}

#member #accordion .member-section .graduation .years strong {
    display: block;
    font-weight: 300;
    font-size: 2em
}

#member #accordion .member-section .graduation h3.title {
    width: 200px;
    height: 36px;
    text-indent: -9999px;
    background: url(../images/member_fellowship_title.gif) no-repeat
}

#member #accordion .member-section .graduation .gallery {
    margin-left: -10px
}

#member #accordion .member-section .graduation .gallery a {
    display: block;
    float: left;
    margin: 0 0 10px 10px
}

#member #accordion .member-section .graduation .thesis h3 {
    font-size: 1.2em
}

#content #member #accordion .member-section .links,
#content #member #accordion .member-section .proceeding,
#member #accordion .member-section #content .links,
#member #accordion .member-section #content .proceeding,
#member #accordion .member-section .downloads {
    padding: 0;
    border: none
}

#content #member #accordion .member-section .links a,
#content #member #accordion .member-section .proceeding a,
#member #accordion .member-section #content .links a,
#member #accordion .member-section #content .proceeding a,
#member #accordion .member-section .downloads a {
    color: #999
}

#content #member #accordion .member-section .links li,
#content #member #accordion .member-section .proceeding li,
#member #accordion .member-section #content .links li,
#member #accordion .member-section #content .proceeding li,
#member #accordion .member-section .downloads li {
    padding-left: 24px
}

#member #accordion .member-section .field-name-field-current-activities,
#member #accordion .member-section .field-name-field-tags {
    padding-bottom: 18px
}

#member #accordion .member-section .field-name-field-current-activities .field-item,
#member #accordion .member-section .field-name-field-tags .field-item {
    display: inline-block;
    background-color: #f2f2f2;
    padding: 5px 8px;
    margin-right: 8px
}

#member #accordion .member-section .field-name-field-research-publications {
    margin-top: 10px
}

#member #accordion .member-section .field-name-field-research-publications .field-label {
    font-size: 110%;
    font-weight: 700
}

#member #accordion .member-section .field-name-field-research-publications .field-item {
    margin: 10px 0
}

#member #accordion .member-section .field-name-field-member-affiliation .field-item,
#member #accordion .member-section .field-name-field-member-national-chapter .field-item {
    position: relative;
    font-weight: 700;
    margin: 15px 0;
    padding-left: 30px
}

#member #accordion .member-section .field-name-field-member-affiliation .field-item:before,
#member #accordion .member-section .field-name-field-member-national-chapter .field-item:before {
    position: absolute;
    left: 0;
    top: 1px;
    content: 'H';
    font-family: owsd;
    font-size: 1.5em;
    font-weight: 400;
    color: #ffc200
}

#member #accordion .member-section .field-name-field-member-affiliation .field-item:before {
    content: 'e'
}

#member #accordion .member-section .field-name-field-member-grants .field-item,
#member #accordion .member-section .field-name-field-member-presentation .field-item,
#member #accordion .member-section .field-name-field-member-prizes .field-item,
#member #accordion .member-section .field-name-field-member-twas-award .field-item {
    margin: 2em 0
}

#member #accordion .member-section .field-name-field-member-grants .field-item>div,
#member #accordion .member-section .field-name-field-member-presentation .field-item>div,
#member #accordion .member-section .field-name-field-member-prizes .field-item>div,
#member #accordion .member-section .field-name-field-member-twas-award .field-item>div {
    margin: 5px
}

#member #accordion .member-section .field-name-field-member-grants .field-item .year,
#member #accordion .member-section .field-name-field-member-presentation .field-item .year,
#member #accordion .member-section .field-name-field-member-prizes .field-item .year,
#member #accordion .member-section .field-name-field-member-twas-award .field-item .year {
    color: #c2c2c2;
    font-style: italic
}

#member #accordion .member-section .field-name-field-member-grants .field-item .title,
#member #accordion .member-section .field-name-field-member-grants .field-item .title-presentatiton,
#member #accordion .member-section .field-name-field-member-presentation .field-item .title,
#member #accordion .member-section .field-name-field-member-presentation .field-item .title-presentatiton,
#member #accordion .member-section .field-name-field-member-prizes .field-item .title,
#member #accordion .member-section .field-name-field-member-prizes .field-item .title-presentatiton,
#member #accordion .member-section .field-name-field-member-twas-award .field-item .title,
#member #accordion .member-section .field-name-field-member-twas-award .field-item .title-presentatiton {
    font-weight: 700;
    font-size: 110%
}

#member #accordion .member-section .field-name-field-member-grants .field-item .title-presentatiton:before,
#member #accordion .member-section .field-name-field-member-grants .field-item .title:before,
#member #accordion .member-section .field-name-field-member-presentation .field-item .title-presentatiton:before,
#member #accordion .member-section .field-name-field-member-presentation .field-item .title:before,
#member #accordion .member-section .field-name-field-member-prizes .field-item .title-presentatiton:before,
#member #accordion .member-section .field-name-field-member-prizes .field-item .title:before,
#member #accordion .member-section .field-name-field-member-twas-award .field-item .title-presentatiton:before,
#member #accordion .member-section .field-name-field-member-twas-award .field-item .title:before {
    content: '•'
}

.create-member-from-user a {
    display: inline-block;
    background: #005d25;
    color: #fff;
    padding: 5px 8px
}

.page-node-add-member-profile-alumna #block-system-main {
    background-color: #f7f6f5;
    padding: 30px 50px
}

#content .node-member_profile_alumna-form .form-required {
    color: #EF393C
}

#content .node-member_profile_alumna-form .field-group-fieldset th {
    font-weight: 400
}

#content .node-member_profile_alumna-form .field-group-fieldset .field-type-file th {
    visibility: hidden
}

#content .node-member_profile_alumna-form .field-group-fieldset legend {
    width: 100%;
    margin-bottom: 15px
}

#content .node-member_profile_alumna-form div>fieldset.form-wrapper legend {
    font-weight: 700;
    font-size: 110%
}

#content .node-member_profile_alumna-form .field-multiple-table {
    width: 100%;
    border-collapse: collapse
}

#content .node-member_profile_alumna-form .field-multiple-table tr.even input {
    margin: 10px 0
}

#content .node-member_profile_alumna-form .field-multiple-table .field-multiple-drag {
    display: none
}

#content .node-member_profile_alumna-form .field-multiple-table .field-label {
    text-align: left
}

#content .node-member_profile_alumna-form .field-multiple-table+.description+div.clearfix,
#content .node-member_profile_alumna-form .field-multiple-table+div.clearfix {
    margin-top: 15px
}

#content .node-member_profile_alumna-form .field-multiple-table .form-item-field--email-und-0-email label span {
    display: none
}

#content .node-member_profile_alumna-form .form-wrapper {
    position: relative;
    margin-bottom: 20px
}

#content .node-member_profile_alumna-form .description a {
    color: #ffc200
}

#content .node-member_profile_alumna-form .form-managed-file .image-widget-data {
    width: 100%
}

#content .node-member_profile_alumna-form .form-managed-file .form-file {
    float: left;
    width: 75%!important;
    background-color: #fff;
    line-height: 28px;
    padding-left: 12px;
    margin-right: 10%
}

#content .node-member_profile_alumna-form .form-managed-file .form-submit {
    width: 15%;
    float: right;
    cursor: pointer
}

#content .node-member_profile_alumna-form .chosen-container-single .chosen-single,
#content .node-member_profile_alumna-form button,
#content .node-member_profile_alumna-form input,
#content .node-member_profile_alumna-form select,
#content .node-member_profile_alumna-form textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #dadada;
    transition: border-color .4s;
    outline: 0
}

#content .node-member_profile_alumna-form .chosen-container-single .chosen-single:focus,
#content .node-member_profile_alumna-form button:focus,
#content .node-member_profile_alumna-form input:focus,
#content .node-member_profile_alumna-form select:focus,
#content .node-member_profile_alumna-form textarea:focus {
    border-color: #ffc200
}

#content .node-member_profile_alumna-form .chosen-container-single .chosen-single:disabled,
#content .node-member_profile_alumna-form button:disabled,
#content .node-member_profile_alumna-form input:disabled,
#content .node-member_profile_alumna-form select:disabled,
#content .node-member_profile_alumna-form textarea:disabled {
    border: 0
}

#content .node-member_profile_alumna-form .chosen-container-single .chosen-single.error,
#content .node-member_profile_alumna-form button.error,
#content .node-member_profile_alumna-form input.error,
#content .node-member_profile_alumna-form select.error,
#content .node-member_profile_alumna-form textarea.error {
    border: 1px solid #A30000
}

#content .node-member_profile_alumna-form .chosen-container-single .chosen-single {
    line-height: 2
}

#content .node-member_profile_alumna-form .chosen-container-single.error .chosen-single {
    border: 1px solid #A30000
}

#content .node-member_profile_alumna-form .form-submit {
    width: 20%;
    line-height: 23px;
    background-color: #ffc200;
    border: #ffc200;
    cursor: pointer;
    color: #525252
}

#content .node-member_profile_alumna-form td .form-submit {
    width: 80px;
    margin: 1em 0
}

#content .node-member_profile_alumna-form label,
#content .node-member_profile_alumna-form span.fieldset-legend {
    display: inline-block;
    padding: 6px 0;
    font-size: 14px
}

#content .node-member_profile_alumna-form .form-text {
    height: 30px;
    padding: 6px
}

#content .node-member_profile_alumna-form .form-type-checkbox,
#content .node-member_profile_alumna-form .form-type-radio {
    display: inline
}

#content .node-member_profile_alumna-form .form-checkbox,
#content .node-member_profile_alumna-form .form-radio {
    display: none
}

#content .node-member_profile_alumna-form .form-checkbox+label,
#content .node-member_profile_alumna-form .form-radio+label {
    display: inline-block;
    font-weight: 200;
    cursor: pointer;
    margin-right: 30px
}

#content .node-member_profile_alumna-form .form-checkbox+label:before,
#content .node-member_profile_alumna-form .form-radio+label:before {
    display: inline-block;
    width: 18px;
    height: 16px;
    padding-top: 2px;
    vertical-align: middle;
    border: 1px solid #dadada;
    background: #fff;
    cursor: pointer;
    margin: -1px 3px 0 0;
    content: "";
    font: 1.2em twasonlineforms;
    text-transform: none;
    color: #666;
    text-align: center
}

#content .node-member_profile_alumna-form .form-checkbox.error+label:before,
#content .node-member_profile_alumna-form .form-radio.error+label:before {
    border: 1px solid #A30000
}

#content .node-member_profile_alumna-form .form-checkbox:checked+label:before,
#content .node-member_profile_alumna-form .form-radio:checked+label:before {
    line-height: 1
}

#content .node-member_profile_alumna-form .form-checkbox:checked+label:before {
    content: 'o';
    color: #ffc200
}

#content .node-member_profile_alumna-form .form-radio+label:before {
    border-radius: 50%;
    font-size: .8em;
    height: 15px;
    color: #ffc200
}

#content .node-member_profile_alumna-form .form-radio:checked+label:before {
    content: 'p'
}

#content .node-member_profile_alumna-form .tabledrag-toggle-weight-wrapper {
    display: none
}

#content .node-member_profile_alumna-form div.autocomplete-deluxe-multiple {
    background: 0 0;
    background-color: #fff;
    width: 100%;
    border: 1px solid #dadada;
    padding: 0
}

#content .node-member_profile_alumna-form div.autocomplete-deluxe-multiple input {
    border: 0
}

#content .node-member_profile_alumna-form div.autocomplete-deluxe-multiple .autocomplete-deluxe-throbber {
    display: none
}

#content .node-member_profile_alumna-form .chosen-drop li {
    padding: 7px
}

#content .node-member_profile_alumna-form .chosen-drop li:before {
    content: ''
}

#content .node-member_profile_alumna-form .chosen-container {
    width: 100%!important
}

#content .node-member_profile_alumna-form .chosen-container ul.chosen-choices,
#content .node-member_profile_alumna-form .chosen-container-single .chosen-single {
    height: 30px;
    background: 0 0;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0
}

#content .node-member_profile_alumna-form .chosen-container-active .chosen-single {
    border: 1px solid #ffc200
}

#content .node-member_profile_alumna-form .chosen-container .chosen-drop {
    border: 0
}

#content .node-member_profile_alumna-form .chosen-results {
    padding: 0
}

#content .node-member_profile_alumna-form .chosen-container .chosen-results li.highlighted {
    background: 0 0;
    background-color: #f9f9f9;
    color: #ffc200
}

#content .node-member_profile_alumna-form ul.chosen-choices {
    padding-bottom: 1px
}

#content .node-member_profile_alumna-form ul.chosen-choices li {
    padding: 0
}

#content .node-member_profile_alumna-form ul.chosen-choices li:before {
    content: ''
}

#content .node-member_profile_alumna-form ul.chosen-choices li.search-choice {
    border-radius: 3px;
    background: 0 0;
    box-shadow: none;
    padding: 0 18px 0 5px;
    margin: 5px;
    background-color: #E4E4E4
}

#content .node-member_profile_alumna-form ul.chosen-choices li.search-choice .search-choice-close {
    top: 6px
}

#content .node-member_profile_alumna-form .field-add-more-submit {
    float: right
}

.ui-autocomplete {
    border: 0
}

.ui-autocomplete a {
    color: #525252
}

.ui-autocomplete .ui-menu-item a.ui-state-hover {
    background: 0 0;
    color: #ffc200;
    border: 0;
    margin: 0;
    border-radius: 0
}

html.js .node-member_profile_alumna-form fieldset.collapsed {
    height: 75px
}

#user-pass .field-type-text,
#user-pass .form-type-password,
#user-pass .form-type-textfield,
#user-pass .form-wrapper,
#user-profile-form .field-type-text,
#user-profile-form .form-type-password,
#user-profile-form .form-type-textfield,
#user-profile-form .form-wrapper,
#user-register-form .field-type-text,
#user-register-form .form-type-password,
#user-register-form .form-type-textfield,
#user-register-form .form-wrapper {
    margin-bottom: 2em
}

#user-pass button,
#user-pass input,
#user-profile-form button,
#user-profile-form input,
#user-register-form button,
#user-register-form input {
    width: 100%;
    height: 30px;
    padding: 0 5px;
    box-sizing: border-box;
    border: 1px solid #dadada;
    transition: border-color .4s;
    outline: 0
}

#user-pass button:focus,
#user-pass input:focus,
#user-profile-form button:focus,
#user-profile-form input:focus,
#user-register-form button:focus,
#user-register-form input:focus {
    border-color: #ffc200
}

#user-pass button:disabled,
#user-pass input:disabled,
#user-profile-form button:disabled,
#user-profile-form input:disabled,
#user-register-form button:disabled,
#user-register-form input:disabled {
    border: 0
}

#user-pass button.error,
#user-pass input.error,
#user-profile-form button.error,
#user-profile-form input.error,
#user-register-form button.error,
#user-register-form input.error {
    border: 1px solid #A30000
}

#user-pass .form-submit,
#user-profile-form .form-submit,
#user-register-form .form-submit {
    width: 100%;
    line-height: 23px;
    background-color: #ffc200;
    border: #ffc200;
    cursor: pointer
}

.partners {
    background: #2d2d2d;
    text-align: center
}

.partners img {
    width: 25%;
    max-width: 100%;
    height: auto
}

@media only screen and (max-width:959px) {
    .partner figure.right {
        width: 100%;
        text-align: center;
        margin: 1em 0
    }
}

.user-form .form-item {
    margin-bottom: 9px
}

.user-form label {
    color: #ffc200;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .9em
}

.user-form .form-required {
    display: none
}

.user-form .form-text {
    width: 100%;
    height: 34px;
    box-sizing: border-box
}

.user-form .form-actions {
    margin-top: 15px
}

.user-form .form-submit {
    width: 100%;
    box-sizing: border-box;
    background: #ffc200;
    border: none;
    padding: 12px 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700
}

.user-form a:focus,
.user-form a:hover {
    color: inherit
}

.block-user {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 216px;
    display: none;
    background: #fff;
    border: 1px solid #d5d5d5;
    padding: 12px
}

.block-user h3 {
    text-align: center;
    font-weight: 400;
    font-size: 1.5em;
    margin-bottom: 9px
}

.block-user .close-overlay {
    position: absolute;
    top: 6px;
    right: 12px;
    font-size: 1.1em
}

.footer-menu {
    background: #222;
    padding: 40px 0
}

.footer-menu .block {
    width: 155px;
    padding-right: 5px;
    float: left
}

.footer-menu .block h3.block-title {
    color: #fff;
    height: 43px;
    line-height: 1.3;
    font-size: 1.1em;
    text-transform: uppercase
}

.footer-menu .block ul.menu li {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.1;
    padding-bottom: 6px
}

.footer-menu .block a {
    color: #888
}

.footer-menu .burger-menu {
    position: relative;
    top: 0
}

.footer-menu .burger-menu span {
    display: inline-block;
    text-transform: uppercase;
    font-size: .5em;
    position: absolute;
    top: 6px;
    left: 42px
}

@media only screen and (max-width:959px) {
    .footer-menu {
        display: none
    }
}

footer.main {
    background: #222;
    text-align: center;
    padding: 18px 0;
    font-size: .9em;
    color: #666;
    font-weight: 700
}

#cboxOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    z-index: 300
}

#colorbox {
    z-index: 301
}

.overlay-content #cboxLoadedContent,
.overlay-content #cboxLoadingGraphic,
.overlay-content #cboxLoadingOverlay {
    width: 700px!important;
    background: #fff;
    overflow: visible!important;
    height: auto!important
}

#cboxClose {
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    top: 10px;
    right: 40px;
    background: url(../images/colorbox_close.png) no-repeat;
    border: none;
    text-indent: -9999px;
    cursor: pointer
}

#cboxClose:hover {
    background-position: 0 -12px
}

#tabs-wrapper {
    width: 100%;
    margin: 0 auto 21px;
    font-size: 1.1em;
    text-transform: uppercase
}

#tabs-wrapper ul {
    display: block;
    height: 18px;
    border-bottom: 1px solid #d2d2d2;
    padding: 12px 33px;
    margin: 0
}

#tabs-wrapper li {
    margin: 0 15px 0 0;
    padding: 0;
    display: block;
    float: left
}

#tabs-wrapper li:before {
    display: none
}

#tabs-wrapper li.active a {
    color: #03a242
}

#tabs-wrapper a {
    background: #fff;
    color: #525252;
    border-color: #d2d2d2 #d2d2d2 transparent;
    border-width: 1px 1px 0;
    border-style: solid;
    padding: 9px;
    margin: 0
}

#tabs-wrapper a:focus,
#tabs-wrapper a:hover {
    color: #03a242
}

#block-views-front-home-institute-block-1 .views-row {
    cursor: pointer
}

#block-views-fellowship-sidebar-block,
#block-views-what-is-owsd-block {
    background: #F7F6F5;
    cursor: pointer
}

#block-views-fellowship-sidebar-block h3 a,
#block-views-what-is-owsd-block h3 a {
    font-size: 21px;
    color: #005d25;
    background: #fff;
    display: block;
    border-bottom: 2px solid #005C2B
}

#block-views-fellowship-sidebar-block .views-field-field--subtitle,
#block-views-fellowship-sidebar-block .views-field-view-node,
#block-views-what-is-owsd-block .views-field-field--subtitle,
#block-views-what-is-owsd-block .views-field-view-node {
    padding: 10px
}

#block-views-fellowship-sidebar-block .views-row:hover,
#block-views-what-is-owsd-block .views-row:hover {
    border-right-color: #005d25;
    background-color: #d2ecdc
}

#block-views-front-success-stories-block-2 h3 {
    color: #ffc200;
    padding-bottom: 4px;
    margin: 0;
    background: #fff;
    font-weight: 400
}

#block-views-front-success-stories-block-2 .view-front-success-stories .views-row {
    cursor: pointer;
    background: url(../images/ribbon_y.png) no-repeat right top;
    background-color: rgba(236, 204, 40, .22);
    padding: 10px;
    border-top: 2px solid #ffc200;
    margin-bottom: 20px;
    min-height: 100px
}

#block-views-front-success-stories-block-2 .view-front-success-stories .views-row .views-field-field-success-tagline {
    font-style: italic;
    font-weight: 300;
    font-size: 120%;
    color: #5C5C5C;
    margin-bottom: 20px
}

#block-views-front-success-stories-block-2 .view-front-success-stories .views-row .views-field-field--member-profile {
    font-size: 110%
}

#block-views-front-success-stories-block-2 .view-front-success-stories .views-row .views-field-field--field {
    font-weight: 300
}

#block-views-front-success-stories-block-2 .view-front-success-stories .views-row .views-field-view-node {
    margin: 10px 0 0
}

#block-views-front-success-stories-block-2 .view-front-success-stories .views-row .views-field-view-node a {
    color: #ffc200
}

#block-views-front-success-stories-block-2 .view-front-success-stories .views-row .views-field-field--picture img {
    margin-bottom: 15px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .75);
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .75);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .75)
}

#block-views-front-success-stories-block-2 .views-row:hover {
    background-color: rgba(236, 204, 40, .4)
}

#block-views-front-news-block-2 {
    background: #F7F6F5
}

#block-views-front-news-block-2 h3 {
    font-size: 21px;
    padding-bottom: 4px;
    margin: 0;
    color: #005d25;
    background: #fff;
    display: block;
    border-bottom: 2px solid #005C2B
}

#block-views-front-news-block-2 .view-front-news .views-row {
    border-bottom: 1px dashed #ddd;
    border-right: 8px solid #F7F6F5;
    padding: 15px 10px
}

#block-views-front-news-block-2 .view-front-news .views-row .views-field-created {
    color: #005d25;
    font-weight: 700
}

#block-views-front-news-block-2 .view-front-news .views-row .views-field-title a {
    color: #333;
    font-size: 110%;
    margin: 5px 0 10px;
    display: block
}

#block-views-front-news-block-2 .view-front-news .views-row:hover {
    border-right-color: #005d25;
    background-color: #d2ecdc
}

#block-views-front-news-block-2 .view-front-news .views-row .views-field-view-node {
    margin: 15px 0 0
}

#block-views-front-news-block-2 .view-front-news .views-row {
    cursor: pointer
}

#block-views-front-news-block-2 .view-front-news .views-row-first {
    margin: 0
}

#block-views-front-news-block-2 .view-front-news .views-row-last {
    border-bottom: 0
}

#block-views-front-conferences-block-2 .block-title {
    color: #0085b2
}

#block-views-front-conferences-block-2 .block-text {
    padding: 0
}

#block-views-front-conferences-block-2 .views-row {
    cursor: pointer;
    background: url(../images/conference-icon.png) no-repeat 95% 15px;
    background-color: #f7f6f5;
    border-top: 2px solid #0085b2;
    margin-bottom: 20px;
    padding: 10px
}

#block-views-front-conferences-block-2 .views-row .views-field {
    margin: 10px 0
}

#block-views-front-conferences-block-2 .views-row .views-field-field--date-w-end {
    font-weight: 700;
    font-size: 90%;
    margin-bottom: 0
}

#block-views-front-conferences-block-2 .views-row .views-field-field-conference-location {
    margin-top: 0
}

#block-views-front-conferences-block-2 .views-row .views-field-title {
    font-size: 120%;
    color: #0085b2;
    font-style: italic
}

#block-views-front-conferences-block-2 .views-row .views-field-view-node {
    margin: 30px 0 0
}

#block-views-front-conferences-block-2 .views-row .views-field-view-node a {
    color: #0085b2
}

#block-views-front-conferences-block-2 .views-row:hover {
    background-color: #bcdbe8
}

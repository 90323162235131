/*------------------------------------------*/
//$screen-xs:                  610px;
//$screen-sm:                  768px;
//$screen-md:                  960px;
//$screen-lg:                  1200px + $grid-gutter-width;

.front {
  header {
    .box-content-new {
      @media screen and (min-width: 1200px + $grid-gutter-width) {
        width: 1140px;
        position: relative;
        margin: 0 auto;
      }
    }
  }

  .container {
    @media screen and (min-width: 1200px + $grid-gutter-width) {
      width: 1200px;
    }
  }
}

header {
  .box-content-new {
    @media screen and (min-width: 1200px + $grid-gutter-width) {
      width: 1140px;
      position: relative;
      margin: 0 auto;
    }

    nav {
      bottom: -6px;
    }
  }
}

.container:not(.anniversary):not(.networks-wrap):not(.block-news) {
  @media screen and (min-width: 1200px + $grid-gutter-width) {
    width: 90%;
    max-width: 1600px;
  }

  @media screen and (max-width: 1200px + $grid-gutter-width - 1) {
    width: 100%;
  }

  &#content-wrapper-new {
    display: flex;
    padding-top: 30px;

    @media screen and (max-width: ($screen-sm) - 1) {
      flex-wrap: wrap;
    }

    aside {
      flex: 0 0 215px;
      max-width: 215px;
      margin-right: 40px;

      &.right-sidebar {
        flex: 0 0 215px;
        max-width: 215px;
        margin-right: 0;
        margin-left: 40px;
        margin-top: 102px;

        @media screen and (max-width: ($screen-md) - 1) {
          display: block;
          max-width: initial;
        }

        @media screen and (max-width: ($screen-sm) - 1) {
          order: 2;
          flex: 1 0 100%;
          max-width: initial;
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }

    #content {
      flex: 1 0 calc(100% - 530px);
      max-width: initial;
      width: auto;
      overflow: hidden;

      @media screen and (max-width: ($screen-md) - 1) {
        flex: 1 0 calc(100% - 265px);
        max-width: calc(100% - 265px);
      }

      @media screen and (max-width: 959px) {
        flex: 1 0 100%;
        max-width: 100%;
      }

      @media screen and (max-width: ($screen-sm) - 1) {
        order: 1;
        flex: 1 0 100%;
        width: 100%;
      }

      #breadcrumbs {
        margin-top: 0;
      }
    }
  }
}

#content-wrapper {
  #content {
    @media screen and (max-width: ($screen-sm) - 1) {
      order: 1;
      flex: 1 0 100%;
      width: 100%;
    }
  }
}

.networks-wrap {
  width: 100% !important;

  #networks-list {
    .network-info {
      .members {

        .member-short {
          figure {
            height: 0;
            padding-bottom: 100%;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

}

iframe {
  max-width: 100%;
}

.members {
  .member-short {

    figure {
      height: 0;
      padding-bottom: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

#regions-sortable {
  .member-short {
    figure {
      height: 0;
      padding-bottom: 100%;

      img {
        width: 100%;
      }
    }
  }
}

#utility-wrapper {
  @media screen and (max-width: 540px) {
    width: 100%;
    transform: translateX(0);
  }
}

.banners-wrapper {
  //margin-top: 102px;

  @media screen and (max-width: ($screen-sm) - 1) {
    margin-top: 30px;
  }

  .banner-item {
    margin-bottom: 50px;
    .banner {
      a {
        &:link,
        &:hover,
        &:visited,
        &:active {
          p {
            //color: $color-base-black;
            font-size: 18px;
            font-weight:bold;
            margin-bottom: 8px;
          }
        }
      }
    }


  }

  .view-content {
    @media screen and (max-width: ($screen-sm) - 1) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .banner-item {
        flex: 0 0 210px;
        max-width: 210px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}



/*@media screen and (max-width: ($screen-md) - 1) {
  #toolbar {
    display: none;
  }

  body {
    padding-top: 0 !important;
  }
}*/

//$screen-xs:                  610px;
//$screen-sm:                  768px;
//$screen-md:                  960px;
//$screen-lg:                  1200px + $grid-gutter-width;

.ie11 {
  .container:not(.anniversary):not(.networks-wrap):not(.block-news) {
    #content {
      flex: 1 0 50%;
      max-width: none;
      width: auto;

      @media screen and (max-width: ($screen-md) - 1) {
        flex: 1 0 calc(100% - 265px);
        max-width: none;
      }

      @media screen and (max-width: ($screen-sm) - 1) {
        order: 1;
        flex: 1 0 100%;
        width: 100%;
      }
    }
  }
}

 .flex-wrapper{
    display:flex;
    flex-wrap: wrap;

    .flex-item {
      padding:5px;
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);

      @media (max-width: 1199px) {
        padding:0;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 10px;
      }
    }

  }

//calendar
.view-national-chapters-events {

  &.view-display-id-upcoming {

    .view-content {
      //display:flex;
      //flex-wrap:wrap;

      .views-row {
        //padding: 20px;
        //flex-basis: 50%;

        .date {
          span {
            text-transform:uppercase;
            font-size: 20px;
            //font-weight: bold;
          }
        }
      }

    }

    .timeline {
      li {
        padding: 0px !important;
        &:before {
          display:none;
        }

        &>div {
          width: 100%;
          display: flex;
          margin-bottom:15px;
          margin-top:15px;

        }

        &:nth-child(odd) > div {
          justify-content: flex-end;
        }

        .timeline-image {
          display: none;
        }

        .timeline-content {
          float: none;
          .timeline-text {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
        }

        .countries {
          border-top: 1px solid $color-grey-news-bg;
          margin-top: 10px;
          a {
            font-size: 13px;
            font-style:italic;
          }
        }
      }
    }

    .simple_timeline {
      margin-top: 30px !important;


    }
  }
}

#content {
  .view-front-success-stories {
    &.view-display-id-block_3, &.view-display-id-block_4, &.view-display-id-block_5 {
      .owsd-story {
        background-color: $color-grey-news-bg;
        height: 100%;
        display: flex;

        @media (max-width: 1199px) {
          flex-wrap: wrap;
        }

        .member-image {
          flex: 0 0 50%;
          max-width: 50%;

          @media (max-width: 1677px) {
            flex: 0 0 35%;
            max-width: 35%;
          }

          @media (max-width: 1199px) {
            flex: 0 0 50%;
            max-width: 250px;
          }

          @media (max-width: 430px) {
            flex: 0 0 35%;
            max-width: 35%;
          }

          .member-image-inner {
            height: 0;
            padding-bottom: 100%;

            img {
              width: 100%;
            }
          }
        }

        .text-wrapper {
          padding: 10px;
          flex: 0 0 50%;
          max-width: 50%;

          @media (max-width: 1677px) {
            flex: 0 0 65%;
            max-width: 65%;
          }

          @media (max-width: 1199px) {
            flex: 1 0 50%;
            //max-width: 50%;
          }

          @media (max-width: 430px) {
            flex: 0 0 65%;
            max-width: 65%;
          }

          .member-info {
            h2 {
              font-size: 1rem;
            }

            p {
              line-height: initial;
              padding-bottom: 0;

              strong {
                &:after {
                  content: "";
                  display: table;
                  clear: both;
                }
              }

            }

            .date {
              font-size: 12px;
            }
          }

          .abstract {

          }

          .more-link {
            margin-top: 15px;

            a {
              display: flex;
              align-items: center;
              padding: 0 0 0 25px;
              text-transform: uppercase;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  #owsd-story {

    &:before {
      display: block;
      width: 113px;
      height: 67px;
      content: '';
      background: url(../images/owsd_story_label_inverted.png) no-repeat;
      position: absolute;
      right: 0;
      top: 58px;
      z-index: 102;

      @media (max-width: 560px) {
        background: url(../images/network_member_success_badge.png) no-repeat;
        width: 51px;
        top:10px;
      }
    }

    .member-info-color {
      background-color: $color-grey-news-bg;
      width: calc(100% - 30px);
      height: 150px;
      position: absolute;
      right: 6px;
      top: -10px;
      z-index: -1;
      box-shadow: 1px 3px 4px grey;
    }

    .member-info {
      background-color: $color-base-white;
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 130px);

      @media (max-width: 560px) {
        width: calc(100% - 10px);
      }


      @media (max-width: 1199px) {
        figure {
          flex: 0 0 150px;
          max-width: 150px;
        }
      }

      .member-data {
        flex-grow: 1;

        @media (max-width: 1199px) {
          max-width: calc(100% - 150px);
        }

        @media (max-width: 400px) {
          max-width: 100%;
        }

        .member-profile {
          @media (max-width: 400px) {
            padding-left: 0;
            padding-right: 0;
          }
        }


        header {
          padding: 30px;
          font-family: $font-family-content-condensed;
          margin-bottom: 0;

          @media (max-width: 1199px) {
            padding: 15px;
          }
          @media (max-width: 560px) {
            padding: 15px 30px 15px 15px;
          }

          @media (max-width: 400px) {
            padding-left: 0;
            padding-right: 0;
          }

          p {
            font-size: 1rem;
          }

          .member-location {
            line-height: 1;
            font-weight: 700;

            .yellow {
              a {
                color: $color-yellow;
              }
            }

            a {
              color: $color-base-black;
            }

          }

          .member-specialization {
            font-weight: 700;
            font-style: italic;
          }
        }

        .affiliation {
          padding-left: 0;
          font-size: 1.2rem;
          color: $color-base-black;
          font-family: $font-family-content-condensed;
          font-weight: 700;
        }

        .append-affiliation {
          font-size: 2.5rem;
          margin-bottom: 0;
          font-family: $font-family-content-condensed;

          @media (max-width: 1199px) {
            font-size: 1.8rem;
          }
        }
      }
    }

    .owsd-story-title {
      font-size: 2.5rem;
      margin-bottom: 30px;
      margin-top: 30px;
      font-family: "Open Sans Condensed",sans-serif;
      color: $color-green-dark;

      @media (max-width: 1199px) {
        font-size: 1.8rem;
      }
    }

    .tagline-wrapper {
      width: calc(100% - 6px);
      background-color: $color-grey-news-bg;
      padding: 15px;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      margin-top: 15px;

      blockquote {
        margin-bottom: 0;


        &:before {
          height: 0;
          float: none;
          font-size: 1rem;
          transform: translateY(-5px);
          vertical-align: unset;
          color: $color-yellow;
        }

        &:after {
          height: 0;
          float: none;
          font-size: 1rem;
          margin-left: 7px;
          transform: translateY(10px);
          vertical-align: unset;
          color: $color-yellow;
        }
      }
    }

    .text {
      width: calc(100% - 6px);

      .text-main {
        display: flex;

        @media (max-width: 1199px) {
          flex-wrap:wrap;
        }

        .program {
          //flex: 0 0 calc(100% - 350px);
          //max-width: calc(100% - 350px);
          flex: 0 0 100%;
          max-width: 100%;
          padding-right: 0;

          @media (max-width: 1199px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 0;
          }
        }

        .multimedia {
          //flex: 0 0 350px;
          max-width: 350px;
          margin-left: 15px;
          margin-bottom: 15px;
          float: right;

          @media (max-width: 1199px) {
            flex: 0 0 100%;
            max-width: 100%;
          }

          .bigimage {
            img {
              width: 100%;
            }

            margin-bottom: 15px;
          }
        }

      }

      .links {
        background-color: $color-yellow;
        border-top: none;
        margin-top: 30px;
        padding: 30px;

        h2 {
          text-transform: uppercase;
          color: $color-base-white;
          margin-bottom: 30px;
        }
        .member-link {
          ul {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-md-max) { //1200px-1

	.tid-country.container.box-content {
		#content {
			width: calc(100% - 30px);
			margin-right: 15px;
		}
	}

}

@media screen and (min-width: $screen-md-min) { //992px

body {
	//padding-top: 90px!important;
	&.toolbar {
		//padding-top: 90px!important;
		header.main {
			//padding-top: 90px!important;
		}

		#wrapper {
			//padding-top: 80px!important;
		}

	}

	&.network {
		#wrapper {
			padding-top: 0px!important;
		}
	}
}

	.toolbar header.main {
			.mega-menu, .submenu {
				//top: 170px;
			}
	}

	header {
		z-index: 410;
		&.main {
			height: 80px;
			padding-top: 10px;
			background: $color-grey-light;
			border-bottom: 0px;

			&>.box-content {
				height: 80px;
			}
			.logo img {
				height: 70px;
			}

			.first-level>li {
				height: 45px;
			}

			.mega-menu, .submenu {
				top: 90px;
			}

			.utility {
				top: 0;
				right: 100px
			}

			nav .first-level>li>a {color: $color-green-dark}
			.utility a:focus, .utility a:hover, .social a {color: $color-base-black}


		}
	}

	.map-image {
		margin-top: 60px;
	}


}

@media screen and (min-width:960px) {
header {
		z-index: 410;
		&.main {
			height: 80px;
			padding-top: 10px;
			background: $color-grey-light;
			border-bottom: 0px;

			&>.box-content {
				height: 80px;
			}
			.logo img {
				height: 70px;
			}

			.first-level>li {
				height: 45px;
			}

			.mega-menu, .submenu {
				top: 154px;
			}

			.utility {
				top: 0;
				right: 100px
			}

			nav .first-level>li>a {color: $color-green-dark}
			.utility a:focus, .utility a:hover, .social a {color: $color-base-black}


		}
  }

  #menu-donate.mobile {
    display: none;
  }
}

@media screen and (max-width: $screen-sm-max) { //992px - 1

	body {

		&.not-logged-in {
			//padding-top: 0px!important;
		}

		&.toolbar {
			//padding-top: 90px!important;
			.slideout-menu {
				//margin-top: 90px;
			}
		//	padding-top: 90px!important;

			#wrapper {
				//padding-top: 0px!important;
			}

		}

		.secondarymenu-wrapper {
			display: none;
		}


	}

	#wrapper {

		header {
			&.main {
				background: $color-grey-light;
        border-bottom: 0px;
        height: 80px;

        &.fixed {
          padding-top: 0 !important;
        }

        @media screen and (max-width: 959px) {
          height: 120px;
        }

        .box-content {
          width: calc(100% - 30px);
          height: 80px;

          @media screen and (max-width: 959px) {
            height: 120px;
          }
        }

				.logo {
					width: auto;
          left: 0;
          top: 0;

          @media screen and (max-width: 959px) {
            top:25px;
            transform: translate(80px,0);

            @media screen and (max-width: 540px) {
              top:40px;
            }
          }
					img {
            height: 70px;

            @media screen and (max-width: 540px) {
              height: 50px;
            }
					}
				}

				#burger-menu {
        color: $color-base-black;
					&:focus, &:hover {
						color: $color-base-black;
					}
				}
			}
		}


		#frontgrid {
			#frontgrid-content {
				.view-display-id-block_1 {
				    margin-top: 0px;
          margin-bottom: 0px;

          >.view-content {
            display: flex;
            flex-wrap: wrap;

            .become-member-wrapper {
              flex: 1 0 50%;

              @media screen and (max-width: 540px) {
                flex: 1 0 100%;
              }

              .link-box {
                text-align: left;

                @media screen and (max-width: 540px) {
                  text-align: center;
                }

                a.more {
                  background-color: rgba(255,255,255,.7);
                  color: $nc-green;
                  text-align: left;
                  width: auto;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
				}
				img {
          display: none;
				}

				.link-box {
					position: relative;
					width: 100%;
					display: inline-block;
					left: initial;
					bottom: initial;
					margin-bottom: 1px;
					background: none;

					padding: 10px 5px;
          margin-bottom: 5px;

          @media screen and (max-width: $screen-xs-max) {
            //border-bottom: 1px solid white;
          }
					a {
						p {
							font-size: $font-size-content;
						}
					}
				}
			}
		}

		#video-content {
			h1 {
				font-size: $font-size-heading-3;
				line-height: 3rem;
				text-align: left;
			}

		}
	}

	#burger-menu-nav {
		.mobile_logo {
			img {
				height: 106px;
			}
		}
	}

	#eb-role {
		overflow: hidden;
		figure {
			top: initial;
			left: initial;

      figcaption {
				strong {
					font-size: $font-size-content;
				}
			}
		}
		header {
      padding: 30px 0 15px 220px !important;
      margin-top: -100px;

			h1 {
				font-size: $font-size-content-plus;
				margin-bottom: 10px;
			}
		}
	}

	.mega-menu {
		.block-menu {
			width: auto;

			a {
				padding: 9px 0px;
				margin: 0 15px 0 0px;
			}

			li ul {
				display: block !important;
			}
		}
	}

	#success-story {
		.member-info {
			padding: 36px 70px 0 70px;
			margin: -30px 0 0 0;
			width: calc(100% - 140px) !important;
		}

		&:before {
			display: none;
		}

		header {
			padding-top: 0;
			p {
				br {
					display: none;
				}
			}

			.member-info h1 {
				margin-bottom: 8px;
			}
		}
	}

	.views-exposed-widgets .views-exposed-widget {
		width: calc(50% - 3px) !important;
		margin-bottom: 10px;
	}


	#networks {
		#networks-list {
			.network {
				height: 100px;
				position: relative;

				.network-info-wrapper {
					.info {
						display: none;
					}
				}
			}
		}
	}

	#country {
		header {
			width: 100% !important;
			min-height: auto;
		}

		.network-chart {
			display: none;
		}

		.network-full-info {
			position: relative;
			width: calc(100% - 50px) !important;
			height: auto !important;
			top: initial;
			margin-top: 15px;
			margin-bottom: 15px;
			padding-bottom: 0px;
			display: block;

			.stats {
				float: none;
				width: 100%;
				margin: 0;
			}

			.member {
				padding: 10px 0 5px 0;

				figure {
					width: 60px;
					height: 60px;
				}
			}

			.member-info {
				width: calc(100% - 90px);
			}
		}
	}


}

@media screen and (max-width:959px) {
	#national-chapters{
		.region {
			position: relative;

			&:after {
				top: calc(50% - 7px);
			}
			.members {
				display: none;
			}
		}
	}

	.yes-nc {
		margin-right: 35px;
  }

  #menu-donate.desktop {
    display: none;
  }

  header.main .utility {
    display:block !important;
    position: relative;
    text-align: right;
    right: -22px;

    li.first {
      display: none;
    }
  }
}

@media screen and (max-width: $screen-xs-max) { //768px - 1
	.views-exposed-widgets .views-exposed-widget {
		width: calc(50% - 3px) !important;
		margin-bottom: 10px;
	}

	#country {
		.network-full-info {
			padding-left:15px;
			padding-right:15px;
			width: 100% !important;
			box-sizing: border-box;
			margin-left:0;
		}
	}

	.network {
		.network-full-info {
			padding: 20px 2% 50px 2% !important;
			.member {
				padding: 5px 0;
			}
			.stats {
				width: 100% !important;
				position: relative;
				top: initial !important;
				right: initial !important;
				float: none;
				.item {
					float: left;
					p {
						display: inline-block;
						float: left;
						border-top: none !important;
					}
				}
			}

			blockquote {
				margin: 15px 0 0 0;

				&:before {
					height: auto;
				}
			}
		}
	}

  #eb-role {
		figure {
			margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;

      img {
        flex: 0 0 100px;
        max-width: 100px;
        margin-right: 20px;
      }

      figcaption {
				position: relative;
        left: initial;
        margin-top: 35px;
        flex: 0 0 calc(100% - 120px);
        max-width: calc(100% - 120px);
			}
		}
		header {
      padding: 30px 0 15px 120px !important;
      font-size: 13px;
      margin-top: 0 !important;

      p {
        font-size: 13px;
      }

			h1 {
				font-size: $font-size-content;
				margin-bottom: 10px;
        font-weight: bold;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.views-exposed-widgets .views-exposed-widget {
		width: 100% !important;
		margin-bottom: 10px;
  }

  .ip-geoloc-map>div {
    height: 400px !important;
  }
}

@media screen and (min-width: 960px) {
  #burger-menu-nav {
    #search-mobile {
      display:none;
    }
  }
}

@media screen and (max-width: 959px) {
  #search-mobile {
    margin-top: 15px;
    margin-left: 20px;

    .container-inline {
      display:flex;

      .form-item {
        flex-grow: 0.9;
      }
    }

    input[type="text"] {
      padding: 5px 4px 4px 4px;
      border-radius: 0;
      border: none;
      width: 99%;
    }
  }

  #utility-wrapper {
    #search {
      display:none;
    }
  }
}

@media screen and (max-width: 540px) {
  #burger-menu-nav {
    nav {
      top: 70px;
    }

    #search-mobile {
      margin-top: 15px;
      margin-left: 20px;

      input[type="text"] {
        padding: 5px 4px 4px 4px;
      }

      .form-submit {
        content: "\f002";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 15px;
        text-rendering: auto;
        border-radius: 0px;
        height: 25px;
        width: 25px;
        border: none;
        background-color: white;
        margin-left: 1px
      }

    }
  }
}

#search {
  background: $color-grey-light;
  z-index: 11;
  position: relative;
  padding: 0;
  margin: 0;

  /*&:after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 25px $color-base-white;;
    border-left: solid 50px transparent;
    border-right: solid 50px transparent;
    z-index: 10;
  }*/


  #search-content {
    #search-block-form {
      text-align: center;

      .container-inline {
        text-align: right;
      }

      input {
        padding: 8px;
        border-radius: 0px;
        border: 1px solid $color-grey-mid;

        &:focus {
          border-color: $color-green-menuhover;
          outline: 0;
          box-shadow: 0 0 0 0.1rem $color-green-menuhover;
        }

        &[type="text"] {
          min-width: 200px;
        }

        &.form-submit {
          content: "\f002";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: 15px;
          text-rendering: auto;
          border-radius: 0px;
          height: 26px;
          width: 26px;


          &:hover {
            background-color: $nc-green;
            color: $color-base-white;
          }

          &:focus {
            background-color: darken($color-grey-mid,10);
            color: $color-base-white;
            outline: 0;
            box-shadow: 0 0 0 0 $color-green-menuhover;
          }
        }
      }

    }
  }

}

.search-section-breadcrumb#breadcrumb-wrapper {
  background-color: $blog-grey-mid;
  width: 100%;
  #breadcrumbs {
        font-size: .7rem;
        margin-top: 0;
        width: 1170px;
        max-width: 100%;
        padding: 15px 0;
        margin: 0 auto;
        float: none;

  }
}

#content-wrapper {
  &.search-section {
    background-color: $blog-grey-mid;
    width: 100%;

    #content {
      margin: 0 auto;
      float: none;
      background-color: $color-base-white;
      padding: 30px;
      max-width: 100%;

      input {
        padding: 8px;
        border-radius: 0px;
        border: 1px solid $color-grey-mid;

        &:focus {
          border-color: $color-green-menuhover;
          outline: 0;
          box-shadow: 0 0 0 0.1rem $color-green-menuhover;
        }

        &[type="text"] {
          min-width: 200px;

        }
      }

      .search-advanced {
        margin-top: 15px;
        margin-bottom: 30px;

        .fieldset-title {
          font-weight: bold;
        }

        .form-type-textfield {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          align-items: center;

          label {
            flex: 0 0 200px;
          }

          input {
            flex: 0 0 200px;
          }
        }

        .form-type-checkboxes {
          &>label {
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
      }

      h2 {
        font-size: 20px;
        border-bottom: 1px solid $color-grey-mid;
        margin-bottom: 15px;
        margin-top: 30px;
      }

      li.search-result {
        padding: 0 0 10px 15px;

        &:before {
          content: 'k';
          font-family: owsd;
          font-size: .8em;
          color: #005d25;
          position: absolute;
          left: 0;
          top: 2px;
          //display: none;
        }

        .search-snippet {
          word-wrap: break-word;
        }
      }

    }
  }
}

.front, .not-front  {
  #search {
    margin-left: 3px;

    @media screen and (max-width: 540px) {
      display: none;
    }

    #search-content {
      width: auto;
      #search-block-form {
        input {
          padding: 6px 5px 5px;
          border: 0px solid #888
        }
      }
    }
  }
}

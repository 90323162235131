.node-type-event-workshop {
  .date-display-single, .date-display-range {
    color: $color-base-navbar-text;
    margin-bottom: 15px;
    font-style:italic;
    display:block;
  }

  .field-name-field-tags {
    display:none;
  }

  .field-group-div {
    font-style:italic;
    margin-bottom: 15px;
    a {
      &:after {
        content:", ";
      }

      &:last-child {
        &:after {
          content:"";
        }
      }
    }
  }
}

.not-front {
  .news-list {
    &.news-list.view-display-id-page_1, &.news-list.view-display-id-page_2 {
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;

      &:before, &:after {
        display: none;
      }

      //$screen-xs:                  610px;
//$screen-sm:                  768px;
//$screen-md:                  960px;
//$screen-lg:                  1200px + $grid-gutter-width;

      >a {
        flex: 0 0 33.333%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom:15px;

        @media screen and (max-width: 1200px) {
          flex: 0 0 50%;
        }

        @media screen and (max-width: ($screen-xs) - 1) {
          flex: 1 0 250px;
        }

        .col-md-4 {
          width:100%;
          padding-left: 0;
          padding-right: 0;
        }
      }



      .link-box {
        background: rgba(82,82,82,.7);
        left: 0;
        width:100%;
        .data-wrapper {
          .newstitle {
            font-size: 14px;
            line-height: 20px;
            display: block;
            text-align: left;
          }
        }
      }

      .view-filters {
        flex: 0 0 calc(100% - 30px);

        .views-exposed-widgets {
          display:flex;
          flex-wrap:wrap;
          align-items: center;

          .views-exposed-widget {
            flex-grow: 1 0 200px;

            &.views-submit-button {
              flex-grow: 0;
              margin-left: auto;
            }
            &.views-reset-button {
              flex-grow: 0;
              display: block;
            }

            .views-widget {
              .form-item {
                select {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .item-list {
        flex: 0 0 100%;
      }

    }

    &.news-list.view-display-id-page_1, &.news-list.view-display-id-page_2 {
        .view-content {
          flex: 0 0 100%;

          .link-box {
            width: calc(100% - 30px);
            left: 15px;
          }
        }

        .view-empty {
          max-width: calc(100% - 30px);
          margin-left: 15px;
          width: auto;
        }
    }

  }
}

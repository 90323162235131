@keyframes leaflet-gestures-fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.leaflet-container {
    &:after {
        animation: leaflet-gestures-fadein 0.8s backwards;
        color: #fff;
        font-family: "Roboto", Arial, sans-serif;
        font-size: 22px;
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 15px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 461;
        pointer-events:none;
    }
}

.leaflet-gesture-handling-touch-warning,
.leaflet-gesture-handling-scroll-warning {
    &:after {
        animation: leaflet-gestures-fadein 0.8s forwards;
    }
}

.leaflet-gesture-handling-touch-warning {
    &:after {
        content: attr(data-gesture-handling-touch-content);
    }
}

.leaflet-gesture-handling-scroll-warning {
    &:after {
        content: attr(data-gesture-handling-scroll-content);
    }
}

#forum-wrapper,.node-type-forum, .page-forum {
  width: 100%;
  max-width: 100%;



  &>#content {
    float: none;
    max-width: 1070px;
    width: auto;
    margin: 30px auto 0;
  }

  #path {
    background: $color-grey-light;
    padding: 10px 15px;
    margin-bottom: 30px;

    #breadcrumbs {
      margin-top: 0;
      text-align: unset;
      padding-bottom: unset;
      font-size: 14px;

      a {
        text-decoration: underline;
        color:$color-green-dark;
      }
    }
  }

  #tabs-wrapper {
    ul {
      padding: 12px 0px 12px 0;


      li {
        a {
          font-size: 14px;
        }

        &.active {
          a {
            //background: $color-base-white;
            //color: $color-base-white;
          }

        }
      }
    }
  }

  #forum, #forum-wrapper {

    .forum-table-name {
      display: none;
    }

    .forum-post-panel-main {
      min-height: 100px;
    }

    .form-type-item {
      margin-bottom: 8px;
    }

    .forum-table-wrap, #forum-topic-list {
      //flex: 0 0 70%;
      //max-width: 70%;
      //order: 1;

      .forum-table {
        border: none;
        width: 100%;

        td {
          border: 2px solid white;;
          //padding-top: 10px;
          //padding-bottom: 10px;
          padding: 5px 15px;
          vertical-align: middle;

          .forum-name {
            a {
              color: $color-base-black;
              font-size: 15px;
              font-weight: 600;
            }
          }

          .forum-subforums {

            .forum-subforums-label {
              font-weight: 600;
              margin-top: 10px;
              display: block;
            }
          }
        }

        tr {
          border: 3px solid $color-base-white;
          background: $white-gray;
        }
      }
      thead {
        tr {
          background-color: $color-base-white !important;
          border: none;
        }

        th {
          padding-top: 10px;
          padding-bottom: 10px;
          font-weight: 400;

          a {
            font-weight: 400;
          }

          &.views-field-last-updated {
            &.active {
              a {
                display: flex;
                flex-wrap: wrap;

                .caption {
                  width: auto;
                  display: flex;
                  flex: 0 0 100%;
                  max-width: 100%;

                  img {
                    height: 13px;
                    transform: translateY(50%);
                    margin-right: 15px;
                  }

                  p {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }

          .forum-name {
            font-weight: 300;
          }
        }
      }
      .forum-table-superheader {
        border: none;
        background: none;
        padding: 10px 0;

        .forum-table-name {
          margin: 0;
          font-size: 36px;
          line-height: 38px;
          font-weight: 500;
        }
      }

      .forum-table {
        .forum-list-icon {
          .forum-list-icon-wrapper {
            border: none;
            background: none;
            box-shadow: none;
          }
        }

        .views-field-topic-icon {
          .topic-icon {
            margin: 10px 25px 10px 10px;

          }
        }

        .views-field-title {
          a:not(.username) {
            color: #525252;
            font-size: 15px;
            font-weight: 600;
          }

          .username {
            color: #525252;
            font-weight: 600;
          }
        }
      }
    }

    tr.even td.forum-list-icon-new-posts .forum-list-icon-wrapper span, tr.odd td.forum-list-icon-new-posts .forum-list-icon-wrapper span, .forum-list-icon-legend .forum-list-icon-new-posts {
      background-image: url('/sites/all/themes/owsd/images/forum/circle-green.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px;
    }

    tr.even td.forum-list-icon-default .forum-list-icon-wrapper span, tr.odd td.forum-list-icon-default .forum-list-icon-wrapper span, .forum-list-icon-legend .forum-list-icon-default {
      background-image: url('/sites/all/themes/owsd/images/forum/circle-grey.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px;
    }

    .forum-list-icon-wrapper {
      border: none;
      background: none;
      box-shadow: none;
      width: 15px;
      height: 15px;

      span {
        width: 15px;
        height: 15px;
      }
    }

    .forum-tools {
      display: none;
    }

    .forum-list-icon-legend {
      //flex: 0 0 70%;
      //max-width: 70%;
      //order: 3;
    }

    #forum-statistics {
      //flex: 0 0 calc(30% - 30px);
     // max-width: calc(30% - 30px);
      //margin-left: 30px;
     // order: 2;
      border: none;
      margin-bottom: 17px;
      background-color: $color-base-white;
      margin-top: 100px;

      #forum-statistics-header {
        background: $white-gray;
      }
      #forum-statistics-active-header {
        border: none;
      }

      #forum-statistics-statistics-header {
        border: none;
      }

      .forum-statistics-sub-body, .forum-statistics-active-body {
        background-color: transparent;
      }
    }
  }

  .forum-add-forum .af-button-large span {
    background-image: url('/sites/all/themes/owsd/images/forum/plus.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 10px;
  }

  .af-button-large, .af-button-small, .author-pane-link {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
  }

  .view-advanced-forum-topic-list .view-empty {
    border: 1px solid #C1C1C1;
    p {
      padding-bottom: 0;
    }
  }

  .forum-node-create-links:not(.forum-node-create-links-top) {
    display: none;
  }

  #forum-sort {
    float: none;

    input, select {
      padding: 4px 8px !important;
      border-radius: 4px;
      border: 1px solid #888;

    }
  }

  .forum-topic-legend {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #C1C1C1;
    display: flex;
    flex-wrap: wrap;

    &>div {
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 20px;
      &.topic-icon-new {background-image: url('/sites/all/themes/owsd/images/forum/comment-dots.svg');}
      &.topic-icon-default {background-image: url('/sites/all/themes/owsd/images/forum/comment-dots-default.svg');}
      &.topic-icon-hot-new {background-image: url('/sites/all/themes/owsd/images/forum/comment.svg');}
      &.topic-icon-hot {background-image: url('/sites/all/themes/owsd/images/forum/comment-grey.svg');}
      &.topic-icon-sticky {background-image: url('/sites/all/themes/owsd/images/forum/pin.svg');background-size: 11px;background-position: left 9px center;}
      &.topic-icon-closed {background-image: url('/sites/all/themes/owsd/images/forum/lock.svg');background-size: 11px;background-position: left 9px center;}
    }
  }

  span:not(.forum-collapsible) {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 20px;

    &.topic-icon-new {background-image: url('/sites/all/themes/owsd/images/forum/comment-dots.svg');}
    &.topic-icon-default {background-image: url('/sites/all/themes/owsd/images/forum/comment-dots-default.svg');}
    &.topic-icon-hot-new {background-image: url('/sites/all/themes/owsd/images/forum/comment.svg');}
    &.topic-icon-hot {background-image: url('/sites/all/themes/owsd/images/forum/comment-grey.svg');}
    &.topic-icon-sticky {background-image: url('/sites/all/themes/owsd/images/forum/pin.svg');background-size: 11px;background-position: left 9px center;}
    &.topic-icon-closed {background-image: url('/sites/all/themes/owsd/images/forum/lock.svg');background-size: 11px;background-position: left 9px center;}
  }

  #content .links li:before {
    display: none;
  }

  #content li {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    &:before {
      display: none;
    }
  }

  .comment-reply span,.topic-reply-allowed span {background-image: url('/sites/all/themes/owsd/images/forum/reply.svg');background-size: 14px;}
  .last-post-link span {background-image: url('/sites/all/themes/owsd/images/forum/angle-double-right.svg');}
  .topic-new-post-link span {background-image: url('/sites/all/themes/owsd/images/forum/angle-right.svg');background-size: 10px;}
  .forum-jump-links span {background-image: url('/sites/all/themes/owsd/images/forum/angle-up.svg');background-size: 14px;}

  //.comment-delete span, .links .comment-delete a {background-image: url('/sites/all/themes/owsd/images/forum/times.svg');background-size: 14px;}
  .comment-delete span {background-image: none;background-size: 14px;}
  .comment-add span {background-image: url('/sites/all/themes/owsd/images/forum/plus.svg');background-size: 14px;}
  .comment-edit span {background-image: url('/sites/all/themes/owsd/images/forum/pen.svg');background-size: 14px;}

  .comment-edit .af-button-small span, .post-edit .af-button-small span {background-image: url('/sites/all/themes/owsd/images/forum/pen.svg');background-size: 14px;}
  .comment-delete .af-button-small span, .post-delete .af-button-small span {background-image: url('/sites/all/themes/owsd/images/forum/times.svg');background-size: 14px;}

  h2.comment-form {
    margin-top: 30px;
  }

  .forum-post-links {
    a {
      font-size: 12px;
      padding: 2px 2px 2px 20px;
      line-height: 25px;
      margin: 0 7px 0 7px;
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 20px;
      display: inline-block;
    }
  }

  #forum-comments {
    .forum-post {
      margin-left: 30px;
    }

  }
}

#forum-comments, .page-node-add-forum, .page-node-edit.node-type-forum, .page-comment-delete, .page-comment-edit, .page-comment-reply {
  input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #888;
    max-width: 100%;
  }

  .title.comment-form {
    margin-bottom: 10px;
  }

  .form-item-subject, .form-item-title {
    margin-top: 10px;
    margin-bottom: 10px;
    label {
      margin-right: 15px;
    }
  }

  .form-textarea-wrapper {
    margin-bottom: 10px;
    textarea {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #888;
    }
  }

  .form-type-select {
    select {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #888;
    }
    label {
      margin-right: 15px;
    }
  }

  #comment-confirm-delete div {
    margin-top: 8px;
  }

  .form-submit {
    margin-right: 8px;
  }

  .forum-post-panel-main {
    min-height: 100px;
  }

}



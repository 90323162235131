#content-wrapper {
  &.blog-section {
    background-color: $blog-grey-mid;
    width: 100%;

    .blog-header {
      margin-left: -30px;
      margin-top: -30px;
      width: calc(100% + 60px);
      max-width: calc(100% + 60px);
      margin-bottom: 100px;
    }

    #content {
      margin: 0 auto;
      float: none;
      background-color: $color-base-white;
      padding: 30px;
      max-width: 100%;

      .node .date-display-single {
        padding-top: 9px;
        letter-spacing: -.03em;
        margin-bottom: 50px;
        font-weight: 300;
        color: $color-green-dark;
        font-size: 1.7em;
        position: relative;
        display: block;

        &:before {
          height: 4px;
          background: $color-blue-sign;
          display: block;
          width: 100px;
          position: absolute;
          left: 0;
          top: 0;
          content: '';
        }
      }

      .links:not(.inline) {
        border: none;
      }

      .group-gallerywrapper {
        margin-top: 30px;
        h3 {
          display: none;
        }
        .field-group-format-wrapper {
          display: flex !important;
          flex-wrap: wrap;

          .colorbox  {
            flex: 0 0 25%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-width: 100%;
              border: 15px solid white;
              max-height: 300px;
              width: auto;
            }
          }
        }
      }
    }

    #comments {
      padding: 30px;

      .comment  {
        margin-top: 15px;

        .submitted {
          >span {
            display: block;
            color: $color-grey-grid;
            margin-bottom: 10px;
          }
        }

        .content {
          margin-bottom: 5px;
        }

        .links.inline {
          .comment_forbidden {
            display:none;
          }
          li {
            display: inline;
            margin-right: 20px;
          }
        }
      }

    }
  }

  .blog-wrapper {
    .view-content {
      display: flex;
      flex-wrap: wrap;


      .blog-item {
        flex: 1 0 calc(33.3333% - 30px);
        max-width: calc(33.3333% - 30px);
        margin: 15px;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 991px) {
          flex: 1 0 calc(50% - 30px);
          max-width: calc(50% - 30px);
        }

        @media screen and (max-width: 650px) {
          flex: 1 0 calc(100% - 30px);
          max-width: calc(100% - 30px);
        }

        .background-image-blog {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 0;
          padding-bottom: 75%;
        }

        .blog-link-wrapper, .blog-link-wrapper span, .blog-link-wrapper span a {
          position: absolute;
          top:0;
          left:0;
          right: 0;
          height: 0;
          padding-bottom: 75%;
          color: transparent;
          display: block;
        }

        .blog-data-wrapper {
          padding: 15px 15px;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          text-align: center;
          background: rgba(82,82,82,.6);
          transition: all .2s ease-in-out;

          &:hover {
            text-decoration: none;
            transform: scale(1.05);
          }

          p {
            color: $color-base-white;
            padding-bottom: 0;
            &.date {
              font-size: 85%;
            }

            &.title {
              &:after {
                content: "\f054";
                font-family: FontAwesome;
                margin-left: 15px;
                font-weight: 300;
                color: #fff;
              }
            }

            a{
              &:link,
              &:hover,
              &:visited,
              &:active {
                color: $color-base-white;
                text-decoration: none;
              }
            }
          }
        }
      }



    }
  }
}

#breadcrumb-wrapper {
  background-color: $blog-grey-mid;
  width: 100%;
  #breadcrumbs {
        font-size: .7rem;
        margin-top: 0;
        width: 1170px;
        max-width: 100%;
        padding: 15px 0;
        margin: 0 auto;
        float: none;

  }
}

.page-comment {
  #content-wrapper {
    &.blog-section {
      #content {
        .comment {
          margin-top: 50px;

          .submitted {
            >a {
              display: block;
              color: $color-grey-grid;
              margin-bottom: 10px;
            }
          }

          .content {
            margin-bottom: 5px;
          }
        }
      }


    }
  }
}

.page-comment, .node-type-blog {
  .comment-form {

    &.title {
      margin-top: 50px;
    }

    .form-item {
      margin-bottom: 15px;

      input {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid $color-grey-mid;
        max-width: 100%;

        &:focus {
          border-color: $color-green-menuhover;
          outline: 0;
          box-shadow: 0 0 0 0.1rem $color-green-menuhover;
        }
      }

      textarea {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid $color-grey-mid;

        &:focus {
          border-color: $color-green-menuhover;
          outline: 0;
          box-shadow: 0 0 0 0.1rem $color-green-menuhover;
        }
      }

      label {
        margin-right: 15px;
      }
    }

  }

  fieldset.collapsed {
    height: 2.5em !important;
  }
}

.cboxPhoto {
  max-height: 100%;
}

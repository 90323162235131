@import './_colors.scss';
@import './_fonts.scss';
@import "./_bootstrap_vars.scss";
@import './bootstrap/_bootstrap.scss';
@import './_owsd.scss';
@import './_flex.scss';
@import './_flex_display.scss';
@import './_national_chapter.scss';
@import './_blog.scss';
@import './_search.scss';
@import './_forum.scss';
@import './_calendar.scss';
@import './_event_workshop.scss';
@import './_handheld.scss';
@import './_leaflet-gesture-handling.scss';
@import './sections/_news_list_content_page.scss';
@import './_owsd_new_content.scss';
@import './_owsd_stories.scss';

input[type="checkbox"],
input[type="radio"] {
  box-sizing: content-box;
  margin: 3px 3px 3px 4px;
}

.page-taxonomy {
  &.nc-editor {
    #tabs-wrapper ul li a {
      display: none;
    }
  }
}

.front {
  .container {
    max-width: 1200px !important;
  }
}

.icon-fellow:before {
  content: "u";
}

.icon-alumnae:before {
  content: "z";
}

.yes-nc {
  /*content: "\f0ac";
	font-family: 'FontAwesome';*/
  font-size: 1.2rem;
  color: $color-green-dark;
  float: right;
}

.no-nc {
  display: none;
}

#wrapper {
  box-shadow: 0 0 0;
}

img[typeof="foaf:Image"] {
  display: block;
  max-width: 100%;
  height: auto;
}

#cboxLoadedContent {
  background: transparent;
}

#cboxContent {
  background: transparent;
}

// Clears child floats
.group:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

sub,
sup {
  font-size: 75%;
}

sup {
  top: -7px;
}

.page-user {
  #content {
    min-height: 500px;
  }
}

#cboxPrevious {
  margin: 0;
  right: 25px;
}

#cboxNext {
  right: 2px;
}

#cboxClose {
  right: 10px;
}

@keyframes blinker {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.7;
  }
}

.blink_me {
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
  animation-name: blinker;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

#breadcrumbs {
  margin-top: -7px;
}

#content-wrapper {
  padding: 45px 0px;
}

.container.box-content,
.secondarymenu-wrapper .block-menu-block {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {

  .container.box-content,
  .secondarymenu-wrapper .block-menu-block {
    width: 750px;
  }
}

@media (min-width: 960px) {

  .container.box-content,
  .secondarymenu-wrapper .block-menu-block {
    width: 960px;

    aside {
      margin-right: 30px;
    }

    #content {
      width: calc(100% - 245px);
    }

    &.tid-country #content {
      width: 100%;
    }
  }

  #block-views-content-members-list-block-1 .view-display-id-block_1 .views-exposed-widgets .views-exposed-widget {
    width: calc(50% - 3px) !important;
    margin-bottom: 10px;
  }

}

@media (min-width: 1200px) {

  .container.box-content,
  .secondarymenu-wrapper .block-menu-block {
    width: 1170px;
  }
}

@media (min-width: 1678px) {

  .container.box-content,
  .secondarymenu-wrapper .block-menu-block {
    width: 1170px;

    #content {
      width: 1170px;
    }

    aside {
      margin-left: -245px;
    }
  }

  #block-views-content-members-list-block-1 .view-display-id-block_1 .views-exposed-widgets .views-exposed-widget {
    width: calc(20% - 3px) !important;
    margin-bottom: 0px !important;
  }
}

a.tabledrag-handle .handle {
  box-sizing: content-box;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 409;
}

#country {
  header {
    width: calc(100% - 455px);
  }

  .network-chart {
    left: -170px;
    top: 30px;
    margin-left: 0;
  }

  .network-full-info {
    .stats {
      .item {
        display: inline-block;
        font-size: 1rem;
        border-bottom: 3px solid $color-base-white;
        margin-bottom: 10px;
        width: auto;
        margin-left: 0;
        margin-right: 6px;

        p.membersItem {
          padding-bottom: 5px;
          text-align: right;
          margin-left: 0px;
          margin-right: 0px;
          border-bottom: none;
          border-top: none;

          strong {
            font-size: $font-size-content-minus;
            color: $color-base-white;
          }
        }
      }

    }
  }
}

#networks #networks-list {
  margin: 0 auto;

  .network {
    margin: 0 auto 0 -15px;
    width: calc(100% + 30px)
  }
}

.page .view-front-news-events-focus .views-exposed-form #edit-region-wrapper {
  position: relative;
  right: initial;
  left: initial;
  top: initial;
  width: auto;
}



.become-member {
  a {
    transition: all .2s ease-in-out;
    display: inline-block;
    box-sizing: content-box;

    &:link,
    &:hover,
    &:visited,
    &:active {
      color: $color-base-white;
      text-decoration: none;
      font-size: $font-size-content-plus;
    }

    &:hover {
      text-decoration: none;
      transform: scale(1.05);
    }
  }
}

.leaflet-tile-container img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) brightness(50%);
  -webkit-filter: grayscale(100%) brightness(50%);
}

.leaflet-popup-content-wrapper {
  border-radius: 0px;
  padding: 8px 30px;

  .leaflet-popup-content {
    a {
      color: #005d25;
      font-size: $font-size-content-plus;
      margin-bottom: 5px;

      &.email {
        font-size: $font-size-content;
      }
    }

    .membersItem {
      font-weight: bold;
    }
  }
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
  border-radius: 0;
}

#content {
  .contextual-links {
    padding-bottom: 6px;

    li {
      padding: 0;

      &:before {
        content: "";
      }

      a {
        padding: 0 1em 0 0.5em;
      }
    }
  }

  .item-list {
    margin-top: 15px;
    border-top: 1px solid $color-grey-light;

    .pager {
      li {
        background: transparent;

        &>a {
          border-radius: 0;
        }

        &.pager-current {
          background-color: $color-grey-mid;
        }
      }

    }
  }


  .view-content:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

.page {
  blockquote {
    color: $color-base-black;
    font-size: $font-size-html;
  }
}

.view-filters {
  background: none;
  padding: 0 0 0 0;
  margin-bottom: 5px;
  width: 100% !important;

  .views-exposed-form {
    .views-exposed-widgets {
      .views-exposed-widget:not(.views-submit-button):not(.views-reset-button) {
        padding: 0px;
        display: inline-block;
        float: none;

        .views-widget {
          float: none;

          .form-item {
            float: none;

            .form-select {
              background: $color-base-white;
              border: 1px solid $color-grey-light;
              padding: 12px 6px;

              &:focus {
                border: 1px solid #efeeeb;
                border-radius: 0;
                outline: 1px solid #efeeeb;
              }

              &.chosen-container {
                padding: 6px 6px;

                .chosen-single {
                  border: none;
                }
              }

              option[value="fellowshipmsc"] {
                display: none;
              }
            }

            .form-text {
              display: block;
              width: 100%;
              height: 43px;
              padding: 12px 6px;
              font-size: 14px;
              line-height: 1.42857143;
              color: #555;
              background-color: #fff;
              background-image: none;
              border: 1px solid $color-grey-light;
              //box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
              transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

              &:focus {
                border-radius: 0;
                border: 1px solid #555;
                outline: none;
              }
            }

            .form-checkboxes {
              padding: 12px 6px;
            }

            label.option {
              font-weight: normal;
            }

            &.form-item-field-member-prize-value {
              .bef-select-as-checkboxes {
                input {
                  width: 14px !important;
                }

                label {
                  width: calc(100% - 21px) !important;
                  display: inline !important;
                }
              }
            }
          }


        }
      }

      #edit-field-member-prize-value-wrapper {
        padding-top: 2px !important;
        padding-bottom: 1px !important;
        transform: translateY(11px);
      }

      .views-exposed-widget {
        float: none;
        padding: 0px;

        &#edit-field-member-prize-value-wrapper {
          border: 0px solid #efeeeb;
          padding: 2px 6px 6px 6px;
          font-size: $font-size-content-minus;

          label {
            font-size: $font-size-content-minus;
            display: inline-block;
            float: none;
            margin-left: 0;
          }
        }
      }

      .views-submit-button {
        float: right;
        //display: inline-block;
        width: auto !important;

        .form-submit:not(#edit-submit-content-nc-members) {
          border: 1px solid $color-base-black !important;
          background: $color-grey-mid !important;
          color: $color-base-white !important;
          padding: 5px 15px;
          margin-top: 2px;
          margin-right: 2px;
          line-height: normal !important;

          &:hover {
            background: darken($color-grey-mid, 10%);
          }

          &#edit-submit-content-nc-members--2,
          &#edit-submit-content-nc-members--3 {
            height: 46px;
            width: 46px;
            margin-top: 0;
            margin-right: 0;
            border: none !important;
            background-image: url(/sites/all/themes/owsd/images/search2-w.png) !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-color: $nc-green !important;
            background-size: 25px auto !important;
            color: transparent;
          }
        }
      }

      .views-reset-button {
        float: right;
        //display: inline-block;
        width: auto !important;
        display: none;

        .form-submit:not(#nc-reset) {
          border: 1px solid $color-grey-mid !important;
          background: $color-grey-light !important;
          padding: 5px 15px;
          margin-top: 2px;
          margin-right: 2px;
          line-height: normal !important;

          &:hover {
            background: darken($color-grey-light, 10%);
          }
        }
      }
    }
  }


}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.ajax-progress {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .4);
  z-index: 300;

  .throbber {
    background: transparent url('/sites/all/themes/owsd/images/waiting.png') no-repeat 0px center;
    animation: spin 3000ms infinite linear;
    float: none;
    height: 32px;
    margin: 0px;
    width: 32px;
    position: fixed;
    left: 50%;
    top: 50%;
  }
}

.page-node-2407 {
  .views-exposed-widget:not(.views-submit-button):not(.views-reset-button) {
    width: calc(25% - 3px);
  }

  .form-select {
    width: 100% !important;
  }
}

.absolute-messages-message {
  z-index: 5;
}

.draggable .tabledrag-handle {
  height: 15px;
}

.front {
  header {
    @media screen and (min-width: 960px) {
      &.main {
        .box-content-new {
          width: 1140px;
        }
      }
    }
  }
}



header {

  &.main {
    box-sizing: content-box;
    z-index: 410;
    position: relative;

    &.fixed {
      position: fixed;
    }
  }

  .mega-menu {
    z-index: 1;

    .cta {
      box-sizing: content-box;

      &:before {
        font-family: owsd;
        margin-left: -90px;
        font-size: 60px;
        float: left;
        margin-top: 5px;
      }
    }

    .about-cta {
      background-image: none;

      &:before {
        font-family: twasonlineforms;
        content: 'g';
        font-size: 70px;
        margin-top: 0px;
      }
    }

    .membership-cta {
      &:before {
        font-family: twasonlineforms;
        content: 'v';
        font-size: 70px;
        margin-top: 0px;
      }
    }

    .awards-cta {
      &:before {
        content: 'A';
      }
    }

    .career-cta {
      background-image: none;

      &:before {
        content: "G";
      }
    }

    .resources-cta {
      background-image: none;

      &:before {
        font-family: twasonlineforms;
        content: 'd';
        font-size: 70px;
        margin-top: 0px;
      }
    }

    .news-cta {
      background-image: none;

      &:before {
        content: "f";
      }
    }
  }

  .block-menu .menu li .menu li a {
    min-width: 222px;
    font-size: $font-size-content-minus;
  }

  @media screen and (max-width: 959px) {

    &.main {

      &>.box-content-new {
        display: flex;
        flex-wrap: wrap;
        max-width: 1600px;
        width: 100%;


        .logo {
          flex: 0 0 215px;
          max-width: 215px;
          margin-right: 40px;
          order:2;
          position: relative;
          top:0 !important;
          left: 50px !important;
          transform: translate(0,0) !important;

          img {
            height: auto !important;
            width: 215px;
            margin-left: 15px
          }
        }

        #main-navigation-wrapper {
          flex: 0 0 100%;
          max-width: 100%;
          order:1;
          display:flex;
          flex-wrap:wrap;
          justify-content: flex-end;
          margin-right: 10px;

          #utility-wrapper {
            flex: 0 0 100%;
            max-width: 100%;
            position: relative;
            order:1;
            //margin-top: 15px;
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-end;
            background-color: #fff;;

            .utility {
              order: 2;
              .item-list > ul li {
                padding: 0;

                a {
                  text-transform: uppercase;
                  padding: 5px 10px;
                  &:hover {
                    color: #333 ;
                  }
                }
              }
            }

            #search {
              order: 1;
              margin-left: 18px;
              margin-top: 5px;
            }



            .open-menu {
              background: transparent;
              color: #888;


              &:hover {
                color: #333 ;
              }
            }
          }
        }


      }

    }

  }

  @media screen and (min-width: 960px) {
    &.main {

      &>.box-content-new {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1600px;
        width: 100%;


        .logo {
          flex: 0 0 215px;
          max-width: 215px;
          margin-right: 40px;
          order:1;

          img {
            height: auto !important;
            width: 215px;
            margin-left: 15px
          }
        }

        #main-navigation-wrapper {
          flex: 0 0 calc(100% - 245px);
          max-width: 680px;
          order:2;
          display:flex;
          flex-wrap:wrap;
          justify-content: flex-end;
          margin-right: 10px;

          #main-navigation {
            flex: 0 0 100%;
            max-width: 100%;
            position: relative;
            order:2;
            bottom: -8px;

            ul.first-level {
              display:flex;
              justify-content: space-between;
              //transform: translateX(-10px);
              li {
                padding: 0 !important;

                #menu-login, #menu-donate {
                  font-size: 11px;
                  padding: 2px 6px;
                }
              }
            }
          }

          #utility-wrapper {
            flex: 0 0 100%;
            max-width: 100%;
            position: relative;
            order:1;
          }
        }


      }

    }
  }
}

#slideshow {
  .slide {
    box-sizing: content-box;

    .info {
      box-sizing: content-box;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    box-sizing: content-box;
  }


}


#career-slider-content article {
  box-sizing: content-box;
}

#focus,
.view-front-news-events-focus.view-display-id-page_1,
.view-front-news-events-focus.view-display-id-page_2 {
  height: auto;
  position: relative;
  margin-bottom: 60px;
  margin-top: 30px;
  padding-bottom: 30px;
  padding-top: 60px;
  overflow: visible;

  &:after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 25px $color-grey-light;
    border-left: solid 50px transparent;
    border-right: solid 50px transparent;
    z-index: 10;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 25px $color-base-white;
    border-left: solid 50px transparent;
    border-right: solid 50px transparent;
    z-index: 10;
  }

  &.regions {
    margin-top: 45px;

    .box-content {
      width: 100%;
      margin-top: -25px;

      .sn-wrapper {
        padding-left: 15px;
        padding-right: 15px;

        .lead-image {
          height: 250px;
        }
      }

      .block-title {
        width: 100%;
        margin: 0 auto 25px;
        text-align: center;
      }
    }
  }

  a.all {
    top: -25px;
    right: 15px;
  }

  .lead-image {
    height: 250px;
    background-size: cover;
    background-position: center center;

    .fa {
      position: absolute;
      left: 10px;
    }

    &:hover {
      .data-wrapper {
        text-decoration: none;
        transform: scale(1.1);
      }
    }
  }

  .link-box {
    padding: 15px 15px;
    position: absolute;
    bottom: 0px;
    left: 15px;
    width: calc(100% - 30px);
    text-align: center;
    background: rgba(82, 82, 82, .6);

    .data-wrapper {
      font-weight: 400;
      transition: all .2s ease-in-out;
      display: block;
      color: $color-base-white;
      text-decoration: none;

      p {
        padding-bottom: 0 !important;
        font-size: $font-size-content-minus1;
      }

      &:hover {
        text-decoration: none;
        transform: scale(1.1);
      }

      .newstitle:after {
        content: "\f054";
        /* this is your text. You can also use UTF-8 character codes as I do here */
        font-family: FontAwesome;
        margin-left: 15px;
        font-weight: 300;
        color: $color-base-white;
      }
    }

    small {
      font-size: 85%;
    }
  }



  .content-page {
    .lead-image {
      height: 200px;
    }
  }
}

#tabs-wrapper {
  ul {
    box-sizing: content-box;
  }
}

.highcharts-container {

  svg {
    box-sizing: content-box;
  }

  .highcharts-tooltip {
    box-sizing: content-box;

    .tooltip {
      opacity: 1;
      box-sizing: content-box;
    }

    span {
      box-sizing: content-box;
      height: 50px;
    }
  }
}



#national-chapters {
  .region {
    box-sizing: content-box;

    .chart {
      box-sizing: content-box;
    }

    div.text {
      box-sizing: content-box;
    }

    .members {
      box-sizing: content-box;
    }
  }

}

.footer-menu {
  .block {
    box-sizing: content-box;
  }
}

#frontgrid {
  background: $color-green-dark;
  //margin-top: 20px;
  margin-bottom: 60px;
  z-index: 10;
  position: relative;
  //padding-bottom: 20px;
  //padding-top: 20px;

  &:after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 50%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 25px $color-green-dark;
    border-left: solid 50px transparent;
    border-right: solid 50px transparent;
    z-index: 10;
  }

  #frontgrid-content {
    .become-member-wrapper {
      overflow: hidden;

      .views-field-title {
        display: none;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      &:hover {
        p {
          transition: all .2s ease-in-out;
          transform: scale(1.1);
        }
      }
    }



    .link-box {
      padding: 15px 5px;
      position: absolute;
      bottom: 0px;
      left: 15px;
      width: calc(100% - 30px);
      text-align: center;
      background: rgba(82, 82, 82, .5);
      color: $color-base-white;
      text-decoration: none;
      font-weight: 400;

      p {

        font-size: $font-size-content-plus;

        &:hover {
          transform: scale(1.1);
        }

        &:after {
          content: "\f054";
          /* this is your text. You can also use UTF-8 character codes as I do here */
          font-family: FontAwesome;
          margin-left: 15px;
          font-weight: 300;
          color: $color-base-white;
        }
      }
    }
  }
}

#video {
  margin-top: 30px;
  margin-bottom: 30px;

  h1 {
    font-weight: 300;
    font-size: 3rem;
    color: $color-base-black;
    text-transform: uppercase;
    line-height: 4rem;
    text-align: left;

    span {
      color: $color-red;
      font-weight: 500;
    }
  }
}

#owl {
  z-index: 1;

  #owl-content {
    .lead-image {
      height: 350px;
      background-size: cover;
      background-position: center 40%;
    }
  }
}

#map {

  #map-content {
    overflow: hidden;
  }

  .view-header {
    h1 {
      font-size: $font-size-heading-3;
      margin-bottom: 15px;
    }

    h3 {
      font-size: $font-size-content-plus;
      margin-bottom: 15px;
    }
  }


  .mapNavs {
    li {
      padding: 0 20px;

      a {
        border-bottom: 3px solid $color-base-navbar-hover;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        cursor: pointer;
        background: $color-grey-light;
        border-radius: 0;
        color: $color-base-black;
        -webkit-transform-origin: 50% 100%;
        -moz-transform-origin: 50% 100%;
        -o-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
        transform-origin: 50% 100%;

        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        display: block;
        padding: 3px 15px;

        &:hover,
        &:focus {
          background: darken($color-grey-light, 5%);
          border-bottom: 3px solid darken($color-yellow, 5%);
          color: $color-base-black;

        }

        &:hover {
          transform: scale(1.1);
        }

        h5 {
          font-weight: 400;
          font-size: $font-size-content-minus1;
          white-space: nowrap;

        }

        .count, .count2 {
          font-size: $font-size-content-plus;
          font-weight: 800;
          margin: 5px auto 0px;
          width: 60px;
        }
      }

      &.active {
        a {
          border-bottom: 3px solid $color-yellow;
          background: darken($color-grey-light, 5%);
        }
      }
    }
  }


}

#colorbox {
  text-align: center;

  video {
    width: 90%;
    height: auto;
    max-height: 80vh;
  }
}

.anniversary {

  &#wrapper {
    box-shadow: 0 0 0px #1a1a1a;
  }

  header.main {
    height: 0px;
    z-index: 10;
  }

  #content {
    width: 100%;

    .item-wrapper {
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      cursor: pointer;


      .colorbox-node {
        transition: all 300ms ease-in-out;

        .img-responsive {
          filter: saturate(0%);
        }

        &:hover {
          .img-responsive {

            filter: saturate(100%);
            -moz-transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -o-transform: scale(1.2);
            transform: scale(1.2);

            transition: all 1s;
          }
        }



        .fa {
          position: absolute;
          top: 50%;
          left: 50%;
          color: $color-base-white;
          font-size: 30px;
          margin-left: -15px;
          margin-top: -15px;
          opacity: .7;
        }
      }


    }
  }

  #anniversary-logo {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -80%);
    z-index: 210;
    max-width: 260px;
    display: none;
  }
}

article.news {
  width: 100% !important;

  img {
    max-width: 100%;
    height: auto;
  }

}

.view-about-history {
  .views-row {
    border-bottom: 1px solid $color-green-dark;
    margin-bottom: 30px;

    &:last-child {
      border: none;
    }

    .links ul,
    .downloads ul {
      padding-bottom: 0 !important;
    }

    .downloads {
      h3 {
        font-size: $font-size-content-plus;
        padding-bottom: 10px;
      }
    }
  }
}

.view-display-id-partner_host_trieste {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $color-grey-light;
}

//networks
.network {
  .network-full-info {
    box-sizing: content-box;
    background-repeat: repeat-y;
    padding-bottom: 20px;

    .stats {
      top: 250px;
      right: 5px;
      text-align: right;
      width: 80%;
      float: right;

      &.regionstats {
        top: 300px;

        .item {
          display: none;

          &:first-child,
          &:last-child {
            display: block;
          }
        }
      }

      &.countrystats {
        .item {
          display: none !important;

          &:first-child {
            display: inline-block !important;
          }
        }
      }

      .item {
        p {
          color: $color-green-text;
          border-bottom: 1px solid #aaa;
          padding-bottom: 3px;
          margin-left: 15px;
          margin-right: 15px;
          font-size: $font-size-content-minus;
          padding-top: 2px;
          font-weight: 400;
          border-top: none;
        }

        &:first-child {
          p {
            border-top: 1px solid #aaa;
          }
        }

        &.countries {
          p {
            color: $color-base-white;
          }
        }
      }
    }
  }
}

#block-views-content-members-list-block-1,
.all-members-wrapper {
  box-sizing: content-box;

  .block-title {
    text-align: center;
    color: $color-green-dark;
    margin-bottom: 30px;
    margin-top: 50px;
  }


}

#block-views-front-success-stories-block-2 {
  .view-front-success-stories {
    .views-row {
      .views-field-field--picture {
        img {
          box-shadow: none;
        }
      }
    }
  }
}

.view--content-members-list.view-display-id-block_1,
.view--content-members-list.view-display-id-block_2,
.view--content-members-list.view-display-id-block_3,
.view--content-members-list.view-display-id-block_4,
.view--content-members-list.view-display-id-block_5,
.view--content-members-list.view-display-id-block_6,
.view--content-members-list.view-display-id-block_7 {
  margin-left: 0;
  margin-right: 0;

  .view-header {
    text-align: center;
    font-size: $font-size-content;

    h4 {
      margin-top: -20px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: .9rem;
    }

    h5 {
      margin-top: -15px;
      margin-bottom: -10px;
      font-weight: 600;
      font-size: $font-size-content-minus;
    }
  }

  .view-filters {
    width: calc(100% - 30px) !important;
    margin-left: 15px;
    padding: 0;
    background: transparent;


    #views-exposed-form--content-members-list-block-1 {
      margin-bottom: 15px;

    }
  }

  .view-content {
    width: calc(100% - 30px) !important;
    margin-left: 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .views-exposed-widgets {
    //padding: 18px 15px 5px 15px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 45px;
    background: #f4f4f4;

    .views-exposed-widget {
      //width: calc(20% - 3px);
      flex: 1 0 calc(20% - 30px);
      padding: 15px !important;
      max-width: 100%;

      @media (max-width: 1500px) {
        flex: 1 0 25%;

      }

      @media (max-width: 1024px) {
        flex: 1 0 33.33333%;
      }

      @media (max-width: 500px) {
        flex: 1 0 50%;
      }

      &#edit-names-wrapper {
        flex: 1 0 400px;
      }

      label {
        font-size: 13px;
        color: #000;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: -13px;
      }

      .bef-checkboxes {
        label {
          margin-top: 0;
        }
      }

      //&#edit-field-member-awarded-value-1-wrapper, &#edit-field-member-prize-value-wrapper {
      &#edit-field-member-prize-value-wrapper {
        //border: 1px solid #efeeeb;
        //padding: 10px 6px 11px 6px;
        font-size: $font-size-content-minus;
        padding: 15px !important;
        transform: translateY(0);

        .form-item {
          display: flex;
          align-items: center;
        }

        label {
          font-size: $font-size-content-minus;
          display: inline-block;
          float: none;
          margin-left: 0;
          white-space: nowrap;
        }
      }

      select {
        width: 100%;
      }

      &.views-submit-button,
      &.views-reset-button {
        margin-top: 6px;
        text-align: right;
        background: transparent;
        display: block;
        flex: 0 0 70px;
        display: flex;
        align-items: center;
        padding: 8px 5px !important;

        &:focus {
          border-radius: 0;
          border: 1px solid #555;
          outline: none;
        }
      }

      &.views-submit-button {
        position: absolute;
        bottom: -40px;
        right: 72px;
      }

      &.views-reset-button {
        position: absolute;
        bottom: -40px;
        right: -7px;
      }
    }
  }

  .f-icon {
    width: 24px;
    height: 24px;
    font-size: 21px;
    //color: $color-base-black;
    color: #000;
    float: right;

    &[data-original-title="PhD Fellowship"] {
      opacity: .3;
    }

    &[data-original-title="Early Career Fellowship"] {
      opacity: .55;

      &:before {
        font-family: IcoFont !important;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        line-height: 1;
        font-feature-settings: "liga";
        content: "\eae3";
        font-size: 20px;
        margin-top: 1px;
        display: block;
      }
    }


  }

  .a-icon {
    max-width: 24px;
    height: 24px;
    font-size: 20px;
    color: $color-base-black;
    float: right;
  }
}

.view-front-success-stories {
  .success-story {
    box-sizing: content-box;
    width: 90%;
    background-position: 98% 0;

    .member-info {
      width: 82%;
    }

    figure {
      box-shadow: none;
      padding: 0;
    }

    .tagline {
      border-radius: 0px;
    }

    .read-more-yellow-plus:before {
      line-height: 20px;
    }
  }
}

#success-story {
  padding: 20px 0;

  .member-info {
    box-sizing: content-box;
    width: calc(100% - 97px);
  }

  figure.bigimage {
    margin: 0 auto;
  }

  .text {
    width: auto;
    max-width: 100%;
  }

  &:before {
    right: -6px;
  }

  .youtube-container--responsive {
    max-width: 580px;
    margin: 0 auto;
  }
}

#networks {
  height: auto;
  border-bottom: none;
  padding-bottom: 15px;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 5px solid $color-green-dark;

  .network {
    width: 696px;
    height: auto;
    margin-bottom: 60px;
    background: $color-green-light;

    h2 {
      text-align: left;
      position: absolute;
      z-index: 10;
      left: 15px;
      top: 15px;
      font-weight: 400;

      p {
        font-size: $font-size-content-minus;
        font-weight: 300;
      }
    }

    .network-info {
      border: none;
      padding: 0;
    }

    .network-info-wrapper {
      position: relative;
      float: left;
      box-sizing: content-box;
      width: 100%;

      .info {
        background-color: $color-base-black;
        padding-top: 20px;
        padding-bottom: 30px;
        height: auto;
        position: absolute;
        right: 0;
        top: 0;
        height: 200px;
        color: $color-green-text;
        min-width: 180px;

        .item {
          p {
            color: $color-green-light;
            border-bottom: 1px solid #aaa;
            padding-bottom: 3px;
            margin-left: 15px;
            margin-right: 15px;
            font-size: $font-size-content-minus1;
            padding-top: 2px;
            font-weight: 400;
          }

          &:first-child {
            p {
              border-top: 1px solid #aaa;
            }
          }
        }

      }

      .map {

        a {
          height: 200px;
          background-position: -60px 0;

          &:hover {
            background-position: -60px 0;
          }
        }
      }
    }

    .members {
      margin-top: 0px;

      figure {
        width: 100%;
        height: auto;
        overflow: hidden;

        img.img-thumbnail {
          width: 100%;
        }
      }

      .member-short-success {
        figure {
          height: auto;
        }
      }

      h4 {
        text-align: left;
        text-transform: uppercase;
        padding-left: 15px;
      }

      .more {
        background: $color-base-white;
      }
    }

    .chart {
      right: 10%;
      bottom: -40px;
    }

  }

  .chart {
    left: auto;
    left: initial;
    right: 144px !important;
    margin-left: 0px;
    bottom: -30px;
    top: auto;
    top: initial;
    z-index: 10;
  }
}

.members {
  float: left;
  box-sizing: content-box;
  width: 100%;
  margin-top: -22px;

  .more {
    border-bottom: 1px solid $color-green-dark;
    text-align: right;
  }

  .member-short {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    overflow: visible;
    margin-bottom: 1px;


    &:hover {
      border-right: none;
    }

    figure {
      width: 100%;
      margin-right: 0px;
      overflow: hidden;

      img {
        width: 100%;
        -webkit-filter: grayscale(100%) brightness(105%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%) brightness(105%);
        -moz-filter: grayscale(100%) brightness(105%);
        -o-filter: grayscale(100%) brightness(105%);
        -ms-filter: grayscale(100%) brightness(105%);
        transition: 0.5s;

        &:hover {
          -webkit-filter: grayscale(0%) brightness(100%);
          /* Safari 6.0 - 9.0 */
          filter: grayscale(0%) brightness(100%);
          -moz-filter: grayscale(0%) brightness(100%);
          -o-filter: grayscale(0%) brightness(100%);
          -ms-filter: grayscale(0%) brightness(100%);
          transform: scale(1.1);
        }

        &.img-thumbnail {
          border: none;
          padding: 1px 1px 0px 1px;
          border-radius: 0;
          width: auto;
        }
      }
    }

    .member-info {
      position: absolute;
      left: 1px;
      width: 20px;
      background: $color-base-black;
      text-align: center;
      padding: 3px;
      height: 20px;
      bottom: 1px;
      overflow: hidden;
      transition: 0.5s;
      content: '+';
      color: $color-base-white;

      .fa {
        color: white;
        -webkit-text-stroke: 2px $color-base-black;
        text-stroke: 2px $color-base-black;
        line-height: 16px;
        font-size: 16px;
      }

      &:hover {
        height: 100%;
        left: 1px;
        width: calc(100% - 2px);
        background: rgba($color-base-black, .5);
        text-align: center;
        padding: 3px;
        overflow: hidden;
        position: absolute;
        bottom: 0px;

        p,
        h5 {
          opacity: 1;
        }

        .fa {
          display: none;
        }
      }

      a {
        color: $color-base-white !important;
      }

      p {

        padding-bottom: 0px;
        color: $color-base-white;
        font-size: $font-size-content-minus2 !important;
        line-height: $font-size-content-minus2 !important;
        font-weight: 300;
        opacity: 0;

      }

      h5 {
        padding-top: 30px;
        color: $color-base-white;
        font-size: $font-size-content-minus;
        line-height: $font-size-content-minus;
        font-weight: 300;
        margin-bottom: 5px;
        opacity: 0;
      }

      .country-network-other-countries {
        margin-top: 10px;
        font-size: $font-size-content;

        a {
          font-weight: 400 !important;
        }
      }
    }
  }
}

aside {

  .block-menu {
    ul {
      ul {
        &:hover {
          background: transparent;
        }
      }
    }
  }

  .block-menu li li::before {
    content: "";
    display: none;
  }

  .block-menu li li a {
    line-height: 20px;
    margin-left: 15px;
  }

  .owl-theme .owl-controls .owl-page span {
    background: $color-grey-grid;
  }

  //sidebar news
  #block-views-front-news-block-1,
  #block-views-front-news-block-2 {
    background: #f7f6f5;

    .block-title {
      padding: 10px 10px 0px 10px;
    }

    .view-display-id-block_1 {
      margin-top: 0px;
    }

    .owl-item {
      padding: 0px 10px 10px 10px;

      .views-field-created {
        font-size: $font-size-content-minus2;
        margin-top: 7px;
      }

      .views-field-title {
        font-size: $font-size-content-minus;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .views-field-field--preview-text {
        font-size: $font-size-content-minus;
        margin-bottom: 5px;
      }

      .views-field-view-node {
        text-align: right;
        padding-top: 2px;
        font-size: $font-size-content-minus;
        border-top: 1px solid $color-green-dark;
      }
    }

    .view-content>.disabled {
      padding: 10px;

      .views-field-created {
        font-size: $font-size-content-minus2;
        margin-top: 7px;
      }

      .views-field-title {
        font-size: $font-size-content-minus;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .views-field-field--preview-text {
        font-size: $font-size-content-minus;
        margin-bottom: 5px;
      }

      .views-field-view-node {
        text-align: right;
        padding-top: 2px;
        font-size: $font-size-content-minus;
        border-top: 1px solid $color-green-dark;
      }
    }
  }

  //sidebar host institutes
  .block-news {

    .owl-wrapper,
    .disabled {
      background: #f7f6f5;

      &:hover {
        background: #d2ecdc;
      }
    }

    .owl-carousel .owl-item img {
      margin: 0 auto;
      width: auto;
      max-width: 100%;
    }


    article {
      border-right: none;
      text-align: center;
      background: transparent;

      &:hover {
        border-right: none;
        background: transparent;
      }
    }

    a {
      color: $color-green-dark;
    }
  }

  //sidebar success stories {
  #block-views-front-success-stories-block-2 {

    .owl-wrapper {
      background-color: $color-yellow-light;
    }

    .owl-item {
      cursor: pointer;
      background-image: url(../images/ribbon_y.png);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 25px auto;
      padding: 10px;
      border-top: 2px solid $color-yellow-mid;
      margin-bottom: 20px;
      min-height: 100px;

      img {
        width: auto;
        margin-bottom: 15px;
      }

      .views-field-field-success-tagline {
        font-style: italic;
        font-weight: 300;
        font-size: $font-size-content-minus;
        color: $color-yellowbg-text;
        margin-bottom: 20px
      }

      .views-field-field--member-profile {
        font-size: $font-size-content-minus;
      }

      .views-field-field--field {
        font-style: italic;
        font-size: $font-size-content-minus2;
      }

      .views-field-view-node {
        border-top: 1px solid $color-yellow-mid;

        a {
          color: $color-yellow-mid;
        }
      }
    }
  }
}

.block-views {
  .view-display-id-block_1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.view-frontpage-grid {
  .view-content {
    .link-box {
      a {

        &:link,
        &:hover,
        &:visited,
        &:active {
          color: $color-base-white;
          text-decoration: none;
        }

        &.more {
          transition: all .2s ease-in-out;
          background-color: rgba(82, 82, 82, .5);
          border: none;
          width: 43%;
          margin: 2px;

          &:hover {
            text-decoration: none;
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

#national-chapters {
  padding: 0 0 45px 0;


  .region {
    height: auto;
    border: 1px solid $color-grey-light;

    header {
      h2 {
        font-size: $font-size-content-plus2;
        font-weight: bold;
      }
    }

    .text {
      height: auto;
      border-right: none;
      position: relative;

      .item {
        display: inline-block;
        font-size: $font-size-content;
        border-bottom: 3px solid darken($color-yellow, 5%);
        margin-bottom: 10px;
        width: auto;
        margin-left: 0;
        margin-right: 4px;

        p {
          font-size: $font-size-content-minus;
        }
      }

      .info {
        margin-top: 15px;
        font-size: $font-size-content-minus3;

        strong {
          font-size: $font-size-content-minus;
          color: $color-base-black;
        }

        a {
          color: $color-green-text;
          font-size: $font-size-content-minus;

          &[href^="mailto:"] {
            color: darken($color-yellow, 5%);
          }
        }


        h4 {
          font-size: $font-size-content-minus;
          font-weight: bold;
        }
      }

      p {
        font-size: $font-size-content-minus3;
      }
    }



    .no-coordinator {
      .text {
        .item {
          width: auto;
          margin-left: 0;
        }
      }
    }

    &.no-coordinator {
      background: #efeeeb;

      .info,
      .more {
        width: 100%;
        margin-left: 0;
      }
    }

    .more {
      position: relative;
      text-align: left;
      margin-top: 15px;

    }

    .members {
      width: calc(100% - 314px);
      height: auto;
      margin-top: 0px;
      padding: 0;

      .view-id-front_regions_country_list {
        .view-content {
          display:flex;
          flex-wrap:wrap;
          background: white;
          .views-row {
            flex: 0 0 20%;
            max-width: 20%;
            display:none;

            &:nth-child(-n+10) {
              display:block;
            }

            .member-short {
              width: 100%;

              figure {
                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%,-50%);
                  filter: grayscale(0%) brightness(100%);
                  border: 1px solid white;
                }
              }
            }
          }
        }
      }
    }

  }
}

#network-mission {
  h2 {
    font-size: $font-size-content-plus;
  }
}

#country {
  .accordion {
    .accordion-button {
      height: auto;
      padding: 0 15px 0 15px;
      border: none;
      margin-bottom: 15px;

      &.allmembersnew {
        background-color: transparent !important;

        &.yellow-button {
          background-position: 0px 0;
          border: none;
          padding-bottom: 0px;
          background-image: none;

          &:hover {
            background-color: transparent !important;
          }

          .s-icon {
            top: -16px;
            position: absolute;
            right: 0;
          }
        }
      }

      figure {
        margin-right: 0;
        position: relative;
        width: 100%;

        img {
          width: 100%;
        }
      }

      &.yellow-button {
        background-position: 0px 0;
        border: none;
        border-bottom: 1px solid $color-yellow-mid;
        background-image: none;

        &:hover {
          background-color: $color-yellow-light;
        }
      }

      .member-accordion {
        box-sizing: content-box;

        .reverse-icon {
          &:after {
            background: none;
            background-image: url(../images/diploma-grey.png);
            background-repeat: no-repeat;
            background-size: 32px auto;
          }

          &.type {
            top: 8px;
            right: 43px;
          }
        }

        .member-info {
          padding-top: 20px;
          padding-bottom: 15px;
        }
      }

      h5 {
        font-size: $font-size-content-minus;
        font-weight: bold;
      }

      p {
        font-size: $font-size-content-minus3;
        line-height: $font-size-content;
      }

      .since {
        font-size: $font-size-content-minus;
        line-height: $font-size-content;
        color: $color-grey-mid;
      }

      /*.f-icon {
				padding: 1px;
				width: 24px;
				height: 24px;
				position: absolute;
				bottom: -24px;
				right: 0;
				font-size: 21px;
				color: $color-base-black;
			}
			.a-icon {
				padding: 1px;
				max-width: 24px;
				height: 24px;
				position: relative;
				position: absolute;
				bottom: -24px;
				right: 25px;
				font-size: 20px;
				color: $color-base-black;
			}

			.s-icon {
				padding: 1px;
				max-width: 24px;
				vertical-align: bottom;
				background-color: transparent;
				position: absolute;
				top: -1px;
				right: -1px;
			}*/




    }
  }

  #views-exposed-form-front-country-page {
    //border-bottom: 1px solid $color-grey-light;
    margin-bottom: 15px;

    .form-checkboxes {
      margin: 8px 30px 5px 0px;
    }
  }
}

.view-front-country {

  &.view-display-id-page,
  &.view-display-id-page_other_countries {
    .view-filters {
      margin-top: 60px;
      padding-top: 9px;
      margin-left: 15px;
      width: calc(100% - 30px) !important;
      //border-top: 1px solid $color-grey-light;
    }

    dl {
      width: calc(100% - 30px) !important;
      margin-left: 15px;
      display: flex;
      flex-wrap: wrap;

      .allmembersnew {
        .member-accordion {
          .memberlist-item {
            border: 1px solid #efeeeb;
            padding-top: 15px;
            padding-bottom: 5px;
            margin-bottom: 15px;
            min-height: 130px;
            position: relative;
            height: calc(100% - 15px);

            .icons-wrap,
            p.country,
            p.specialization-data {
              width: 100%;
            }
          }
        }
      }
    }
  }

}

.national-chapters-list {
  margin-top: 30px;

  .view-filters {
    margin-left: 15px;
    width: calc(100% - 30px) !important;
    background: transparent;
    padding: 18px 0;
  }

  .region {
    height: 231px;
    border: 1px solid $color-grey-light;
    padding: 15px;
    margin-bottom: 15px;
    background-color: $color-grey-light;

    &:hover {
      background: $color-green-light;
    }


    header {
      h2 {
        font-size: $font-size-content-plus2;
        font-weight: bold;
      }
    }

    .text {
      height: auto;
      border-right: none;

      .item {
        display: inline-block;
        font-size: $font-size-content;
        border-bottom: 3px solid darken($color-yellow, 5%);
        margin-bottom: 10px;
        width: auto;
        margin-left: 0;
        margin-right: 7px;

        p {
          font-size: $font-size-content-minus;
          padding-bottom: 0px !important;
        }
      }

      .info {
        margin-top: 15px;
        font-size: $font-size-content-minus3;

        strong {
          font-size: $font-size-content-minus;
          color: $color-base-black;
          font-weight: 400;
          text-transform: uppercase;
        }

        a {
          color: $color-green-text;
          font-size: $font-size-content-minus;

          &[href^="mailto:"] {
            color: darken($color-yellow, 5%);
          }
        }


        h4 {
          font-size: $font-size-content-minus;
          font-weight: bold;
        }
      }

      p {
        font-size: $font-size-content-minus3;
        padding-bottom: 5px !important;
      }
    }

    .more {
      position: relative;
      text-align: left;

      a {
        text-transform: uppercase;
        font-size: $font-size-content-minus;
      }

    }

  }
}

.view-display-id-block_proceedings {
  .more {
    display: none;
  }
}


.success-stories-wrapper {
  background: $color-yellow-light;

  h3 {
    background: $color-base-white;
    border-bottom: 2px solid $color-yellow-mid;
    font-size: $font-size-content-plus2;
    color: $color-yellow-mid;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }

  .owl-item {
    img {
      max-height: 160px;
      width: auto;
      margin: 0 auto;
    }
  }

  .block-news {

    .views-field-created {
      font-size: $font-size-content-minus;
      margin-top: 10px;
    }

    .views-field-view-node {
      border-top: 1px solid $color-green-light;
    }

    .views-field-title {
      font-weight: bold;
      font-size: $font-size-content;
      margin-bottom: 10px;
    }

    .views-field-field--preview-text {
      font-style: italic;
      font-weight: 300;
      font-size: $font-size-content-minus;
      color: $color-yellowbg-text;
      margin-top: 10px;
    }
  }

  .view-display-id-block_2.success-stories {


    .owl-item {
      padding: 15px;

      &:hover {
        background: $color-yellow-light-hover;
      }

      img {
        max-height: 160px;
        width: auto;
        margin: 0 auto;
      }

      .views-field-field-success-tagline {
        font-style: italic;
        font-weight: 300;
        font-size: $font-size-content-minus;
        color: $color-yellowbg-text;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .views-field-field--member-profile {
        font-size: $font-size-content-minus;
      }

      .views-field-field--field {
        font-style: italic;
        font-size: $font-size-content-minus2;
      }

      .views-field-view-node {
        border-top: 1px solid $color-yellow-mid;

        a {
          color: $color-yellow-mid;
        }
      }
    }
  }


}

.content-news-wrapper,
.success-stories-wrapper,
.news-list {
  background: $color-grey-news-bg;
  margin-top: 30px;

  &.view-display-id-page_1,
  &.view-display-id-page_2 {
    background: none;

    .lead-image {
      margin-bottom: 15px;

      .link-box {
        bottom: 15px;
      }
    }

    .view-filters {
      margin-left: 15px;
      width: calc(100% - 30px) !important;
      height: auto;
    }


  }


  .view-front-news {
    margin-bottom: 15px;
  }


  #block-views-front-news-block-1 {
    h3.block-title {
      font-size: 21px;
      padding-bottom: 4px;
      margin: 0;
      color: $color-green-dark;
      background: $color-base-white;
      display: block;
      border-bottom: 2px solid $color-green-dark;
      padding-top: 5px;
      text-align: center;
      margin-top: -30px;
    }
  }

  .view-front-news,
  &.view-display-id-page_1,
  &.view-display-id-block_workshop,
  &.view-display-id-page_2 {

    .owl-carousel {
      .n-wrapper {
        width: 100%;
      }
    }

    .lead-image {
      height: 250px;
      padding-bottom: 70%;
      background-size: cover;
      //background-position: center center;
      background-position: center top -30px;
      background-repeat: no-repeat;

      .fa {
        position: absolute;
        left: 10px;
      }

      &:hover {
        .data-wrapper {
          text-decoration: none;
          transform: scale(1.1);
        }
      }
    }

    .link-box {
      padding: 15px 10px;
      position: absolute;
      bottom: 0px;
      left: 15px;
      width: calc(100% - 30px);
      text-align: center;
      background: rgba(82, 82, 82, .5);

      .data-wrapper {
        font-weight: 400;
        transition: all .2s ease-in-out;
        display: block;
        color: $color-base-white;
        text-decoration: none;

        p {
          padding-bottom: 0 !important;
          font-size: $font-size-content-minus1;
        }

        &:hover {
          text-decoration: none;
          transform: scale(1.1);
        }

        .newstitle:after {
          content: "\f054";
          /* this is your text. You can also use UTF-8 character codes as I do here */
          font-family: FontAwesome;
          margin-left: 15px;
          font-weight: 300;
          color: $color-base-white;
        }
      }

      small {
        font-size: 85%;
      }
    }
  }

}

.view--front-opportunities {
  span.icon-opportunity-icon {
    display: none;
  }

  .news-teaser {
    border-bottom: none;

    &:before {
      top: 18%;
      right: 0;
    }

    h5 {
      &.date {
        margin-left: 0;
      }
    }

    .text {
      box-sizing: content-box;
      width: auto;
    }
  }

}

#conference {
  .item-list {
    border-top: none;

    li {
      padding: 15px;
      margin: 0;
      background: none;
      height: auto;

      &:hover {
        background: none;
      }

      h4 {
        padding-top: 0;
      }

      figure {
        text-align: left;
        top: initial;
        left: initial;
        width: 100%;
        position: relative;
      }

      img[typeof="foaf:Image"] {
        display: inline-block;
        width: 190px;
      }

      .member {
        background: $color-grey-light;
        padding: 30px;
        min-height: 290px;

        &:hover {
          background: $color-green-light;
        }
      }
    }
  }

  #accordion dt,
  .accordion dt {
    box-sizing: content-box;
  }
}

#eb-role {
  display:flex;
  flex-wrap: wrap;

  figure {
    z-index: 500;
    position: relative;
    img {
      width: 200px !important
    }

    figcaption {
      margin-bottom: 15px;
    }
  }
  header {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    padding: 30px 0 15px 280px;
    min-height: 138px;
    height: auto;
    margin-top: -100px;
  }

  .text {
    width: 100%;
  }

  .youtube-container--responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

.node-type-member-profile-alumna {
  #content {
    .node-member_profile_alumna-form {

      fieldset.general-information,
      fieldset.contact-info,
      fieldset.research-profession,
      fieldset.awards {
        >.fieldset-wrapper {
          >.fieldset-description {
            &:before {
              display: none;
            }
          }
        }
      }

      .form-wrapper {
        margin-bottom: 15px;
      }

      fieldset.primary-group>div>fieldset>legend {
        width: 100%;
        background: $color-green-dark;
        font-style: normal;

        span.fieldset-legend {
          color: $color-base-white;
          font-size: 14px;
        }
      }

      >div>fieldset>.fieldset-wrapper {
        background: $color-base-white;
      }

      .description,
      .fieldset-description {
        font-size: 12px;
        color: $color-grey-mid;
        background-color: transparent !important;

        &:before {
          font-family: FontAwesome;
          content: "\f05a";
          color: $color-grey-mid;
          margin-right: 5px;
          //margin-left: 10px;
        }

        .info-icon {
          display: none;
        }

        .helptext {
          opacity: 1;
          position: relative;
          z-index: 0;
          font-style: normal;
          top: auto !important;
          right: auto !important;
          padding: 0;
          background: transparent;
          color: $color-grey-mid;

          &:before {
            display: none;
          }
        }
      }

      .form-required {
        font-weight: bold;
      }

      .form-radio+label:before {
        height: 14px;
        width: 14px;
        font-size: .6em;
      }

      .form-checkbox:checked+label:before {
        font-size: 10px;
      }

      .form-type-radios.form-item-field-member-gender-und {
        border-bottom: 1px solid #dadada;
      }

      #edit-field-picture-und-0-upload {
        &:after {
          width: 80px !important;
        }
      }

      textarea:disabled {
        background: lighten($color-grey-light, 5%);
      }

      input[type="file"] {
        background-color: transparent;
        border: none;
        width: auto !important;
        line-height: 30px;
        margin-right: 0;
      }

      .field-type-field-collection tbody tr {
        border-bottom: none;

        td:after {
          bottom: -45px;
        }
      }

      .field-name-field-mq-certificate {
        tr {

          &.odd,
          &.even {
            border-bottom: 1px solid $color-grey-light;
          }

          td:first-child {
            border-right: none;
            max-width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }

      .field-multiple-table {
        border-collapse: separate;
        border-spacing: 0;
      }
    }
  }
}

.other.node-type-member-profile-alumna #content .node-member_profile_alumna-form .field-name-field-mq-certificate input[type=submit] {
  display: none;
}

.form-item-pass-pass1 {
  label {
    display: block;
  }
}

.node-type-member-profile-alumna #edit-field-name label:after,
.node-type-member-profile-alumna #edit-field-surname label:after,
.node-type-member-profile-alumna #edit-field-country--2 label:after,
.node-type-member-profile-alumna #edit-field-country label:after,
.node-type-member-profile-alumna #edit-field-member-city label:after,
.node-type-member-profile-alumna #edit-field-field label:after,
.node-type-member-profile-alumna #edit-field-tags label:after,
.node-type-member-profile-alumna #edit-field-picture label:after,
.node-type-member-profile-alumna #edit-field-member-curriculum label:after,
.node-type-member-profile-alumna #edit-field-current-activities label:after,
.node-type-member-profile-alumna #edit-field-current-prof-desc label:after,
.node-type-member-profile-alumna #edit-field-research-publications label:after,
.node-type-member-profile-alumna #edit-field-research-activities label:after,
.node-type-member-profile-alumna #edit-field-field-specialization label:after,
.node-type-member-profile-alumna #edit-field-member-awarded label:after,
.node-type-member-profile-alumna #edit-field-member-graduation-picture label:after,
.node-type-member-profile-alumna #edit-field-member-short-summary label:after,
.node-type-member-profile-alumna #edit-field-member-other-picture legend:after,
.node-type-member-profile-alumna #edit-field-member-title-of-phd label:after,
.node-type-member-profile-alumna #edit-field-member-thesis-entire label:after,
.node-type-member-profile-alumna #edit-field-member-final-reports legend:after,
.node-type-member-profile-alumna #edit-field-memb-fell-publications legend:after,
.node-type-member-profile-alumna #edit-field-field-member-career-develo label:after,
.node-type-member-profile-alumna #edit-field-member-qualification td div.field-name-field--degree div label:after,
.node-type-member-profile-alumna #edit-field-member-qualification td div.field-name-field--field div label:after,
.node-type-member-profile-alumna #edit-field-member-qualification td div.field-name-field-year-of-achievement div legend:after,
.node-type-member-profile-alumna #edit-field-member-attended td div div div label:after,
.node-type-member-profile-alumna #edit-field-member-pres-given td div div div label:after,
.node-type-member-profile-alumna #edit-field-member-prizes td div.field-name-field-prizes-prize-title div div label:after,
.node-type-member-profile-alumna #edit-field-member-prizes td div.field-name-field-prizes-month-year div fieldset legend:after,
.node-type-member-profile-alumna #edit-field-member-prizes td div.field-name-field-prizes-prize-description div div label:after,
.node-type-member-profile-alumna #edit-field-member-twas-award td div.field-name-field--programmes div label:after,
.node-type-member-profile-alumna #edit-field-member-twas-award td div.field-name-field-prizes-month-year fieldset legend:after,
.node-type-member-profile-alumna #edit-field-member-twas-award td div.field-name-field-prizes-prize-description div div label:after,
.node-type-member-profile-alumna #edit-field-member-grants td div.field-name-field-prizes-prize-title div label:after,
.node-type-member-profile-alumna #edit-field-member-grants td div.field-name-field-prizes-prize-description div div label:after,
.node-type-member-profile-alumna #edit-field-member-grants td div.field-name-field-prizes-month-year fieldset legend:after,
.node-type-member-profile-alumna #edit-field-member-prof-memb td div.field-name-field-pm-institution div div label:after {
  margin-left: 5px;
  margin-bottom: -2px;
}

.node-type-member-profile-alumna #edit-field-member-curriculum label::after {
  background: none;
}

.node-type-member-profile-alumna #content .form-item-field-member-curriculum-und-0 label.option {
  display: block;
  margin-top: 5px;
  margin-left: 15px;
}


.manualcrop-overlay {
  .manualcrop-instantpreview {
    width: 150px !important;

    img {
      max-width: none;
    }
  }
}


.page-node-58891 {
  .page {
    header {
      display: none;
    }
  }
}

.allmembersnew {
  &.yellow-button {
    .memberlist-item {
      border: 1px solid $color-yellow-light2;

      &:hover {
        background-color: $color-yellow-light2;
      }
    }
  }

  .memberlist-item {
    border: 1px solid $color-grey-light;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    min-height: 130px;
    position: relative;
    height: calc(100% - 15px);

    .text {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }

    &:hover {
      background-color: $color-green-light;
    }

    h2 {
      font-size: $font-size-content;
    }

    p.country {
      font-size: $font-size-content-minus3;
      line-height: 1.3 !important;
      padding-bottom: 0px !important;
      margin-top: 5px;
      margin-bottom: 10px;

      a {
        font-weight: bold;
      }
    }

    .icons {
      /*position: absolute;
			bottom: 0;
			right: 15px;*/
      width: 100%;
    }

    img {
      max-height: 100px;
    }

    .a-icon,
    .f-icon {
      margin-top: 5px;
      position: relative !important;
      float: right;
      bottom: auto !important;
      right: auto !important;
      font-size: 21px;
      height: 24px;
      width: 24px;
      //color: #525252;
      color: #000000;

      &[data-original-title="PhD Fellowship"] {
        opacity: .3;
      }

      &[data-original-title="Early Career Fellowship"] {
        opacity: .55;
      }
    }

    .text {
      position: relative;
    }
  }

  &.awardees {
    margin-left: -15px;
    width: calc(100% + 30px);

    .memberlist-item {
      height: 100%;

      .text {
        flex-direction: column;
      }


      img {
        max-height: none !important;
      }

      p {
        &.country {
          font-size: $font-size-content-minus;
        }

        &.year {
          font-size: $font-size-content;

          span {
            padding: 1px 5px;
          }
        }
      }
    }

  }
}

.gallery-news {

  .grid:after {
    content: '';
    display: block;
    clear: both;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .grid-item, a.colorbox {
    width: calc(25% - 10px);
    margin-bottom: 10px;
    margin-right: 10px;



    @media screen and (min-width: 768px) {
      &:nth-child(4) {
        margin-right: 0;
      }
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      width: calc(33% - 10px);

      &:nth-child(3) {
        margin-right: 0;
      }
    }

    @media screen and (min-width: 401px) and (max-width: 575px) {
      width: calc(50% - 10px);

      &:nth-child(2) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 400px) {
      width: calc(100% - 0px);
      margin-right: 0;
    }

    .caption p {
      font-size: 12px !important;
      font-style: italic;
      line-height: 15px !important;
      margin-top: 5px;
      color: rgb(82, 82, 82);
    }
  }



  img {
    width: 100%;

  }
}

#menu-donate {
  background: #ffc200;
  color: #525252;
  padding: 5px 10px;
  border-radius: 5px;
  //margin-right: -10px;
}

#menu-login {
  background: #005d25;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  //margin-right: -10px;
  white-space: nowrap;

  @media screen and (max-width: 959px) {
    &.desktop {
      display:none;
    }
  }

  @media screen and (min-width: 960px) {
    &.mobile {
      display:none;
    }
  }
}

.maintenance-page {
  //&body {
  padding-top: 0px !important;
  //}

  #content {
    height: calc(100vh - 150px);
    background-image: url('/sites/all/themes/owsd/images/mantainance.png');
    background-repeat: no-repeat;
    background-position: center 80px;
    background-size: auto 100%;
    width: 100%;
    font-size: 1.5rem;
    text-align: center;

    h2 {
      font-size: 3rem;
      margin-top: 30px;
    }
  }

  .footer-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: transparent;
  }

  footer.main {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 10px solid $color-green-dark;
  }
}

#member {
  .info {
    h1 {
      margin-top: 10px;
      font-size: $font-size-sidebar-headers;

    }

    .member-id {
      font-size: $font-size-content-minus;
    }

    .text {
      width: auto;

      .award_type {
        margin-bottom: 0;
      }

      .prize_type {
        font-weight: 700;
        text-transform: uppercase;

        &:before {
          font-family: "owsd" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          //speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 2rem;
          content: "A";
          width: 30px;
          height: 43px;
          position: relative;
          top: 15px;
          margin-right: 10px;
        }
      }

      .types {
        font-weight: 700;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 15px;
        padding-bottom: 4px;

        &:before {
          font-family: "owsd" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          //speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          font-size: 1.5rem;
          width: 30px;
          //height: 43px;
          height: auto;
          position: relative;
          top: 6px;
          margin-right: 2px;
        }

        &.prize_type:before {
          content: "A";
          font-size: 1.4rem;
        }

        &.award_type:before {
          content: "u";
          font-size: 1.5rem;


        }

        &[data-name="Early Career Fellowship"]:before {
          font-family: IcoFont !important;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          line-height: 1;
          -webkit-font-feature-settings: "liga";
          font-feature-settings: "liga";
          content: "\ec25";
          font-size: 24px;
          transform: translateY(-5px);
          display: inline-block;
        }
      }

      .bottom {
        margin-top: 25px;
      }

      .current {
        margin-top: 5px;
      }

      .specialization {
        margin-top: 10px;
      }

      .label-member {
        font-weight: normal;
        display: inline-block;
      }
    }
  }

}

.password-suggestions {
  ul {
    margin-top: 15px;

    li {
      padding: 0 0 0 20px;
    }
  }
}



.member-section {
  .field-name-field-member-presentation {
    .title-presentatiton {
      display: inline-block;

      &:before {
        content: '' !important;
      }
    }

    .year {
      display: inline-block;
    }
  }

  .field-item {
    .title {
      &:before {
        content: '' !important;
      }
    }
  }


  .gallery-other {
    position: relative;

    .grid {
      position: relative;
    }

    .grid:after {
      content: '';
      display: block;
      clear: both;
    }

    .grid-sizer,
    .grid-item {
      width: calc(25% - 10px);
      margin-bottom: 10px;
      position: relative;
      float: left;

      img {
        width: 100%;

      }
    }

  }

}

.block-youtubechannel {
  //border: 1px solid $color-green-light;
  margin: 0 auto;
  text-align: center;

  #youtubechannel-player {
    margin: 0 auto;
    width: 100% !important;
    height: auto !important;
  }

  #youtubechannel-list {
    width: 100% !important;

    li:before {
      content: "";
    }

    img {
      height: 85px
    }
  }

  .youtube-channel-link a {
    background: $color-green-text;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

.block-paypal-donations {

  .fa-paypal {
    margin-right: 15px;
  }

  .btn-primary {
    width: 200px;
    border-radius: 0;
    margin-left: 30px;
  }

  button[type=submit] {
    color: #fff;
    background-color: #005d25;
    border-color: #00441b;
    width: 200px;
    border-radius: 0;
    margin-left: 30px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
  }

  .inner_content {
    margin-top: -10px;

    .paypal-ul {
      li {
        &:before {
          display: none;
        }

        label {
          font-weight: bold;
        }

        input[type=checkbox],
        input[type=radio] {
          margin: 1px 5px 3px 4px;
        }

        &.last {
          margin-top: 25px;
          font-weight: bold;

          input {
            width: 200px;
            display: inline-block;
            border-radius: 0;
          }
        }
      }

    }
  }

}

.webform-client-form {

  .webform-component-markup {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px 0px;
    text-align: left;

    p {
      padding-bottom: 0px !important;

      span {
        background-color: transparent !important;
        font-weight: bold;
      }
    }

    h2 {
      border-top: 1px solid #efeeeb;
      color: #005d25;
      font-size: 18px;
      margin-top: 5px;
    }


  }

  .form-required {
    color: red;
  }


  .webform-component-textfield,
  .webform-component-email {
    margin-bottom: 7px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .varchar,
    .email {
      display: inline-block;
      width: 300px;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      border-radius: 0;
    }

    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      width: 200px;
      font-weight: 400;
    }
  }

  .webform-submit {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    background-color: #005d25;
    border-color: #00441b;
    width: 150px;
    border-radius: 0;
    margin-top: 30px;
    margin-bottom: 5px;
  }
}

#toolbar {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.submenu {
  nav {
    display: none;
  }
}

nav {
  .first-level {
    li {
      &:last-child {
        padding: 0 10px 0 5px;
      }
    }
  }
}

/*-------------.ckeditor-accordion-container--------------------------*/

.ckeditor-accordion-container {
  dl {
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: none;

    dt {
      a {

        &:link,
        &:hover,
        &:visited,
        &:active {
          background-color: #efeeeb;
          border-bottom: 1px solid darken(#efeeeb, 10);
          color: #005d25;
          font-weight: 700;
          outline: none;
          font-size: 18px;
        }

        &:hover {
          background-color: darken(#efeeeb, 10);
        }
      }

      .ckeditor-accordion-toggle {

        &:before,
        &:after {
          background: #005d25;
        }
      }
    }

    dd {
      padding: 15px 15px 15px 50px;
    }
  }
}

.bg-image-yt {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 0;
  padding-bottom: 56.25%;
}

#youtube,
#youtube-global {

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .item {

    float: left;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .link-box {
    padding: 15px 10px;
    //margin-top: -4px;
    width: 100%;
    text-align: center;
    background: rgba(82, 82, 82, .1);
    flex-grow: 1;

    .data-wrapper,
    h3 {
      font-weight: 400;
      transition: all .2s ease-in-out;
      display: block;
      color: #333;
      text-decoration: none;
      font-size: 1em;
    }

    h3:after {
      content: "\f054";
      font-family: FontAwesome;
      margin-left: 15px;
      font-weight: 300;
      color: #333;
    }

    p {
      font-size: 85%;
      padding-bottom: 0;
    }
  }
}

.youtube-list {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;


  #pagination-owsd,
  #pagination-list {
    width: 100%;
    padding-bottom: 0;
    margin: 0;

    li {
      padding: 6px 12px;
      color: #333;
      background-color: #fff;
      border: 1px solid #ddd;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }

      &.nextToken {
        float: right;
      }

      &.prevToken {
        float: left;
      }

      &:before {
        content: '';
      }
    }
  }
}

.captcha {
  .fieldset-legend {
    display: none;
  }
}

#content .node-member_profile_alumna-form {
  .field-name-field--degree {
    border-top: 30px solid white;
  }

  #edit-field-member-qualification-und-0-field-degree {
    border-top: 0px solid white;
  }

  .ajax-new-content {
    background-color: rgba(255, 0, 0, .1) !important;
  }

  .field-type-text {
    .form-item {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      .field-multiple-table {
        width: 100%;
        border-spacing: 0;

        .field-name-field--degree {
          border-top: 30px solid white;
        }
      }

      .field-add-more-submit {
        width: 200px;
        float: none;
      }

      .clearfix {
        margin-top: 7px;
      }

      .description {
        width: 100%;
      }
    }
  }

  #edit-actions {
    width: calc(100% - 40px);
    right: unset;
    margin-right: 40px;
  }
}

#video-content {
  position: relative;
  margin-top: 30px;

  @media (max-width: 537px) {
    margin-top: 40px;
  }

  .closeblock-processed {
    position: absolute;
    background: #ffbf00;
    width: calc(100% - 65px);
    padding: 17px 30px;
    transform: translateY(-62px);
    margin-left: 20px;
    text-align: center;
    font-size: 16px;

    @media screen and (max-width: 767px) {
      top: 0;
    }

    @media (max-width: 537px) {
      padding: 10px 30px;
      transform: translateY(-67px);
    }

    h3 {
      display: none;
    }

    .closeblock-button {
      float: right;
      position: absolute;
      top: 6px;
      right: 15px;
      cursor: pointer;
      transform: rotate(45deg);
      font-size: 20px;
    }
  }

}

#utility-wrapper {
  //position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 244px);
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 959px) {
    width: 100%;
  }

  @media screen and (max-width: 540px) {
    flex-wrap: wrap;
    width: calc(100% + 30px);
    //transform: translateX(15px);
  }

  .utility,
  .social {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    margin-right: 15px;

    @media screen and (max-width: 540px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;

      .item-list {
        #menu-donate {
          margin-right: 0;
          background: transparent;
        }
      }
    }
  }

  .social {

    li {
      background-color: $color-grey-mid;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
      margin-right: 3px;

      &:hover {
        background-color: darken($color-grey-mid, 10);
      }

      a {

        &:link,
        &:hover,
        &:visited,
        &:active {
          color: #fff;
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            font-size: 12px;
          }

          i {
            &:before {
              font-size: 15px;
            }
          }
        }

      }
    }


  }

  .utility {
    .item-list {
      height: 26px;

      >ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        @media screen and (max-width: 540px) {
          //text-transform: uppercase;
        }

        li {
          padding: 0 0 0 15px;
        }
      }
    }
  }
}

#news {
  .back-to {
    display: flex;
    justify-content: flex-end;

    #back-to {
      padding: 6px 12px;
      color: #333;
      background-color: #fff;
      border: 1px solid #ddd;

      &:hover {
        text-decoration: none;
        background-color: #eee;
      }
    }
  }
}

.twitter-timeline {
  border: 1px solid #ddd !important;
  padding: 0 10px !important;
}


#block-simple-instagram-feed-simple-instagram-block {
  border: 1px solid #ddd;
  padding: 10px;

  .block-title {
    text-transform: none;
    font-size: 21px;
    font-weight: 300;
    line-height: 24px;
    color: #292F33;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(15, 70, 100, .12);
  }

  .instagram-feed {
    .instagram_profile {
      text-align: left !important;

      .instagram_biography {
        font-size: 12px !important;
        line-height: 16px;
      }

    }

    .instagram_gallery {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;

      a {
        flex: 0 0 49%;
        max-width: 49%;
      }

      img {
        width: 100% !important;
        float: none !important;
      }
    }
  }
}

.yt-side {
  padding: 10px;
  border: 1px solid rgba(15, 70, 100, .12);

  .block-title {
    text-transform: none;
    font-size: 21px;
    font-weight: 300;
    line-height: 24px;
    color: #292F33;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(15, 70, 100, .12);
  }

  .vidCard {
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .morevid {
    text-align: right;
    padding-top: 10px;
    border-top: 1px solid rgba(15, 70, 100, .12);

    .fa {
      margin-left: 8px;
    }
  }

}

.page-taxonomy.network .subscribe {
  margin-top: 57px;
}

.subscribe {
  background-color: #005d25;
  background-image: url(/sites/all/themes/owsd/images/newspaper-white.svg);
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: right 20px top 24px;
  padding: 20px;

  p {
    color: #fff;

    span {
      width: calc(100% - 45px);
      display: block;
      margin-bottom: 10px;
    }

    a {
      display: block;

      &:link,
      &:hover,
      &:visited,
      &:active {
        background-color: #fff;
        color: #005d25;
        padding: 15px;
        text-align: center;
        font-weight: bold;
      }
    }
  }


}

.rssapp-feed {
  .rssapp-feed-toolbar {
    h1 {
      font-size: 18px;
      color: $color-green-dark;

    }
  }
}

#graduates-counter-outer-wrapper {
  @media screen and (max-width: ($screen-sm) - 1) {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .graduates-counter-wrapper {
    position: relative;
    margin-bottom:15px;

    @media screen and (max-width: ($screen-sm) - 1) {
      max-width: 210px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .graduates-counter-inner {
      position: absolute;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      color: white;
      padding: 15px;
      @media screen and (max-width: ($screen-sm) - 1) {
        width: calc(100% - 20px);
        font-size: 17px;
      }

      span {
        font-size: 25px;
        @media screen and (max-width: ($screen-sm) - 1) {
          font-size: 20px;
        }
      }
    }

    img {
      border-radius: 3px;
    }
  }
}


#map .mapNavs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    margin-left: -35px;
    width: calc(100% + 70px);
  }

  li {
    flex: 0 0 25%;
    max-width: 25%;

    @media (max-width: 1140px) {
      padding: 0 5px;

      a:hover {
        transform: none;
      }
    }


    @media (max-width: 900px) {
      flex: 0 0 50%;
      max-width: 50%;
      margin-top:5px;
    }
  }

  .count-wrapper {
    display: flex;
    justify-content: center;

    .double {
      min-width: 115px;

      span {
        font-size: 10px;
        margin-top: -4px;
        display: block;
      }

      .count, .count2 {
        margin: -5px auto 0 !important;
      }

      &:first-child {
        border-right: 1px solid #ddd;
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }
}

#video {
  margin-top: 0;
}

#frontgrid {
  margin-bottom: 0;
}

#block-block-19 {
  &>.col-sm-12 {
    background-color: #005d25;
    padding: 20px;
    margin-left: 20px;
    width: calc(100% - 35px);
    margin-bottom: 10px;
    margin-top: -4px;

    &>.col-sm-12 {

      &>div {
        a {
          color: #ffffff;
          font-size: 18px;
          text-align: right;
          &:first-child {
            font-size: 22px;
          }
        }
      }
    }
  }
}

#content {
  .caption.left {
    height: auto !important;
    .caption-img {
      margin-left: 0!important;
      margin-right: 15px !important;
      height: auto !important;
      margin-bottom: 5px;
    }

    p {
      display: inline-block !important;
      margin-right: 20px!important;
    }
  }

  .caption.none {
    height: auto !important;
    .caption-img {
      margin-left: 0!important;
      margin-right: 0 !important;
      height: auto !important;
      margin-bottom: 5px;
    }

    p {
      display: block !important;
    }
  }

  .caption.right {
    height: auto !important;
    .caption-img {
      margin-left: 15px!important;
      margin-right: 0 !important;
      height: auto !important;
      margin-bottom: 5px;
    }

    p {
      display: inline-block !important;
      margin-left: 20px!important;
    }
  }
}

div.assembly-cert {
  padding: 18px 18px 18px 10px;
  height: auto;
  font-weight: 300;
  text-transform: uppercase;
    position: relative;
    border-bottom: 1px solid #eee;
    font-size: 1.4em;

  a {
    font-weight: 400;
    font-size: 1em;
    &:before {
      color: $color-green-dark;
      margin-right: 9px;
      margin-left: 20px;
      font-size: 1em;
      vertical-align: middle;
      font-family: "owsd" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      content: "o";
    }
  }

  span {
    &:before {
      color: $color-green-dark;
      margin-right: 9px;
      font-size: 1.9em;
      vertical-align: middle;
      font-family: "owsd" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      content: "w";
    }
  }
}

#general-cert {
  padding: 18px 18px 18px 10px;
  height: auto;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  border-bottom: 1px solid #eee;
  font-size: 1.4em;

  a {
    font-weight: 400;
    font-size: 1em;
    color: $color-green-dark;
    &:before {
      color: $color-green-dark;
      margin-right: 9px;
      margin-left: 20px;
      font-size: 1em;
      vertical-align: middle;
      font-family: "owsd" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      content: "o";
    }
  }

  span {
    &:before {
      color: $color-yellow;
      margin-right: 9px;
      font-size: 1.9em;
      vertical-align: middle;
      font-family: "owsd" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      content: "x";
    }
  }
}

.yellow a {
  color: $color-yellow;
}

#announcement-wrapper {
  .grid {
    display: flex;
    flex-wrap: wrap;

    &.ecf {
      .node-announcements {
        flex: 0 0 100%;
        margin-bottom: 10px;

        &:nth-child(odd) {
          margin-right: 0;
        }

        &:nth-child(even) {
          margin-left: 0;
        }
      }
    }

    .node-announcements {
      flex: 0 0 calc(50% - 5px);
      margin-bottom: 10px;
      background-color: $color-grey-news-bg;

      &:nth-child(odd) {
        margin-right: 5px;
      }

      &:nth-child(even) {
        margin-left: 5px;
      }

      @media (max-width: 1199px) {
        flex: 0 0 calc(100%);

        &:nth-child(odd) {
          margin-right: 0;
        }

        &:nth-child(even) {
          margin-left: 0;
        }
      }


      &>.content {


        .col {
          padding: 10px;
          &.col-auto {
            padding: 0;

            img {
              object-fit: cover;
              -o-object-position: center;
              object-position: center;
              width: 100%;
              height: 100%;
              max-height: 399px;
            }
          }

          .more-link {
            margin-top:15px;
            a {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-align: center;
              align-items: center;
              padding: 0 0 0 25px;
              text-transform: uppercase;
              font-weight: 600;
              color: $color-base-black;
            }

            .read-more-green {
              padding: 0 0 0 0;
              font-size: .8em;
              &:before {
                content: 'p';
                font-size: 1.4em;
                line-height: 1;
                font-family: owsd;
                color: $color-base-white;
                background-color: $color-green-dark;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 5px;
              }
            }
          }
        }
      }

      .links {
        display: none;
      }
    }
  }
}


#content {
  #field-member-qualification-add-more-wrapper {
   #degrees-maintext {
      padding-bottom: 0;
      margin-top: 20px;
    }

    .form-item .description {
      .description {
        display: none;
      }


      #field-member-qualification-values {
        .description {
          display: block;
        }
      }
    }
  }
}




// Import fonts
@import url('https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700|Open+Sans:300,400,600|Raleway:300,400,600');
@import url('https://fonts.googleapis.com/css?family=Cabin:400,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Muli:200,300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');
// Common font families
$font-family-headers: 'Raleway', sans-serif;
$font-family-content: 'Open Sans', sans-serif;
$font-family-content-condensed: 'Open Sans Condensed', sans-serif;
$font-family-cabin: 'Cabin', sans-serif;
$font-family-raleway: 'Raleway', sans-serif;
$font-family-print: 'Old Standard TT', serif;
$font-family-muli: 'Muli', sans-serif;


// Common font sizes
$font-size-html: 14px; //rem default size
$font-size-content: 1rem;
$font-size-content-minus2: 0.6rem;
$font-size-content-minus3: 0.7rem;
$font-size-content-minus: 0.8rem;
$font-size-content-minus1: 0.9rem;
$font-size-content-plus: 1.2rem;
$font-size-content-plus1: 1.3rem;
$font-size-content-plus2: 1.408rem;

$font-size-heading-1: 3rem;
$font-size-heading-2: 4.7rem;
$font-size-heading-3: 2.3rem;

$font-size-sidebar-headers: 2rem;

//bussiness card
$font-size-bussinesscard: 1rem;

// --- navigation --- //
$font-size-navbar: 1.4rem;

/*8px = 0.5714rem
9px = 0.6429rem
10px = 0.7143rem
11px = 0.7857rem
12px = 0.8571rem
13px = 0.9286rem
14px = 1rem (base)
15px = 1.071rem
16px = 1.143rem
17px = 1.214rem
18px = 1.286rem
19px = 1.357rem
20px = 1.429rem
21px = 1.5rem
22px = 1.571rem
23px = 1.643rem
24px = 1.714rem
25px = 1.786rem
26px = 1.857rem
27px = 1.929rem
28px = 2rem
29px = 2.071rem
30px = 2.143rem
31px = 2.214rem
32px = 2.286rem
33px = 2.357rem
34px = 2.429rem
35px = 2.5rem
36px = 2.571rem
37px = 2.643rem
38px = 2.714rem
39px = 2.786rem
40px = 2.857rem
41px = 2.929rem
42px = 3rem
43px = 3.071rem
44px = 3.143rem
45px = 3.214rem
46px = 3.286rem
47px = 3.357rem
48px = 3.429rem
49px = 3.5rem
50px = 3.571rem
51px = 3.643rem
52px = 3.714rem
53px = 3.786rem
54px = 3.857rem
55px = 3.929rem
56px = 4rem
57px = 4.071rem
58px = 4.143rem
59px = 4.214rem
60px = 4.286rem
61px = 4.357rem
62px = 4.429rem
63px = 4.5rem
64px = 4.571rem
65px = 4.643rem
66px = 4.714rem
67px = 4.786rem
68px = 4.857rem
69px = 4.929rem
70px = 5rem
71px = 5.071rem
72px = 5.143rem
73px = 5.214rem
74px = 5.286rem
75px = 5.357rem
76px = 5.429rem
77px = 5.5rem
78px = 5.571rem
79px = 5.643rem
80px = 5.714rem*/
